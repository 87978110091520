import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  SafeAreaView,
  FlatList,
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
  ScrollView,
  Image,
  Modal,
} from "react-native";
import axios from "axios";
import { FIREBASE_AUTH } from "../../FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import moment from "moment-timezone";
import CustomAlert from "./CustomAlert";
import SignatureCanvas from "react-signature-canvas";
import CryptoJS from "crypto-js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import debounce from "lodash/debounce";

// Define calculateTotalTime outside of the component
const calculateTotalTime = (startTime, endTime) => {
  if (!startTime || !endTime) return 0;

  const start = moment(startTime, "h:mm A");
  const end = moment(endTime, "h:mm A");

  if (end.isBefore(start)) {
    end.add(1, "day"); // Handle overnight shifts
  }

  return moment.duration(end.diff(start)).asHours();
};

// BackButton Component
const BackButton = ({ color }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
      accessible={true}
      accessibilityLabel="Go Back"
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

// ExpandableTimeDetails Component
const ExpandableTimeDetails = ({ day, dynamicStyles }) => {
  const [expanded, setExpanded] = useState(false);

  const specialTimes = useMemo(() => {
    return [
      {
        type: "Vacation",
        amount: day.hours.find((h) => h.type === "vacation")?.amount || 0,
      },
      {
        type: "Sick",
        amount: day.hours.find((h) => h.type === "sick")?.amount || 0,
      },
      {
        type: "Holiday",
        amount: day.hours.find((h) => h.type === "holiday")?.amount || 0,
      },
    ].filter((time) => time.amount > 0);
  }, [day.hours]);

  const totalSpecialTime = useMemo(() => {
    return specialTimes.reduce((sum, time) => sum + time.amount, 0);
  }, [specialTimes]);

  return (
    <TouchableOpacity
      onPress={() => setExpanded(!expanded)}
      style={[
        styles.expandableTimeDetails,
        dynamicStyles.expandableTimeDetails,
      ]}
      accessible={true}
      accessibilityLabel={
        expanded
          ? "Collapse special time details"
          : "Expand special time details"
      }
    >
      <Text
        style={[styles.expandableTimeTotal, dynamicStyles.expandableTimeTotal]}
      >
        {totalSpecialTime > 0 ? totalSpecialTime.toFixed(1) : "-"}
      </Text>
      {expanded && totalSpecialTime > 0 && (
        <View style={{ marginTop: 5 }}>
          {specialTimes.map((time, index) => (
            <Text
              key={index}
              style={[
                styles.expandableTimeBreakdown,
                dynamicStyles.expandableTimeBreakdown,
              ]}
            >
              {time.type}: {time.amount.toFixed(1)}
            </Text>
          ))}
        </View>
      )}
    </TouchableOpacity>
  );
};

// Employeesummary Component
const Employeesummary = ({ route }) => {
  const { employeeId } = route.params;
  const [attendanceData, setAttendanceData] = useState([]);
  const [supervisorName, setSupervisorName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [newNote, setNewNote] = useState("");
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 1024; // Adjusted breakpoint for desktop
  const [isSignatureChecked, setIsSignatureChecked] = useState({});
  const [isSignaturePadEmpty, setIsSignaturePadEmpty] = useState(true);
  const [signatureData, setSignatureData] = useState(null);
  const [supervisorSignatures, setSupervisorSignatures] = useState({});
  const [currentAttendanceId, setCurrentAttendanceId] = useState(null);
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);

  const signaturePadRef = useRef(null);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertConfirm, setAlertConfirm] = useState(null);
  const [showCancel, setShowCancel] = useState(true);

  // New State for Current Pay Period
  const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);

  // Dynamic styles based on colorScheme and isDesktop
  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme, isDesktop),
    [colorScheme, isDesktop]
  );

  // Toggle between light and dark modes
  const toggleColorScheme = async () => {
    const newScheme = colorScheme === "light" ? "dark" : "light";
    setColorScheme(newScheme);
    try {
      await AsyncStorage.setItem("colorScheme", newScheme);
    } catch (error) {
      console.error("Failed to save color scheme", error);
    }
  };

  // Function to display custom alerts
  const showAlert = (message, title, onConfirm = null, showCancel = true) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertConfirm(() => onConfirm);
    setShowCancel(showCancel);
    setAlertVisible(true);
  };

  // Fetch supervisor's name from Firebase on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      const user = FIREBASE_AUTH.currentUser;
      if (user) {
        try {
          const db = getFirestore();
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.name) {
              setSupervisorName(userData.name);
            }
            // Set admin status based on user role
            setIsAdmin(userData.role === "admin");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsAdmin(false);
        }
      }
    };

    fetchUserData();
    fetchAttendanceData();
  }, [employeeId]);

  // Add this utility function at the top with other utility functions
  const roundToTwoDecimals = (number) => {
    if (number === null || number === undefined || isNaN(number)) {
      return 0;
    }
    return Math.round((number + Number.EPSILON) * 100) / 100;
  };

  // Update the calculateDayTotal function
  const calculateDayTotal = (day) => {
    let total = 0;

    if (
      day.services &&
      Array.isArray(day.services) &&
      day.services.length > 0
    ) {
      // Sum up all service hours
      total = day.services.reduce((sum, service) => {
        const serviceHours = calculateTotalTime(
          service.startTime,
          service.endTime
        );
        return roundToTwoDecimals(sum + serviceHours);
      }, 0);
    } else if (day.startTime && day.endTime) {
      // For regular timesheets
      total = calculateTotalTime(day.startTime, day.endTime);
    }

    // Subtract lunch duration if present
    if (day.lunchStartTime && day.lunchEndTime) {
      const lunchDuration = calculateTotalTime(
        day.lunchStartTime,
        day.lunchEndTime
      );
      total = roundToTwoDecimals(total - lunchDuration);
    }

    // Add stand-by hours if present (for special format timesheets)
    const standByHours = roundToTwoDecimals(parseFloat(day.standByHours) || 0);
    total = roundToTwoDecimals(total + standByHours);

    // Add other hour types (for regular timesheets)
    const vacationHours = roundToTwoDecimals(
      day.hours?.find((h) => h.type === "vacation")?.amount || 0
    );
    const sickHours = roundToTwoDecimals(
      day.hours?.find((h) => h.type === "sick")?.amount || 0
    );
    const holidayHours = roundToTwoDecimals(
      day.hours?.find((h) => h.type === "holiday")?.amount || 0
    );

    total = roundToTwoDecimals(
      total + vacationHours + sickHours + holidayHours
    );

    return Math.max(0, total); // Ensure total is not negative
  };

  // Find the existing calculateWeeklyTotal function in the component and replace it with:
  const calculateWeeklyTotal = useCallback((days) => {
    // Only include regular work hours
    const total = days.reduce((sum, day) => {
      const workHours = day.hours?.find(h => h.type === 'work')?.amount || 0;
      return roundToTwoDecimals(sum + workHours);
    }, 0);
    return roundToTwoDecimals(total);
  }, []);

  // Add these calculation functions near the top with other utility functions
  const calculateWeeklyStandbyHours = (days, isSpecialFormat) => {
    if (!isSpecialFormat) return 0; // Return 0 for regular timesheets

    const total = days.reduce((sum, day) => {
      const standByHours = parseFloat(day.standByHours) || 0;
      return roundToTwoDecimals(sum + standByHours);
    }, 0);
    return roundToTwoDecimals(total);
  };

  const calculateWeeklyRegularHours = (days, isSpecialFormat) => {
    const total = days.reduce((sum, day) => {
      let dayTotal = 0;

      if (isSpecialFormat) {
        // For special format timesheets
        if (
          day.services &&
          Array.isArray(day.services) &&
          day.services.length > 0
        ) {
          dayTotal = day.services.reduce((serviceSum, service) => {
            const serviceHours = calculateTotalTime(
              service.startTime,
              service.endTime
            );
            return roundToTwoDecimals(serviceSum + serviceHours);
          }, 0);
        }
      } else {
        // For regular timesheets
        if (day.startTime && day.endTime) {
          dayTotal = calculateTotalTime(day.startTime, day.endTime);
          // Subtract lunch time if present
          if (day.lunchStartTime && day.lunchEndTime) {
            const lunchHours = calculateTotalTime(
              day.lunchStartTime,
              day.lunchEndTime
            );
            dayTotal = roundToTwoDecimals(dayTotal - lunchHours);
          }
        }
        // All special times (vacation, holiday, sick) are now excluded from total
      }

      return roundToTwoDecimals(sum + dayTotal);
    }, 0);
    return roundToTwoDecimals(total);
  };

  // Fetch Attendance Data Function
  const fetchAttendanceData = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://sbg-backend.onrender.com/attendances/${employeeId}`
      );
      let data = response.data
        .filter((item) => item.isSubmitted === true)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((record) => ({
          ...record,
          weeklyTotalHours: calculateWeeklyTotal(record.days),
          weeklyStandbyHours: calculateWeeklyStandbyHours(
            record.days,
            record.isSpecialFormat
          ),
          weeklyRegularHours: calculateWeeklyRegularHours(
            record.days,
            record.isSpecialFormat
          ),
        }));

      setAttendanceData(data);
      setIsSignatureChecked(
        data.reduce((acc, item) => {
          acc[item._id] = item.isSignatureChecked || false;
          return acc;
        }, {})
      );
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setAttendanceData([]);
    }
  }, [employeeId, calculateWeeklyTotal]);

  // Approve Attendance Function
  const approveAttendance = async (attendance) => {
    if (!supervisorName) {
      showAlert("Supervisor name is not available", "Error");
      return;
    }

    if (!isSignatureChecked[attendance._id]) {
      showAlert(
        "Please confirm the employee's times before approving.",
        "Error"
      );
      return;
    }

    const weekStart = moment(attendance.weekStart).format("MM/DD/YYYY");
    const weekEnd = moment(attendance.weekStart)
      .add(6, "days")
      .format("MM/DD/YYYY");
    const dateSubmitted = moment(attendance.date).format("MM/DD/YYYY");

    let summaryMessage = `
TIME SHEET APPROVAL

Employee Name: ${attendance.employeeName}
Employee ID: ${attendance.employeeId}
Supervisor: ${supervisorName}

Week: ${weekStart} to ${weekEnd}
Date Submitted: ${dateSubmitted}

${
  attendance.isSpecialFormat
    ? `Regular Hours: ${attendance.weeklyRegularHours.toFixed(2)} hrs
Stand-by Hours: ${attendance.weeklyStandbyHours.toFixed(2)} hrs`
    : `Total Hours: ${attendance.weeklyTotalHours.toFixed(2)} hrs`
}

By approving this timesheet, you confirm that you have reviewed and verified the accuracy of all reported times and activities.

Do you wish to continue?
    `;

    showAlert(
      summaryMessage,
      "Confirm Approval",
      () => {
        setCurrentAttendanceId(attendance._id);
        setIsSignatureModalVisible(true);
      },
      true
    );
  };

  // Handle the end of signature drawing
  const handleSignatureEnd = () => {
    if (signaturePadRef.current) {
      const signatureImage = signaturePadRef.current.toDataURL();
      setSignatureData(signatureImage); // Store signature data
    } else {
      console.error("Signature pad reference is null");
      showAlert("Error capturing signature. Please try again.", "Error");
    }
  };

  // Handle signature confirmation
  const handleSignatureConfirm = async () => {
    if (signatureData && !isSignaturePadEmpty && currentAttendanceId) {
      setSupervisorSignatures((prev) => ({
        ...prev,
        [currentAttendanceId]: signatureData,
      }));

      setIsSignatureModalVisible(false);
      proceedWithApproval(currentAttendanceId, signatureData);
    } else {
      showAlert("Please provide a valid signature before confirming.", "Error");
    }
  };

  // Handle signature cancellation
  const handleSignatureCancel = () => {
    setIsSignatureModalVisible(false);
    setSignatureData(null);
    setIsSignaturePadEmpty(true);
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  // Proceed with Approval by sending data to the server
  const proceedWithApproval = async (attendanceId, signatureData) => {
    try {
      if (!signatureData) {
        console.error(
          "No signature data found for attendanceId:",
          attendanceId
        );
        showAlert("No signature data found. Please sign again.", "Error");
        return;
      }

      const verificationHash = CryptoJS.SHA256(signatureData).toString();

      const response = await axios.put(
        `https://sbg-backend.onrender.com/approveAttendance/${attendanceId}`,
        {
          approvedBy: supervisorName,
          supervisorSignature: signatureData,
          supervisorSignatureVerificationHash: verificationHash,
        }
      );

      showAlert("Attendance approved successfully", "Success", null, false);
      fetchAttendanceData();
    } catch (error) {
      console.error("Error in proceedWithApproval:", error);
      showAlert(`Error approving attendance: ${error.message}`, "Error");
    }
  };

  // Update the resetAttendanceStatus function
  const resetAttendanceStatus = async (attendanceId) => {
    const message = isAdmin
      ? "As an administrator, you can reset this timesheet at any time. This will return it to an unsubmitted state and the employee will need to resubmit. Continue?"
      : "This will reset the timesheet to an unsubmitted state. The employee will need to resubmit their timesheet. Are you sure you want to proceed?";

    showAlert(
      message,
      "Confirm Reset",
      async () => {
        try {
          // Include isAdmin and resetBy (supervisor name) in the request
          const response = await axios.put(
            `https://sbg-backend.onrender.com/resetAttendance/${attendanceId}`,
            {
              isAdmin: isAdmin,
              resetBy: supervisorName || "System",
            }
          );

          if (response.status === 200) {
            showAlert(
              `Timesheet has been ${
                isAdmin ? "fully" : ""
              } reset successfully. The employee will be notified via email if they have notifications enabled.`,
              "Success",
              () => {
                fetchAttendanceData(); // Refresh the data
              },
              false
            );
          } else {
            throw new Error("Failed to reset timesheet");
          }
        } catch (error) {
          console.error("Error resetting attendance:", error);
          showAlert(
            `Error resetting timesheet: ${
              error.response?.data?.message || error.message
            }`,
            "Error"
          );
        }
      },
      true // Show cancel button
    );
  };

  // Add Note Function
  const addNote = async (attendanceId) => {
    if (!newNote.trim()) {
      showAlert("Please enter a note", "Error");
      return;
    }

    try {
      await axios.post(
        `https://sbg-backend.onrender.com/attendances/${attendanceId}/notes`,
        {
          content: newNote,
          supervisorName,
        }
      );
      showAlert("Note added successfully", "Success", null, false);
      setNewNote("");
      fetchAttendanceData();
    } catch (error) {
      showAlert("Error adding note", "Error");
    }
  };

  // Function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Navigation Functions Defined Inside the Component
  const goToNextPeriod = useCallback(() => {
    if (currentPeriodIndex < attendanceData.length - 1) {
      setCurrentPeriodIndex(currentPeriodIndex + 1);
    }
  }, [currentPeriodIndex, attendanceData.length]);

  const goToPreviousPeriod = useCallback(() => {
    if (currentPeriodIndex > 0) {
      setCurrentPeriodIndex(currentPeriodIndex - 1);
    }
  }, [currentPeriodIndex]);

  // Paginate Attendance Data
  const paginatedAttendanceData = useMemo(() => {
    if (attendanceData.length === 0) return [];
    return [attendanceData[currentPeriodIndex]];
  }, [attendanceData, currentPeriodIndex]);

  // Render Regular Format Table
  const renderRegularTable = (item, isDesktop) => (
    <View
      style={[
        styles.tableContainer,
        isDesktop && styles.desktopTableContainer,
        dynamicStyles.tableContainer,
      ]}
    >
      {/* Table Header */}
      <View
        style={[
          styles.tableHeader,
          isDesktop && styles.desktopTableHeader,
          dynamicStyles.tableHeader,
        ]}
      >
        {["Day", "Date", "Start", "End", "Lunch", "Special Time", "Total"].map(
          (header, index) => (
            <Text
              key={index}
              style={[
                styles.headerCell,
                isDesktop && styles.desktopHeaderCell,
                dynamicStyles.headerCell,
              ]}
            >
              {header}
            </Text>
          )
        )}
      </View>

      {/* Table Rows */}
      {item.days.map((day, index) => (
        <View
          key={index}
          style={[
            styles.tableRow,
            isDesktop && styles.desktopTableRow,
            dynamicStyles.tableRow,
          ]}
        >
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {moment(day.date).format("ddd")}
          </Text>
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {moment(day.date).format("MM/DD")}
          </Text>
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {day.startTime || "-"}
          </Text>
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {day.endTime || "-"}
          </Text>
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {day.lunchStartTime && day.lunchEndTime
              ? `${day.lunchStartTime}-${day.lunchEndTime}`
              : "-"}
          </Text>
          <View style={styles.tableCell}>
            <ExpandableTimeDetails day={day} dynamicStyles={dynamicStyles} />
          </View>
          <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
            {calculateDayTotal(day).toFixed(2)}
          </Text>
        </View>
      ))}
    </View>
  );

  // Add these calculation functions for daily totals
  const calculateDayRegularHours = (day) => {
    if (!day.services || !Array.isArray(day.services)) return 0;

    const total = day.services.reduce((sum, service) => {
      const serviceHours = calculateTotalTime(
        service.startTime,
        service.endTime
      );
      return roundToTwoDecimals(sum + serviceHours);
    }, 0);

    return roundToTwoDecimals(total);
  };

  const calculateDayStandbyHours = (day) => {
    return roundToTwoDecimals(parseFloat(day.standByHours) || 0);
  };

  // Update the calculateServiceTime function
  const calculateServiceTime = (service, day, isFirstService) => {
    if (!service.startTime || !service.endTime) return 0;

    const start = moment(service.startTime, "h:mm A");
    const end = moment(service.endTime, "h:mm A");

    if (end.isBefore(start)) {
      end.add(1, "day");
    }

    let duration = moment.duration(end.diff(start)).asHours();

    // Subtract lunch time only from the first service if it contains the lunch period
    if (isFirstService && day.lunchStartTime && day.lunchEndTime) {
      const lunchStart = moment(day.lunchStartTime, "h:mm A");
      const lunchEnd = moment(day.lunchEndTime, "h:mm A");

      if (lunchStart.isSameOrAfter(start) && lunchEnd.isSameOrBefore(end)) {
        const lunchDuration = moment
          .duration(lunchEnd.diff(lunchStart))
          .asHours();
        duration = duration - lunchDuration;
      }
    }

    return roundToTwoDecimals(duration);
  };

  // Update the renderSpecialFormatTable function
  const renderSpecialFormatTable = (item, isDesktop) => {
    const sortedDays = [...item.days].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    const isStandbyTimesheet = sortedDays.some(
      (day) => parseFloat(day.standByHours) > 0
    );

    return (
      <View style={[styles.tableContainer, dynamicStyles.tableContainer]}>
        {/* Table Header - simplified for non-standby */}
        <View style={[styles.tableHeader, dynamicStyles.tableHeader]}>
          <Text style={[styles.tableHeaderCell, dynamicStyles.tableHeaderCell]}>
            DAY
          </Text>
          <Text style={[styles.tableHeaderCell, dynamicStyles.tableHeaderCell]}>
            DATE
          </Text>
          <Text
            style={[
              styles.tableHeaderCell,
              styles.serviceCell,
              dynamicStyles.tableHeaderCell,
            ]}
          >
            SERVICE
          </Text>
          <Text style={[styles.tableHeaderCell, dynamicStyles.tableHeaderCell]}>
            START TIME
          </Text>
          <Text style={[styles.tableHeaderCell, dynamicStyles.tableHeaderCell]}>
            END TIME
          </Text>
          <Text style={[styles.tableHeaderCell, dynamicStyles.tableHeaderCell]}>
            LUNCH
          </Text>
          <Text style={[styles.tableHeaderCell, dynamicStyles.tableHeaderCell]}>
            TOTAL TIME
          </Text>
          {isStandbyTimesheet && (
            <>
              <Text
                style={[styles.tableHeaderCell, dynamicStyles.tableHeaderCell]}
              >
                STAND-BY HOURS
              </Text>
              <Text
                style={[styles.tableHeaderCell, dynamicStyles.tableHeaderCell]}
              >
                DAY TOTAL
              </Text>
            </>
          )}
        </View>

        {sortedDays.map((day, dayIndex) => {
          const hasServices = day.services && day.services.length > 0;
          const dayRegularHours = calculateDayRegularHours(day);

          return (
            <React.Fragment key={dayIndex}>
              {hasServices &&
                day.services.map((service, serviceIndex) => (
                  <View
                    key={`${dayIndex}-${serviceIndex}`}
                    style={[styles.tableRow, dynamicStyles.tableRow]}
                  >
                    <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                      {serviceIndex === 0 ? moment(day.date).format("ddd") : ""}
                    </Text>
                    <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                      {serviceIndex === 0
                        ? moment(day.date).format("MM/DD")
                        : ""}
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.serviceCell,
                        dynamicStyles.tableCell,
                      ]}
                    >
                      {service.service}
                    </Text>
                    <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                      {service.startTime}
                    </Text>
                    <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                      {service.endTime}
                    </Text>
                    <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                      {serviceIndex === 0 && day.lunchStartTime
                        ? `${day.lunchStartTime}-${day.lunchEndTime}`
                        : "-"}
                    </Text>
                    <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                      {calculateServiceTime(
                        service,
                        day,
                        serviceIndex === 0
                      ).toFixed(2)}
                    </Text>
                    {isStandbyTimesheet && (
                      <>
                        <Text
                          style={[styles.tableCell, dynamicStyles.tableCell]}
                        ></Text>
                        <Text
                          style={[styles.tableCell, dynamicStyles.tableCell]}
                        ></Text>
                      </>
                    )}
                  </View>
                ))}

              {/* Daily Total Row - only show for standby timesheets */}
              {isStandbyTimesheet && hasServices && (
                <View
                  style={[
                    styles.tableRow,
                    styles.dailyTotalRow,
                    dynamicStyles.dailyTotalRow,
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      styles.dailyTotalCell,
                      dynamicStyles.dailyTotalCell,
                    ]}
                  >
                    Daily Total
                  </Text>
                  <Text
                    style={[styles.tableCell, dynamicStyles.tableCell]}
                  ></Text>
                  <Text
                    style={[styles.tableCell, dynamicStyles.tableCell]}
                  ></Text>
                  <Text
                    style={[styles.tableCell, dynamicStyles.tableCell]}
                  ></Text>
                  <Text
                    style={[styles.tableCell, dynamicStyles.tableCell]}
                  ></Text>
                  <Text
                    style={[styles.tableCell, dynamicStyles.tableCell]}
                  ></Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.dailyTotalCell,
                      dynamicStyles.dailyTotalCell,
                    ]}
                  >
                    {dayRegularHours.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.dailyTotalCell,
                      dynamicStyles.dailyTotalCell,
                    ]}
                  >
                    {calculateDayStandbyHours(day).toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.dailyTotalCell,
                      dynamicStyles.dailyTotalCell,
                    ]}
                  >
                    {(dayRegularHours + calculateDayStandbyHours(day)).toFixed(
                      2
                    )}
                  </Text>
                </View>
              )}
            </React.Fragment>
          );
        })}
      </View>
    );
  };

  // Render Attendance Item
  const renderAttendance = ({ item }) => {
    const weekStart = moment(item.weekStart).format("MM/DD/YYYY");
    const weekEnd = moment(item.weekStart).add(6, "days").format("MM/DD/YYYY");
    const dateSubmitted = moment(item.date).format("MM/DD/YYYY");
    const hasStandby = item.days.some(
      (day) => parseFloat(day.standByHours) > 0
    );

    // Function to determine if reset button should be shown
    const shouldShowResetButton = () => {
      if (isAdmin) return true; // Admins can always see the reset button
      return !item.approved; // Supervisors can only see it before approval
    };

    return (
      <View
        style={[styles.attendanceContainer, dynamicStyles.attendanceContainer]}
      >
        <View style={isDesktop ? styles.desktopContentContainer : null}>
          <View
            style={[
              styles.employeeInfo,
              isDesktop && styles.desktopEmployeeInfo,
            ]}
          >
            <View style={[styles.employeeAvatar, dynamicStyles.employeeAvatar]}>
              <Text
                style={[
                  styles.avatarText,
                  isDesktop && styles.desktopAvatarText,
                  dynamicStyles.avatarText,
                ]}
              >
                {item.employeeName.charAt(0)}
              </Text>
            </View>
            <View>
              <Text style={[styles.employeeName, dynamicStyles.employeeName]}>
                {item.employeeName}
              </Text>
              <Text
                style={[styles.employeeDetail, dynamicStyles.employeeDetail]}
              >
                ID: {item.employeeId}
              </Text>
              <Text
                style={[styles.employeeDetail, dynamicStyles.employeeDetail]}
              >
                Date Submitted: {dateSubmitted}
              </Text>
              <Text
                style={[styles.employeeDetail, dynamicStyles.employeeDetail]}
              >
                Week: {weekStart} to {weekEnd}
              </Text>

              {/* Conditional hours display */}
              {hasStandby ? (
                <>
                  <Text
                    style={[
                      styles.employeeDetail,
                      dynamicStyles.employeeDetail,
                    ]}
                  >
                    Regular Hours: {item.weeklyRegularHours.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      styles.employeeDetail,
                      dynamicStyles.employeeDetail,
                    ]}
                  >
                    Stand-by Hours: {item.weeklyStandbyHours.toFixed(2)}
                  </Text>
                  <Text
                    style={[
                      styles.employeeDetail,
                      dynamicStyles.employeeDetail,
                    ]}
                  >
                    Total Hours: {item.weeklyTotalHours.toFixed(2)}
                  </Text>
                </>
              ) : (
                <Text
                  style={[styles.employeeDetail, dynamicStyles.employeeDetail]}
                >
                  Total Hours: {item.weeklyTotalHours.toFixed(2)}
                </Text>
              )}

              {item.approvedBy && (
                <Text
                  style={[styles.approvedDetail, dynamicStyles.approvedDetail]}
                >
                  Approved by: {item.approvedBy}
                </Text>
              )}
            </View>
          </View>

          {/* Table Section */}
          {isDesktop ? (
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={styles.desktopTableScrollView}
            >
              {item.isSpecialFormat
                ? renderSpecialFormatTable(item, isDesktop)
                : renderRegularTable(item, isDesktop)}
            </ScrollView>
          ) : (
            <View>
              {item.isSpecialFormat
                ? renderSpecialFormatTable(item, isDesktop)
                : renderRegularTable(item, isDesktop)}
            </View>
          )}
        </View>

        {/* Supervisor Signatures */}
        {supervisorSignatures[item._id] && (
          <View style={styles.signatureImageContainer}>
            <Text style={[styles.signatureLabel, dynamicStyles.signatureLabel]}>
              Supervisor Signature:
            </Text>
            <Image
              source={{ uri: supervisorSignatures[item._id] }}
              style={styles.signatureImage}
              resizeMode="contain"
            />
          </View>
        )}

        {/* Supervisor Notes */}
        <View style={[styles.notesSection, dynamicStyles.notesSection]}>
          <Text style={[styles.notesHeader, dynamicStyles.notesHeader]}>
            Supervisor Notes:
          </Text>
          {item.notes && item.notes.length > 0 ? (
            item.notes.map((note, index) => (
              <View
                key={index}
                style={[styles.noteItem, dynamicStyles.noteItem]}
              >
                <Text style={[styles.noteContent, dynamicStyles.noteContent]}>
                  {note.content}
                </Text>
                <Text style={[styles.noteDetails, dynamicStyles.noteDetails]}>
                  By {note.supervisorName} on{" "}
                  {moment
                    .tz(note.date, "America/Phoenix")
                    .format("YYYY-MM-DD hh:mm A")}
                </Text>
              </View>
            ))
          ) : (
            <Text style={[styles.noNotesText, dynamicStyles.noNotesText]}>
              No notes available
            </Text>
          )}
        </View>

        {/* Add Note Section */}
        <View style={styles.addNoteSection}>
          <TextInput
            style={[styles.noteInput, dynamicStyles.noteInput]}
            value={newNote}
            onChangeText={setNewNote}
            placeholder="Add a new note..."
            placeholderTextColor={colorScheme === "dark" ? "#888" : "#999"}
            multiline
            accessible={true}
            accessibilityLabel="Add a new note"
          />
          <TouchableOpacity
            style={[styles.addNoteButton, dynamicStyles.addNoteButton]}
            onPress={() => addNote(item._id)}
            accessible={true}
            accessibilityLabel="Add Note Button"
          >
            <Text style={styles.addNoteButtonText}>Add Note</Text>
          </TouchableOpacity>
        </View>

        {/* Signature Confirmation */}
        <View style={styles.signatureContainer}>
          <TouchableOpacity
            style={styles.checkbox}
            onPress={() => {
              setIsSignatureChecked((prev) => ({
                ...prev,
                [item._id]: !prev[item._id],
              }));
            }}
            accessible={true}
            accessibilityLabel={
              isSignatureChecked[item._id]
                ? "Uncheck signature confirmation"
                : "Check signature confirmation"
            }
          >
            {isSignatureChecked[item._id] && (
              <Feather name="check" size={20} color="#4CAF50" />
            )}
          </TouchableOpacity>
          <Text style={[styles.signatureText, dynamicStyles.signatureText]}>
            I confirm that I have reviewed and verified the accuracy of all
            reported times and activities.
          </Text>
        </View>

        {/* Approve Button */}
        <TouchableOpacity
          style={[
            styles.approveButton,
            (!isSignatureChecked[item._id] || item.approved) &&
              styles.approveButtonDisabled,
            dynamicStyles.approveButton,
            (!isSignatureChecked[item._id] || item.approved) &&
              dynamicStyles.approveButtonDisabled,
          ]}
          onPress={() =>
            !item.approved &&
            isSignatureChecked[item._id] &&
            approveAttendance(item)
          }
          disabled={!isSignatureChecked[item._id] || item.approved}
          accessible={true}
          accessibilityLabel={
            item.approved
              ? "Attendance already approved"
              : "Review and Approve Attendance"
          }
        >
          <Text style={styles.approveButtonText}>
            {item.approved ? "Approved" : "Review and Approve"}
          </Text>
        </TouchableOpacity>

        {/* Reset Button - Show based on user role and approval status */}
        {shouldShowResetButton() && (
          <TouchableOpacity
            style={[styles.resetButton, dynamicStyles.resetButton]}
            onPress={() => resetAttendanceStatus(item._id)}
            accessible={true}
            accessibilityLabel="Reset Timesheet Submission"
          >
            <View style={styles.resetButtonContent}>
              <Feather
                name="refresh-ccw"
                size={20}
                color="#FFFFFF"
                style={styles.resetButtonIcon}
              />
              <Text style={styles.resetButtonText}>
                Reset {item.approved ? "Approved " : ""}Timesheet
              </Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  // Render Timesheet Function
  const renderTimesheet = useCallback(
    (item, isDesktop) => {
      return (
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {item.isSpecialFormat
            ? renderSpecialFormatTable(item, isDesktop)
            : renderRegularTable(item, isDesktop)}
        </ScrollView>
      );
    },
    [renderRegularTable, renderSpecialFormatTable]
  );

  return (
    <SafeAreaView style={[styles.container, dynamicStyles.container]}>
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#121212" : "#F8F9FA"}
      />
      <View style={[styles.header, dynamicStyles.header]}>
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"} />
        <Text style={[styles.headerText, dynamicStyles.headerText]}>
          Timesheet Approval
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
          accessible={true}
          accessibilityLabel="Toggle Theme"
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
          />
        </TouchableOpacity>
      </View>
      <Text
        style={[styles.supervisorNameText, dynamicStyles.supervisorNameText]}
      >
        Supervisor: {supervisorName}
      </Text>
      {attendanceData.length > 0 ? (
        <>
          {/* Navigation Controls */}
          <View style={styles.navigationContainer}>
            <TouchableOpacity
              onPress={goToPreviousPeriod}
              disabled={currentPeriodIndex === 0}
              style={[
                styles.navButton,
                currentPeriodIndex === 0 && styles.navButtonDisabled,
              ]}
              accessible={true}
              accessibilityLabel="Previous Pay Period"
            >
              <Feather
                name="arrow-left"
                size={24}
                color={
                  currentPeriodIndex === 0
                    ? colorScheme === "dark"
                      ? "#666"
                      : "#999"
                    : colorScheme === "dark"
                    ? "#FFF"
                    : "#007AFF"
                }
              />
            </TouchableOpacity>

            <Text
              style={[styles.periodIndicator, dynamicStyles.periodIndicator]}
            >
              {currentPeriodIndex + 1} of {attendanceData.length}
            </Text>

            <TouchableOpacity
              onPress={goToNextPeriod}
              disabled={currentPeriodIndex === attendanceData.length - 1}
              style={[
                styles.navButton,
                currentPeriodIndex === attendanceData.length - 1 &&
                  styles.navButtonDisabled,
              ]}
              accessible={true}
              accessibilityLabel="Next Pay Period"
            >
              <Feather
                name="arrow-right"
                size={24}
                color={
                  currentPeriodIndex === attendanceData.length - 1
                    ? colorScheme === "dark"
                      ? "#666"
                      : "#999"
                    : colorScheme === "dark"
                    ? "#FFF"
                    : "#007AFF"
                }
              />
            </TouchableOpacity>
          </View>

          {/* Paginated FlatList */}
          <FlatList
            data={paginatedAttendanceData}
            renderItem={renderAttendance}
            keyExtractor={(item) => item._id}
            contentContainerStyle={isDesktop && styles.desktopListContainer}
            accessible={true}
            accessibilityLabel="Attendance Records List"
          />
        </>
      ) : (
        <Text style={[styles.noDataText, dynamicStyles.noDataText]}>
          No valid timesheet submissions found
        </Text>
      )}

      {/* Signature Modal */}
      <Modal
        visible={isSignatureModalVisible}
        animationType="slide"
        transparent={true}
        accessible={true}
        accessibilityViewIsModal={true}
      >
        <View style={styles.modalContainer}>
          <View style={styles.signaturePadContainer}>
            <Text style={styles.modalTitle}>Please sign below</Text>
            <SignatureCanvas
              ref={signaturePadRef}
              onBegin={() => setIsSignaturePadEmpty(false)}
              onEnd={handleSignatureEnd}
              canvasProps={{
                width: isDesktop ? 600 : 300,
                height: isDesktop ? 200 : 150,
                className: "signatureCanvas",
              }}
              webStyle={`
                  .m-signature-pad {
                    width: 100%;
                    height: 100%;
                    margin-bottom: 20px;
                  }
                  .m-signature-pad--body {
                    border: 2px solid #e8e8e8;
                    border-radius: 8px;
                  }
                  .m-signature-pad--body canvas {
                    width: 100% !important;
                    height: 100% !important;
                  }
                `}
            />
            <View style={styles.modalButtonContainer}>
              <TouchableOpacity
                style={[
                  styles.modalButton,
                  isSignaturePadEmpty && styles.modalButtonDisabled,
                ]}
                onPress={handleSignatureConfirm}
                disabled={isSignaturePadEmpty}
                accessible={true}
                accessibilityLabel="Confirm Signature"
              >
                <Text style={styles.modalButtonText}>Confirm Signature</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.cancelButton]}
                onPress={handleSignatureCancel}
                accessible={true}
                accessibilityLabel="Cancel Signature"
              >
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      {/* Custom Alert */}
      <CustomAlert
        visible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setAlertVisible(false)}
        onConfirm={alertConfirm}
        showCancel={showCancel}
      />
    </SafeAreaView>
  );
};

// Stylesheet
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#333",
    backgroundColor: "#1E1E1E",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  supervisorNameText: {
    fontSize: 16,
    color: "#CCCCCC",
    padding: 16,
  },
  attendanceContainer: {
    marginVertical: 10,
    marginHorizontal: 16,
    padding: 16,
    borderRadius: 12,
    backgroundColor: "#2C2C2C",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 5,
  },
  desktopAttendanceContainer: {
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
    padding: 24,
  },
  desktopContentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  employeeInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  desktopEmployeeInfo: {
    width: "30%",
    marginRight: 20,
  },
  employeeAvatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: "#0A84FF",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
  avatarText: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
  },
  desktopAvatarText: {
    fontSize: 32,
  },
  employeeName: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  desktopEmployeeName: {
    fontSize: 24,
    marginBottom: 8,
  },
  employeeDetail: {
    fontSize: 14,
    color: "#CCCCCC",
    marginTop: 2,
  },
  desktopEmployeeDetail: {
    fontSize: 16,
    marginBottom: 4,
  },
  approvedDetail: {
    fontSize: 14,
    color: "#81C784",
    marginTop: 4,
  },
  desktopApprovedDetail: {
    fontSize: 16,
  },
  tableContainer: {
    marginTop: 15,
    borderRadius: 8,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#333",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#1E1E1E",
    paddingVertical: 10,
    paddingHorizontal: 5,
  },
  desktopTableHeader: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  headerCell: {
    color: "#FFFFFF",
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
    fontSize: 12,
    paddingHorizontal: 2,
    // Removed minWidth to allow flexibility
  },
  desktopHeaderCell: {
    fontSize: 16,
    paddingHorizontal: 12,
  },
  tableRow: {
    flexDirection: "row",
    borderTopWidth: 1,
    borderTopColor: "#333",
    paddingVertical: 8,
    paddingHorizontal: 5,
  },
  desktopTableRow: {
    paddingVertical: 12,
    paddingHorizontal: 10,
  },
  tableCell: {
    color: "#CCCCCC",
    flex: 1,
    textAlign: "center",
    fontSize: 12,
    paddingHorizontal: 2,
    // Removed minWidth to allow flexibility
  },
  desktopTableCell: {
    fontSize: 14,
    paddingHorizontal: 12,
  },
  serviceCell: {
    flex: 2,
    textAlign: "left",
  },
  desktopServiceCell: {
    flex: 3,
    textAlign: "left",
  },
  signatureImageContainer: {
    marginTop: 16,
    alignItems: "center",
  },
  signatureImage: {
    width: 300,
    height: 100,
    backgroundColor: "#FFFFFF",
  },
  signatureLabel: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFFFFF",
    marginBottom: 8,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  signaturePadContainer: {
    backgroundColor: "#2C2C2C",
    padding: 20,
    borderRadius: 10,
    width: "95%",
    maxWidth: 600,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#FFFFFF",
    marginBottom: 15,
    textAlign: "center",
  },
  modalButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  modalButton: {
    flex: 1,
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginHorizontal: 5,
    backgroundColor: "#0A84FF",
  },
  modalButtonDisabled: {
    backgroundColor: "#666666",
  },
  cancelButton: {
    backgroundColor: "#333333",
  },
  modalButtonText: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: 16,
  },
  cancelButtonText: {
    color: "#CCCCCC",
    fontSize: 16,
  },
  desktopListContainer: {
    paddingHorizontal: 20,
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  mobileListContainer: {
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  noDataText: {
    color: "#AAAAAA",
    fontSize: 16,
    textAlign: "center",
    marginTop: 20,
  },
  notesSection: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "#333",
    borderRadius: 8,
  },
  notesHeader: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFFFFF",
    marginBottom: 10,
  },
  noteItem: {
    backgroundColor: "#2C2C2C",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  noteContent: {
    color: "#FFFFFF",
    fontSize: 14,
  },
  noteDetails: {
    color: "#AAAAAA",
    fontSize: 12,
    marginTop: 5,
  },
  noNotesText: {
    color: "#AAAAAA",
    fontStyle: "italic",
  },
  addNoteSection: {
    marginTop: 15,
  },
  noteInput: {
    borderColor: "#555",
    backgroundColor: "#333",
    color: "#FFFFFF",
    borderRadius: 8,
    padding: 10,
    minHeight: 80,
    textAlignVertical: "top",
  },
  addNoteButton: {
    backgroundColor: "#0A84FF",
    padding: 12,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 10,
  },
  addNoteButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
  signatureContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 16,
  },
  checkbox: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: "#4CAF50",
    borderRadius: 4,
    marginRight: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  signatureText: {
    color: "#FFFFFF",
    flex: 1,
    fontSize: 14,
  },
  approveButton: {
    backgroundColor: "#43A047",
    padding: 12,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 20,
  },
  approveButtonDisabled: {
    backgroundColor: "#666666",
  },
  approveButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
  deleteButton: {
    backgroundColor: "#FF453A",
    padding: 12,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 10,
  },
  deleteButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
  desktopTableScrollView: {
    width: "70%",
  },
  expandableTimeDetails: {
    padding: 5,
  },
  expandableTimeTotal: {
    fontSize: 14,
    color: "#FFFFFF",
  },
  expandableTimeBreakdown: {
    fontSize: 12,
    color: "#CCCCCC",
    marginTop: 2,
  },
  // Navigation Styles
  navigationContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
    paddingHorizontal: 16,
  },
  navButton: {
    padding: 10,
    borderRadius: 8,
  },
  navButtonDisabled: {
    opacity: 0.5,
  },
  periodIndicator: {
    marginHorizontal: 20,
    fontSize: 16,
    fontWeight: "500",
    color: "#000000",
  },
  // Updated Styles for renderSpecialFormatTable
  tableHeaderCell: {
    color: "#FFFFFF",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 12,
    paddingHorizontal: 2,
    paddingVertical: 10,
    flex: 1, // Default flex
  },
  desktopTableHeaderCell: {
    fontSize: 14,
    paddingHorizontal: 4,
    paddingVertical: 12,
  },
  totalRow: {
    backgroundColor: "#333333",
    borderTopWidth: 2,
    borderTopColor: "#666666",
  },
  totalCell: {
    color: "#FFFFFF",
    fontWeight: "bold",
    textAlign: "center",
  },
  dailyTotalRow: {
    backgroundColor: "#2C2C2C",
    borderTopWidth: 1,
    borderTopColor: "#444444",
  },
  weeklyTotalRow: {
    backgroundColor: "#333333",
    borderTopWidth: 2,
    borderTopColor: "#666666",
  },
  dailyTotalCell: {
    color: "#CCCCCC",
    fontWeight: "500",
    textAlign: "center",
    fontStyle: "italic",
  },
  resetButton: {
    backgroundColor: "#FF453A", // Red color for reset action
    padding: 12,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "center",
  },
  resetButtonContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  resetButtonIcon: {
    marginRight: 8,
  },
  resetButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
});

// Function to create dynamic styles based on colorScheme and isDesktop
const createDynamicStyles = (colorScheme, isDesktop) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#F8F9FA",
  },
  header: {
    backgroundColor: colorScheme === "dark" ? "#1E1E1E" : "white",
    borderBottomColor: colorScheme === "dark" ? "#333" : "#E0E0E0",
  },
  headerText: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  supervisorNameText: {
    color: colorScheme === "dark" ? "#CCCCCC" : "#333333",
  },
  attendanceContainer: {
    backgroundColor: colorScheme === "dark" ? "#2C2C2C" : "#FFFFFF",
    shadowColor: colorScheme === "dark" ? "#FFF" : "#000",
  },
  employeeAvatar: {
    backgroundColor: colorScheme === "dark" ? "#0A84FF" : "#007AFF",
  },
  avatarText: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  employeeName: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#333333",
  },
  employeeDetail: {
    color: colorScheme === "dark" ? "#CCCCCC" : "#666666",
  },
  approvedDetail: {
    color: colorScheme === "dark" ? "#81C784" : "#4CAF50",
  },
  tableContainer: {
    backgroundColor: colorScheme === "dark" ? "#1E1E1E" : "#FFFFFF",
    borderColor: colorScheme === "dark" ? "#333333" : "#E0E0E0",
  },
  tableHeader: {
    backgroundColor: colorScheme === "dark" ? "#2C2C2C" : "#f2f2f2",
  },
  headerCell: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
    fontSize: isDesktop ? 14 : 12,
    flex: 1,
    // Removed minWidth
  },
  desktopHeaderCell: {
    fontSize: 16,
    paddingHorizontal: 12,
  },
  tableRow: {
    borderTopColor: colorScheme === "dark" ? "#333333" : "#E0E0E0",
  },
  tableCell: {
    color: colorScheme === "dark" ? "#CCCCCC" : "#333333",
    fontSize: isDesktop ? 14 : 12,
    flex: 1,
    // Removed minWidth
  },
  notesSection: {
    backgroundColor: colorScheme === "dark" ? "#333333" : "#F0F0F0",
  },
  notesHeader: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#333333",
  },
  noteItem: {
    backgroundColor: colorScheme === "dark" ? "#2C2C2C" : "#FFFFFF",
  },
  noteContent: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  noteDetails: {
    color: colorScheme === "dark" ? "#AAAAAA" : "#666666",
  },
  noNotesText: {
    color: colorScheme === "dark" ? "#AAAAAA" : "#666666",
  },
  noteInput: {
    borderColor: colorScheme === "dark" ? "#555555" : "#E0E0E0",
    backgroundColor: colorScheme === "dark" ? "#333333" : "#FFFFFF",
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  addNoteButton: {
    backgroundColor: colorScheme === "dark" ? "#0A84FF" : "#007AFF",
  },
  signatureText: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#333333",
  },
  approveButton: {
    backgroundColor: colorScheme === "dark" ? "#43A047" : "#4CAF50",
  },
  approveButtonDisabled: {
    backgroundColor: colorScheme === "dark" ? "#666666" : "#A0A0A0",
  },
  deleteButton: {
    backgroundColor: colorScheme === "dark" ? "#FF453A" : "#FF3B30",
  },
  noDataText: {
    color: colorScheme === "dark" ? "#AAAAAA" : "#666666",
  },
  signatureLabel: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#333333",
  },
  tableHeaderCell: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
    fontSize: isDesktop ? 14 : 12,
    flex: 1, // Ensure flex is set appropriately
  },
  periodIndicator: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  navButtonIcon: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#007AFF",
  },
  resetButton: {
    backgroundColor: colorScheme === "dark" ? "#FF453A" : "#DC3545",
    opacity: 1,
  },
  resetButtonDisabled: {
    backgroundColor: colorScheme === "dark" ? "#666666" : "#999999",
    opacity: 0.5,
  },
});

export default Employeesummary;
