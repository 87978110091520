import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  FlatList,
  Text,
  View,
  TextInput,
  StyleSheet,
  Button,
  Platform,
  Alert,
  Pressable,
  TouchableOpacity,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import { DataTable } from "react-native-paper";
import axios from "axios";
import moment from "moment";
import { Ionicons, Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

const BackButton = ({ color }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Ionicons name="arrow-back" size={24} color={color} />
    </TouchableOpacity>
  );
};

// Conditionally import ExcelJS and FileSaver for web
let ExcelJS, saveAs;
if (Platform.OS === "web") {
  ExcelJS = require("exceljs");
  saveAs = require("file-saver").saveAs;
}

const WeeklyAttendanceSummary = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newNote, setNewNote] = useState("");
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  // Function to approve attendance
  const approveAttendance = async (attendance) => {
    try {
      const response = await axios.put(
        `https://sbg-backend.onrender.com/approveAttendance/${attendance._id}`,
        { approvedBy: attendance.approvedBy }
      );
      console.log(response.data);
      fetchAttendanceData(); // Refresh data after approval
    } catch (error) {
      console.error("Error approving attendance:", error);
    }
  };

  // Function to add a new note
  const addNote = async (attendanceId) => {
    if (!newNote.trim()) {
      Alert.alert("Error", "Please enter a note");
      return;
    }

    try {
      const response = await axios.post(
        `https://sbg-backend.onrender.com/attendances/${attendanceId}/notes`,
        {
          content: newNote,
          supervisorName: "Current Supervisor", // Replace with actual supervisor name
        }
      );
      console.log(response.data);
      setNewNote("");
      fetchAttendanceData(); // Refresh data after adding note
    } catch (error) {
      console.error("Error adding note:", error);
      Alert.alert("Error", "Failed to add note");
    }
  };

  // Fetch attendance data
  const fetchAttendanceData = async () => {
    try {
      const response = await axios.get(
        `https://sbg-backend.onrender.com/attendances`
      );
      const data = response.data;

      // Get the start and end dates of the current week
      const startOfWeek = moment().startOf("week");
      const endOfWeek = moment().endOf("week");

      // Filter data to include only the current week's attendance
      const filteredData = data.filter((attendance) => {
        const attendanceDate = moment(attendance.date);
        return attendanceDate.isBetween(startOfWeek, endOfWeek, "days", "[]");
      });

      console.log(filteredData);
      setAttendanceData(filteredData);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  // Fetch attendance data when component mounts
  useEffect(() => {
    fetchAttendanceData();
  }, []);

  // Function to generate Excel file
  const generateExcel = async () => {
    const dataToExport = searchQuery ? filteredAttendanceData : attendanceData;

    if (Platform.OS === "web") {
      // Web-specific Excel generation
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Attendance");

      // Define columns
      worksheet.columns = [
        { header: "Employee Name", key: "employeeName", width: 20 },
        { header: "Employee ID", key: "employeeId", width: 15 },
        { header: "Week Start", key: "weekStart", width: 15 },
        { header: "Weekly Total Hours", key: "weeklyTotalHours", width: 20 },
        { header: "Submitted On", key: "submittedOn", width: 20 },
        { header: "Approved By", key: "approvedBy", width: 20 },
        { header: "Approved Date", key: "approvedDate", width: 20 },
        { header: "Day of Week", key: "dayOfWeek", width: 15 },
        { header: "Date", key: "date", width: 15 },
        { header: "Start Time", key: "startTime", width: 15 },
        { header: "End Time", key: "endTime", width: 15 },
        { header: "Lunch Start", key: "lunchStartTime", width: 15 },
        { header: "Lunch End", key: "lunchEndTime", width: 15 },
        { header: "Vacation Hours", key: "vacationHours", width: 15 },
        { header: "Sick Time", key: "sickTime", width: 15 },
        { header: "Holiday Hours", key: "holidayHours", width: 15 },
        { header: "Total Work", key: "totalWorkHours", width: 15 },
        { header: "Notes", key: "notes", width: 30 },
      ];

      // Add rows
      dataToExport.forEach((item) => {
        const notes = item.notes
          ? item.notes
              .map((note) => `${note.content} (${note.supervisorName})`)
              .join("; ")
          : "";
        if (item.days.length === 0) {
          worksheet.addRow({
            employeeName: item.employeeName,
            employeeId: item.employeeId,
            weekStart: moment.utc(item.weekStart).format("YYYY-MM-DD"),
            weeklyTotalHours: item.weeklyTotalHours,
            submittedOn: item.date
              ? moment.utc(item.date).format("YYYY-MM-DD")
              : "",
            approvedBy: item.approvedBy,
            approvedDate: item.approvedDate
              ? moment.utc(item.approvedDate).format("YYYY-MM-DD")
              : "",
            dayOfWeek: "",
            date: "",
            startTime: "",
            endTime: "",
            lunchStartTime: "",
            lunchEndTime: "",
            vacationHours: "",
            sickTime: "",
            holidayHours: "",
            totalWorkHours: "",
            notes: notes,
          });
        } else {
          item.days.forEach((day) => {
            worksheet.addRow({
              employeeName: item.employeeName,
              employeeId: item.employeeId,
              weekStart: moment.utc(item.weekStart).format("YYYY-MM-DD"),
              weeklyTotalHours: item.weeklyTotalHours,
              submittedOn: item.date
                ? moment.utc(item.date).format("YYYY-MM-DD")
                : "",
              approvedBy: item.approvedBy,
              approvedDate: item.approvedDate
                ? moment.utc(item.approvedDate).format("YYYY-MM-DD")
                : "",
              dayOfWeek: day.dayOfWeek,
              date: moment.utc(day.date).format("YYYY-MM-DD"),
              startTime: day.startTime,
              endTime: day.endTime,
              lunchStartTime: day.lunchStartTime,
              lunchEndTime: day.lunchEndTime,
              vacationHours: day.vacation,
              sickTime: day.sickTime,
              holidayHours: day.holiday,
              totalWorkHours: day.totalWorkHours,
              notes: notes,
            });
          });
        }
      });

      // Save the Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "WeeklyAttendanceSummary.xlsx");
    } else {
      // React Native (mobile) specific handling
      Alert.alert("Export to Excel is not supported on mobile.");
    }
  };

  // Filter attendance data based on search query
  const filteredAttendanceData = attendanceData.filter((item) => {
    const searchLower = searchQuery.toLowerCase();
    const approvedStatus =
      searchLower === "approved"
        ? true
        : searchLower === "not approved"
        ? false
        : null;

    const matches =
      (item.employeeName &&
        item.employeeName.toLowerCase().includes(searchLower)) ||
      (item.employeeId &&
        item.employeeId.toLowerCase().includes(searchLower)) ||
      (item.approvedBy &&
        item.approvedBy.toLowerCase().includes(searchLower)) ||
      (item.date &&
        moment.utc(item.date).format("YYYY-MM-DD").includes(searchLower)) ||
      (item.weekStart &&
        moment
          .utc(item.weekStart)
          .format("YYYY-MM-DD")
          .includes(searchLower)) ||
      (item.approvedDate &&
        moment
          .utc(item.approvedDate)
          .format("YYYY-MM-DD")
          .includes(searchLower)) ||
      (item.weeklyTotalHours !== null &&
        item.weeklyTotalHours !== undefined &&
        item.weeklyTotalHours.toString().includes(searchLower)) ||
      (approvedStatus !== null && item.approved === approvedStatus) ||
      (item.notes &&
        item.notes.some(
          (note) =>
            note.content.toLowerCase().includes(searchLower) ||
            note.supervisorName.toLowerCase().includes(searchLower)
        ));

    return matches;
  });

  const renderAttendance = ({ item }) => {
    const tableData = item.days.map((day) => (
      <DataTable.Row key={day.date}>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.dayOfWeek}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {moment.utc(day.date).format("YYYY-MM-DD")}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.startTime}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.endTime}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.lunchStartTime}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.lunchEndTime}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.vacation}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.sickTime}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.holiday}
          </Text>
        </DataTable.Cell>
        <DataTable.Cell>
          <Text style={colorScheme === "dark" ? styles.cellTextDark : null}>
            {day.totalWorkHours}
          </Text>
        </DataTable.Cell>
      </DataTable.Row>
    ));

    return (
      <View
        style={[
          styles.attendanceContainer,
          colorScheme === "dark" && styles.attendanceContainerDark,
          isDesktop && styles.desktopAttendanceContainer,
        ]}
      >
        <View style={styles.employeeInfo}>
          <View
            style={[
              styles.employeeAvatar,
              colorScheme === "dark" && styles.employeeAvatarDark,
            ]}
          >
            <Text style={styles.avatarText}>
              {item?.employeeName?.charAt(0)}
            </Text>
          </View>
          <View>
            <Text
              style={[
                styles.employeeName,
                colorScheme === "dark" && styles.employeeNameDark,
              ]}
            >
              {item?.employeeName}
            </Text>
            <Text
              style={[
                styles.employeeDetail,
                colorScheme === "dark" && styles.employeeDetailDark,
              ]}
            >
              {item?.employeeId}
            </Text>
            <Text
              style={[
                styles.employeeDetail,
                colorScheme === "dark" && styles.employeeDetailDark,
              ]}
            >
              Submitted On: {moment.utc(item?.date).format("YYYY-MM-DD")}
            </Text>
            <Text
              style={[
                styles.employeeDetail,
                colorScheme === "dark" && styles.employeeDetailDark,
              ]}
            >
              Week of: {moment.utc(item?.weekStart).format("YYYY-MM-DD")}
            </Text>
            <Text
              style={[
                styles.employeeDetail,
                colorScheme === "dark" && styles.employeeDetailDark,
              ]}
            >
              Weekly Total Hours (excl. sick time): {item?.weeklyTotalHours}
            </Text>
            <Text
              style={[
                styles.employeeDetail,
                colorScheme === "dark" && styles.employeeDetailDark,
                { fontStyle: 'italic', fontSize: 12 }
              ]}
            >
              Note: Total hours exclude sick time as per new policy
            </Text>
            {item.approved && item.approvedBy && (
              <Text
                style={[
                  styles.approvedDetail,
                  colorScheme === "dark" && styles.approvedDetailDark,
                ]}
              >
                Approved by: {item.approvedBy}
              </Text>
            )}
            {item.approvedDate && (
              <Text
                style={[
                  styles.approvedDetail,
                  colorScheme === "dark" && styles.approvedDetailDark,
                ]}
              >
                Approved on:{" "}
                {moment.utc(item.approvedDate).format("YYYY-MM-DD")}
              </Text>
            )}
          </View>
        </View>
        <ScrollView horizontal={!isDesktop}>
          <DataTable
            style={[
              styles.dataTable,
              colorScheme === "dark" && styles.dataTableDark,
            ]}
          >
            <DataTable.Header>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Day of Week
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Date
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Start Time
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  End Time
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Lunch Start
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Lunch End
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Vacation Hours
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Sick Time
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Holiday Hours
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text
                  style={colorScheme === "dark" ? styles.headerTextDark : null}
                >
                  Total Work
                </Text>
              </DataTable.Title>
            </DataTable.Header>
            {tableData}
          </DataTable>
        </ScrollView>

        <View
          style={[
            styles.notesSection,
            colorScheme === "dark" && styles.notesSectionDark,
          ]}
        >
          <Text
            style={[
              styles.notesHeader,
              colorScheme === "dark" && styles.notesHeaderDark,
            ]}
          >
            Notes:
          </Text>
          {item.notes && item.notes.length > 0 ? (
            item.notes.map((note, index) => (
              <View
                key={index}
                style={[
                  styles.noteItem,
                  colorScheme === "dark" && styles.noteItemDark,
                ]}
              >
                <Text
                  style={[
                    styles.noteContent,
                    colorScheme === "dark" && styles.noteContentDark,
                  ]}
                >
                  {note.content}
                </Text>
                <Text
                  style={[
                    styles.noteDetails,
                    colorScheme === "dark" && styles.noteDetailsDark,
                  ]}
                >
                  By {note.supervisorName} on{" "}
                  {moment.utc(note.date).format("YYYY-MM-DD")}
                </Text>
              </View>
            ))
          ) : (
            <Text
              style={[
                styles.noNotesText,
                colorScheme === "dark" && styles.noNotesTextDark,
              ]}
            >
              No notes available
            </Text>
          )}
        </View>

        <View style={styles.addNoteSection}>
          <TextInput
            style={[
              styles.noteInput,
              colorScheme === "dark" && styles.noteInputDark,
            ]}
            value={newNote}
            onChangeText={setNewNote}
            placeholder="Add a new note..."
            placeholderTextColor={colorScheme === "dark" ? "#888" : "#999"}
            multiline
          />
          <Pressable
            style={[
              styles.addNoteButton,
              colorScheme === "dark" && styles.addNoteButtonDark,
            ]}
            onPress={() => addNote(item._id)}
          >
            <Text style={styles.addNoteButtonText}>Add Note</Text>
          </Pressable>
        </View>

        {!item.approved && (
          <Pressable
            style={[
              styles.approveButton,
              colorScheme === "dark" && styles.approveButtonDark,
            ]}
            onPress={() => approveAttendance(item)}
          >
            <Text style={styles.approveButtonText}>Approve</Text>
          </Pressable>
        )}
      </View>
    );
  };

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#000000"} />
        <Text
          style={[
            styles.headerText,
            colorScheme === "dark" && styles.headerTextDark,
          ]}
        >
          Weekly Attendance Summary
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#000000"}
          />
        </TouchableOpacity>
      </View>
      <View style={[styles.content, isDesktop && styles.desktopContent]}>
        <TextInput
          style={[
            styles.searchBar,
            colorScheme === "dark" && styles.searchBarDark,
          ]}
          placeholder="Search by any field"
          placeholderTextColor={colorScheme === "dark" ? "#888" : "#999"}
          value={searchQuery}
          onChangeText={setSearchQuery}
        />
        <Button title="Export to Excel" onPress={generateExcel} />
        <FlatList
          data={filteredAttendanceData}
          renderItem={renderAttendance}
          keyExtractor={(item) => item._id}
          contentContainerStyle={isDesktop && styles.desktopList}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f9f9f9",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  headerTextDark: {
    color: "#FFFFFF",
  },
  content: {
    flex: 1,
    padding: 10,
  },
  desktopContent: {
    paddingHorizontal: 20,
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  searchBar: {
    padding: 10,
    backgroundColor: "#fff",
    borderRadius: 5,
    marginBottom: 10,
    borderColor: "#ccc",
    borderWidth: 1,
  },
  searchBarDark: {
    backgroundColor: "#333",
    borderColor: "#555",
    color: "#FFFFFF",
  },
  attendanceContainer: {
    marginVertical: 10,
    padding: 10,
    backgroundColor: "#fff",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 5,
  },
  attendanceContainerDark: {
    backgroundColor: "#2C2C2C",
    shadowColor: "#FFF",
  },
  desktopAttendanceContainer: {
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  employeeInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  employeeAvatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: "#4b6cb7",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  employeeAvatarDark: {
    backgroundColor: "#6A8ED4",
  },
  avatarText: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
  },
  employeeName: {
    fontSize: 16,
    fontWeight: "bold",
  },
  employeeNameDark: {
    color: "#FFFFFF",
  },
  employeeDetail: {
    marginTop: 5,
    color: "gray",
  },
  employeeDetailDark: {
    color: "#CCCCCC",
  },
  approvedDetail: {
    marginTop: 5,
    color: "green",
    fontStyle: "italic",
  },
  approvedDetailDark: {
    color: "#4CAF50",
  },
  dataTable: {
    marginTop: 10,
  },
  dataTableDark: {
    backgroundColor: "#333",
  },
  headerTextDark: {
    color: "#FFFFFF",
  },
  cellTextDark: {
    color: "#FFFFFF",
  },
  notesSection: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "#f0f0f0",
    borderRadius: 5,
  },
  notesSectionDark: {
    backgroundColor: "#333",
  },
  notesHeader: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  notesHeaderDark: {
    color: "#FFFFFF",
  },
  noteItem: {
    marginBottom: 10,
    padding: 5,
    backgroundColor: "#fff",
    borderRadius: 3,
  },
  noteItemDark: {
    backgroundColor: "#2C2C2C",
  },
  noteContent: {
    fontSize: 14,
  },
  noteContentDark: {
    color: "#FFFFFF",
  },
  noteDetails: {
    fontSize: 12,
    color: "gray",
    marginTop: 5,
  },
  noteDetailsDark: {
    color: "#AAAAAA",
  },
  noNotesText: {
    fontStyle: "italic",
    color: "gray",
  },
  noNotesTextDark: {
    color: "#AAAAAA",
  },
  addNoteSection: {
    marginTop: 15,
  },
  noteInput: {
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    minHeight: 80,
    textAlignVertical: "top",
  },
  noteInputDark: {
    borderColor: "#555",
    backgroundColor: "#333",
    color: "#FFFFFF",
  },
  addNoteButton: {
    backgroundColor: "#4b6cb7",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  addNoteButtonDark: {
    backgroundColor: "#6A8ED4",
  },
  addNoteButtonText: {
    color: "white",
    fontSize: 16,
  },
  approveButton: {
    backgroundColor: "#4b6cb7",
    padding: 10,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  approveButtonDark: {
    backgroundColor: "#6A8ED4",
  },
  approveButtonText: {
    color: "white",
    fontSize: 16,
  },
  desktopList: {
    paddingHorizontal: 20,
  },
});

export default WeeklyAttendanceSummary;
