import { StyleSheet } from "react-native";

const UserStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F9FA",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "#FFFFFF",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333333",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#000000",
    flex: 1,
    textAlign: "center",
  },
  headerTextDark: {
    color: "#FFFFFF",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  scrollView: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 16,
  },
  desktopContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginHorizontal: "auto",
  },
  column: {
    flex: 1,
  },
  leftColumn: {
    marginRight: 20,
    flex: 2,
  },
  rightColumn: {
    flex: 1,
    marginTop: 20,
  },
  welcomeContainer: {
    padding: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginBottom: 20,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  welcomeContainerDark: {
    backgroundColor: "#1E1E1E",
  },
  welcomeText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333333",
    marginBottom: 10,
  },
  welcomeTextDark: {
    color: "#E0E0E0",
  },
  dateRangeText: {
    fontSize: 14,
    color: "#666666",
  },
  dateRangeTextDark: {
    color: "#BBBBBB",
  },
  noteText: {
    fontSize: 14,
    color: "#dc3545",
    marginTop: 10,
  },
  noteTextDark: {
    color: "#ff6b6b",
  },
  dayCard: {
    backgroundColor: "#FFFFFF",
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    minHeight: 320,
  },
  dayCardDark: {
    backgroundColor: "#1E1E1E",
  },
  currentDayCard: {
    borderColor: "#007AFF",
    borderWidth: 2,
  },
  dayText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333333",
    marginBottom: 10,
  },
  dayTextDark: {
    color: "#FFFFFF",
  },
  savedBadge: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "#28a745",
    borderRadius: 5,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  savedBadgeText: {
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: "bold",
  },
  timeInputsRow: {
    flexDirection: "column",
    marginBottom: 15,
    gap: 8,
  },
  timeInputWrapper: {
    marginBottom: 12,
    width: "100%",
  },
  inputLabel: {
    fontSize: 12,
    color: "#666666",
    marginBottom: 5,
  },
  inputLabelDark: {
    color: "#CCCCCC",
  },
  timeInput: {
    flex: 2,
    borderWidth: 1,
    borderColor: "#DDDDDD",
    borderRadius: 5,
    padding: 8,
    fontSize: 14,
    height: 40,
    marginRight: 5,
  },
  timeInputDark: {
    borderColor: "#333333",
    color: "#FFFFFF",
  },
  pickerContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
  },
  amPmPicker: {
    height: 40,
  },
  specialHoursContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 10,
  },
  specialHoursWrapper: {
    width: "30%",
    marginBottom: 10,
  },
  specialHoursInput: {
    borderWidth: 1,
    borderColor: "#DDDDDD",
    borderRadius: 5,
    padding: 8,
    fontSize: 14,
    height: 40,
  },
  specialHoursInputDark: {
    borderColor: "#333333",
    color: "#FFFFFF",
  },
  totalHoursText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333333",
    marginTop: 10,
  },
  totalHoursTextDark: {
    color: "#FFFFFF",
  },
  saveButton: {
    backgroundColor: "#28a745",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  saveButtonDark: {
    backgroundColor: "#1E6E34",
  },
  disabledButton: {
    backgroundColor: "#CCCCCC",
  },
  disabledButtonDark: {
    backgroundColor: "#444444",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  disabledButtonText: {
    color: "#666666",
  },
  disabledButtonTextDark: {
    color: "#999999",
  },
  summaryCard: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  summaryCardDark: {
    backgroundColor: "#2C2C2C",
  },
  summaryText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333333",
    marginBottom: 15,
  },
  summaryTextDark: {
    color: "#E0E0E0",
  },
  submissionStatusContainer: {
    backgroundColor: "#F0F0F0",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  submissionStatusContainerDark: {
    backgroundColor: "#333333",
  },
  submissionStatusText: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    color: "#333333",
  },
  submissionStatusTextDark: {
    color: "#E0E0E0",
  },
  signatureContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  checkbox: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: "#4CAF50",
    borderRadius: 4,
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  checkboxDark: {
    borderColor: "#1E6E34",
  },
  signatureText: {
    flex: 1,
    fontSize: 14,
    color: "#333333",
  },
  signatureTextDark: {
    color: "#E0E0E0",
  },
  termsLink: {
    color: "#007AFF",
    textDecorationLine: "underline",
  },
  termsLinkDark: {
    color: "#4da6ff",
  },
  submitButton: {
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  submitButtonDark: {
    backgroundColor: "#0056b3",
  },
  actionButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  actionButton: {
    backgroundColor: "#6c757d",
    padding: 15,
    borderRadius: 5,
    flex: 1,
    marginHorizontal: 5,
    alignItems: "center",
  },
  actionButtonDark: {
    backgroundColor: "#4a4a4a",
  },
  actionButtonText: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: "bold",
  },
  sectionHeader: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#333333",
    marginBottom: 15,
  },
  sectionHeaderDark: {
    color: "#E0E0E0",
  },
  historyCard: {
    backgroundColor: "#FFFFFF",
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  historyCardDark: {
    backgroundColor: "#2C2C2C",
  },
  historyWeekOf: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333333",
    marginBottom: 10,
  },
  historyWeekOfDark: {
    color: "#E0E0E0",
  },
  historyDay: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#EEEEEE",
  },
  historyDayDark: {
    borderBottomColor: "#444444",
  },
  historyDate: {
    fontSize: 14,
    color: "#666666",
  },
  historyDateDark: {
    color: "#BBBBBB",
  },
  historyHours: {
    fontSize: 14,
    color: "#333333",
  },
  historyHoursDark: {
    color: "#E0E0E0",
  },
  historyTotalHours: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333333",
    marginTop: 10,
    textAlign: "right",
  },
  historyTotalHoursDark: {
    color: "#E0E0E0",
  },
  historyHighlight: {
    backgroundColor: "#E0F7FA",
  },
  historyHighlightDark: {
    backgroundColor: "#003A63",
  },

  // New and updated styles for mobile responsiveness
  timeSection: {
    marginBottom: 16,
  },
  timeInputContainer: {
    marginBottom: 12,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: "500",
    color: "#333",
    marginBottom: 8,
  },
  sectionTitleDark: {
    color: "#fff",
  },
  timeInputGroup: {
    width: "100%",
    gap: 12,
  },
  timeInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  totalTimeCell: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    padding: 4,
    width: 60,
    marginLeft: 8,
  },
  totalTimeCellDark: {
    backgroundColor: "#3C3C3C",
    borderColor: "#555555",
    borderWidth: 1,
  },
  totalTimeCellLight: {
    backgroundColor: "#F0F0F0",
    borderColor: "#CCCCCC",
    borderWidth: 1,
  },
  lunchContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
    flexWrap: "wrap",
  },
  lunchInput: {
    flex: 1,
    marginRight: 8,
    minWidth: 120,
  },
  serviceInput: {
    height: 36,
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 8,
    marginBottom: 4,
    width: "100%",
  },
  serviceInputDark: {
    borderColor: "#555555",
    color: "#FFFFFF",
    backgroundColor: "#2C2C2C",
  },
  serviceInputLight: {
    borderColor: "#CCCCCC",
    color: "#000000",
    backgroundColor: "#FFFFFF",
  },
  removeServiceButton: {
    backgroundColor: "#FF3B30",
    padding: 8,
    borderRadius: 4,
    alignItems: "center",
    marginTop: 4,
    width: "100%",
  },
  addServiceButton: {
    backgroundColor: "#0A84FF",
    padding: 8,
    borderRadius: 4,
    alignItems: "center",
    marginTop: 8,
    width: "100%",
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 8,
    paddingTop: 8,
    borderTopWidth: 1,
  },
  totalLabel: {
    fontWeight: "bold",
    fontSize: 14,
  },
  totalLabelDark: {
    color: "#E0E0E0",
    borderTopColor: "#555555",
  },
  totalLabelLight: {
    color: "#333333",
    borderTopColor: "#CCCCCC",
  },
  totalTime: {
    fontWeight: "bold",
    fontSize: 14,
  },
  totalTimeDark: {
    color: "#E0E0E0",
  },
  totalTimeLight: {
    color: "#333333",
  },
  standBySwitchContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  standByLabel: {
    fontSize: 14,
    marginRight: 8,
  },
  standByLabelDark: {
    color: "#E0E0E0",
  },
  standByLabelLight: {
    color: "#333333",
  },
  serviceCountText: {
    fontSize: 14,
    marginBottom: 4,
    fontWeight: "bold",
  },
  serviceCountTextDark: {
    color: "#E0E0E0",
  },
  serviceCountTextLight: {
    color: "#333333",
  },
  serviceRow: {
    marginBottom: 8,
  },
  totalTimeLabel: {
    fontSize: 10,
    color: "#888888",
  },
  totalTimeValue: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#E0E0E0",
  },
  updateButton: {
    backgroundColor: "#007AFF",
  },
  updateButtonDark: {
    backgroundColor: "#0056b3",
  },
  updateButtonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  updateButtonTextDark: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  weeklyTotalContainer: {
    backgroundColor: "#F0F0F0",
    padding: 15,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  weeklyTotalContainerDark: {
    backgroundColor: "#2C2C2C",
  },
  weeklyTotalText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333333",
    textAlign: "center",
  },
  weeklyTotalTextDark: {
    color: "#E0E0E0",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    width: "90%",
    maxWidth: 400,
  },
  modalContentDark: {
    backgroundColor: "#1E1E1E",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    textAlign: "center",
    color: "#333333",
  },
  modalTitleDark: {
    color: "#E0E0E0",
  },
  modalButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  modalButton: {
    flex: 1,
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginHorizontal: 5,
  },
  modalButtonDisabled: {
    backgroundColor: "#CCCCCC",
  },
  modalButtonDisabledDark: {
    backgroundColor: "#444444",
  },
  modalButtonText: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: 16,
  },
  cancelButton: {
    backgroundColor: "#F0F0F0",
  },
  cancelButtonDark: {
    backgroundColor: "#4a4a4a",
  },
  cancelButtonText: {
    color: "#333333",
    fontSize: 16,
  },
  cancelButtonTextDark: {
    color: "#E0E0E0",
  },
  errorText: {
    color: "#FF3B30",
    fontSize: 12,
    marginTop: 4,
  },
});

export default UserStyles;
