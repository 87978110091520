import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  SafeAreaView,
  FlatList,
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import axios from "axios";
import moment from "moment-timezone";
import { useLocalSearchParams } from "expo-router";
import { useNavigation } from "@react-navigation/native";
import { Ionicons, Feather } from "@expo/vector-icons";

const BackButton = ({ color }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
      accessible={true}
      accessibilityLabel="Go Back"
    >
      <Ionicons name="arrow-back" size={24} color={color} />
    </TouchableOpacity>
  );
};

const ExpandableTimeDetails = ({ day, dynamicStyles }) => {
  const [expanded, setExpanded] = useState(false);

  const specialTimes = useMemo(() => {
    return [
      {
        type: "Vacation",
        amount: day.hours.find((h) => h.type === "vacation")?.amount || 0,
      },
      {
        type: "Sick",
        amount: day.hours.find((h) => h.type === "sick")?.amount || 0,
      },
      {
        type: "Holiday",
        amount: day.hours.find((h) => h.type === "holiday")?.amount || 0,
      },
    ].filter((time) => time.amount > 0);
  }, [day.hours]);

  const totalSpecialTime = useMemo(() => {
    return specialTimes.reduce((sum, time) => sum + time.amount, 0);
  }, [specialTimes]);

  return (
    <TouchableOpacity
      onPress={() => setExpanded(!expanded)}
      style={[
        styles.expandableTimeDetails,
        dynamicStyles.expandableTimeDetails,
      ]}
      accessible={true}
      accessibilityLabel={
        expanded
          ? "Collapse special time details"
          : "Expand special time details"
      }
    >
      <Text
        style={[styles.expandableTimeTotal, dynamicStyles.expandableTimeTotal]}
      >
        {totalSpecialTime > 0 ? totalSpecialTime.toFixed(1) : "-"}
      </Text>
      {expanded && totalSpecialTime > 0 && (
        <View style={{ marginTop: 5 }}>
          {specialTimes.map((time, index) => (
            <Text
              key={index}
              style={[
                styles.expandableTimeBreakdown,
                dynamicStyles.expandableTimeBreakdown,
              ]}
            >
              {time.type}: {time.amount.toFixed(1)}
            </Text>
          ))}
        </View>
      )}
    </TouchableOpacity>
  );
};

const AttendanceHistory = () => {
  const params = useLocalSearchParams();
  const [attendanceHistory, setAttendanceHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalHoursWorked, setTotalHoursWorked] = useState(0);
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);

  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme, isDesktop),
    [colorScheme, isDesktop]
  );

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    if (params?.id) {
      console.log(`Fetching attendance history for ID: ${params.id}`);
      fetchAttendanceHistory(params.id);
    }
  }, [params?.id]);

  const fetchAttendanceHistory = async (id) => {
    setLoading(true);
    try {
      console.log(`API Request for ID: ${id}`);
      const response = await axios.get(
        `https://sbg-backend.onrender.com/attendances/${id}/history`
      );
      console.log("Response data:", response.data);
      if (response.data && response.data.length > 0) {
        const sortedData = response.data.sort(
          (a, b) => new Date(b.weekStart) - new Date(a.weekStart)
        );
        setAttendanceHistory(sortedData);
        calculateTotalHoursWorked(sortedData);
        setCurrentPeriodIndex(0);
      } else {
        setAttendanceHistory([]);
        setTotalHoursWorked(0);
        setCurrentPeriodIndex(0);
      }
    } catch (error) {
      console.error("Error fetching attendance history:", error);
      setAttendanceHistory([]);
      setTotalHoursWorked(0);
      setCurrentPeriodIndex(0);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalHoursWorked = (data) => {
    let totalHours = 0;
    data.forEach((record) => {
      record.days.forEach((day) => {
        const workHours = day.hours?.find(h => h.type === 'work')?.amount || 0;
        totalHours += workHours;
      });
    });
    setTotalHoursWorked(totalHours);
  };

  const renderRegularTable = (item, isDesktop) => (
    <View
      style={[
        styles.tableContainer,
        isDesktop && styles.desktopTableContainer,
        dynamicStyles.tableContainer,
      ]}
    >
      {/* Table Header */}
      <View
        style={[
          styles.tableHeader,
          isDesktop && styles.desktopTableHeader,
          dynamicStyles.tableHeader,
        ]}
      >
        {["Day", "Date", "Start", "End", "Lunch", "Special Time", "Total"].map(
          (header, index) => (
            <Text
              key={index}
              style={[
                styles.headerCell,
                isDesktop && styles.desktopHeaderCell,
                dynamicStyles.headerCell,
              ]}
            >
              {header}
            </Text>
          )
        )}
      </View>

      {/* Table Rows */}
      {item.days.map((day, index) => (
        <View
          key={index}
          style={[
            styles.tableRow,
            isDesktop && styles.desktopTableRow,
            dynamicStyles.tableRow,
          ]}
        >
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {moment(day.date).format("ddd")}
          </Text>
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {moment(day.date).format("MM/DD")}
          </Text>
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {day.startTime || "-"}
          </Text>
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {day.endTime || "-"}
          </Text>
          <Text
            style={[
              styles.tableCell,
              isDesktop && styles.desktopTableCell,
              dynamicStyles.tableCell,
            ]}
          >
            {day.lunchStartTime && day.lunchEndTime
              ? `${day.lunchStartTime}-${day.lunchEndTime}`
              : "-"}
          </Text>
          <View style={styles.tableCell}>
            <ExpandableTimeDetails day={day} dynamicStyles={dynamicStyles} />
          </View>
          <Text
            style={[
              styles.tableCell,
              styles.totalHoursCell,
              dynamicStyles.tableCell,
              dynamicStyles.totalHoursCell,
            ]}
          >
            {day.totalWorkHours.toFixed(1)}
          </Text>
        </View>
      ))}

      {/* Weekly Total Row */}
      <View
        style={[
          styles.tableRow,
          styles.weeklyTotalRow,
          isDesktop && styles.desktopTableRow,
          dynamicStyles.tableRow,
          dynamicStyles.weeklyTotalRow,
        ]}
      >
        <Text
          style={[
            styles.tableCell,
            styles.weeklyTotalCell,
            dynamicStyles.tableCell,
            dynamicStyles.weeklyTotalCell,
          ]}
        >
          Weekly Total:
        </Text>
        <Text
          style={[
            styles.tableCell,
            styles.weeklyTotalHours,
            dynamicStyles.tableCell,
            dynamicStyles.weeklyTotalHours,
          ]}
        >
          {item.weeklyTotalHours.toFixed(2)}
        </Text>
      </View>
    </View>
  );

  const renderSpecialFormatTable = (item, isDesktop) => (
    <View
      style={[
        styles.tableContainer,
        isDesktop && styles.desktopTableContainer,
        dynamicStyles.tableContainer,
      ]}
    >
      {/* Table Header */}
      <View
        style={[
          styles.tableHeader,
          isDesktop && styles.desktopTableHeader,
          dynamicStyles.tableHeader,
        ]}
      >
        {[
          "Day",
          "Date",
          "Service",
          "Start",
          "End",
          "Lunch",
          "Total",
          "Stand-by",
          "Day Total",
        ].map((header, index) => (
          <Text
            key={index}
            style={[
              styles.headerCell,
              isDesktop && styles.desktopHeaderCell,
              dynamicStyles.headerCell,
              header === "Service" && styles.serviceCell,
              isDesktop && header === "Service" && styles.desktopServiceCell,
            ]}
          >
            {header}
          </Text>
        ))}
      </View>

      {/* Table Rows */}
      {item.days.map((day, dayIndex) =>
        day.services && day.services.length > 0 ? (
          day.services.map((service, serviceIndex) => (
            <View
              key={`${dayIndex}-${serviceIndex}`}
              style={[
                styles.tableRow,
                isDesktop && styles.desktopTableRow,
                dynamicStyles.tableRow,
              ]}
            >
              <Text
                style={[
                  styles.tableCell,
                  isDesktop && styles.desktopTableCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {serviceIndex === 0 ? moment(day.date).format("ddd") : ""}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  isDesktop && styles.desktopTableCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {serviceIndex === 0 ? moment(day.date).format("MM/DD") : ""}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.serviceCell,
                  isDesktop && styles.desktopServiceCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {service.service}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  isDesktop && styles.desktopTableCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {service.startTime || "-"}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  isDesktop && styles.desktopTableCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {service.endTime || "-"}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  isDesktop && styles.desktopTableCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {serviceIndex === 0 && day.lunchStartTime && day.lunchEndTime
                  ? `${day.lunchStartTime}-${day.lunchEndTime}`
                  : "-"}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  isDesktop && styles.desktopTableCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {service.totalTime.toFixed(2)}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  isDesktop && styles.desktopTableCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {serviceIndex === 0 ? day.standByHours.toFixed(2) : ""}
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  isDesktop && styles.desktopTableCell,
                  dynamicStyles.tableCell,
                ]}
              >
                {serviceIndex === 0 ? day.totalHours.toFixed(2) : ""}
              </Text>
            </View>
          ))
        ) : (
          <View
            key={dayIndex}
            style={[
              styles.tableRow,
              isDesktop && styles.desktopTableRow,
              dynamicStyles.tableRow,
            ]}
          >
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {moment(day.date).format("ddd")}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {moment(day.date).format("MM/DD")}
            </Text>
            <Text
              style={[
                styles.tableCell,
                styles.serviceCell,
                isDesktop && styles.desktopServiceCell,
                dynamicStyles.tableCell,
              ]}
            >
              -
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              -
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              -
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              -
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              0.00
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {day.standByHours.toFixed(2)}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {day.totalHours.toFixed(2)}
            </Text>
          </View>
        )
      )}

      {/* Weekly Total Row for Special Format */}
      <View
        style={[
          styles.tableRow,
          styles.weeklyTotalRow,
          isDesktop && styles.desktopTableRow,
          dynamicStyles.tableRow,
          dynamicStyles.weeklyTotalRow,
        ]}
      >
        <Text
          style={[
            styles.tableCell,
            styles.weeklyTotalCell,
            dynamicStyles.tableCell,
            dynamicStyles.weeklyTotalCell,
          ]}
        >
          Weekly Total:
        </Text>
        <Text
          style={[
            styles.tableCell,
            styles.weeklyTotalHours,
            dynamicStyles.tableCell,
            dynamicStyles.weeklyTotalHours,
          ]}
        >
          {item.weeklyTotalHours.toFixed(2)}
        </Text>
      </View>
    </View>
  );

  const renderAttendanceHistory = ({ item }) => (
    <View style={[styles.historyContainer, dynamicStyles.historyContainer]}>
      <Text style={[styles.historyWeek, dynamicStyles.historyWeek]}>
        Pay Period Starting:{" "}
        {moment.tz(item.weekStart, "America/Phoenix").format("MMMM D, YYYY")}
      </Text>
      {item.isSpecialFormat
        ? renderSpecialFormatTable(item, isDesktop)
        : renderRegularTable(item, isDesktop)}
    </View>
  );

  const goToNextPeriod = useCallback(() => {
    if (currentPeriodIndex < attendanceHistory.length - 1) {
      setCurrentPeriodIndex(currentPeriodIndex + 1);
    }
  }, [currentPeriodIndex, attendanceHistory.length]);

  const goToPreviousPeriod = useCallback(() => {
    if (currentPeriodIndex > 0) {
      setCurrentPeriodIndex(currentPeriodIndex - 1);
    }
  }, [currentPeriodIndex]);

  const paginatedAttendanceHistory = useMemo(() => {
    if (attendanceHistory.length === 0) return [];
    return [attendanceHistory[currentPeriodIndex]];
  }, [attendanceHistory, currentPeriodIndex]);

  const filteredAttendanceHistory = useMemo(() => {
    if (searchQuery.trim() === "") {
      return paginatedAttendanceHistory;
    }
    const query = searchQuery.toLowerCase();
    return paginatedAttendanceHistory.filter((item) => {
      return (
        moment
          .tz(item.weekStart, "America/Phoenix")
          .format("YYYY-MM-DD")
          .toLowerCase()
          .includes(query) ||
        item.days.some((day) =>
          [
            moment.tz(day.date, "America/Phoenix").format("YYYY-MM-DD"),
            day.dayOfWeek,
            day.startTime,
            day.endTime,
            day.lunchStartTime,
            day.lunchEndTime,
            day.totalWorkHours,
            day.hours.find((h) => h.type === "vacation")?.amount,
            day.hours.find((h) => h.type === "sick")?.amount,
            day.hours.find((h) => h.type === "holiday")?.amount,
          ]
            .map((field) => field && field.toString().toLowerCase())
            .some((field) => field && field.includes(query))
        )
      );
    });
  }, [paginatedAttendanceHistory, searchQuery]);

  return (
    <SafeAreaView style={[styles.container, dynamicStyles.container]}>
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#121212" : "#F8F9FA"}
      />
      <View style={[styles.header, dynamicStyles.header]}>
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#000000"} />
        <Text style={[styles.headerTitle, dynamicStyles.headerTitle]}>
          Attendance History
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
          accessible={true}
          accessibilityLabel="Toggle Theme"
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#000000"}
          />
        </TouchableOpacity>
      </View>
      <View style={[styles.content, isDesktop && styles.desktopContent]}>
        <TextInput
          style={[styles.searchBar, dynamicStyles.searchBar]}
          placeholder="Search by any field"
          placeholderTextColor={colorScheme === "dark" ? "#999" : "#666"}
          value={searchQuery}
          onChangeText={setSearchQuery}
          accessible={true}
          accessibilityLabel="Search Attendance History"
        />
        {attendanceHistory.length > 0 && (
          <View style={styles.navigationContainer}>
            <TouchableOpacity
              onPress={goToPreviousPeriod}
              disabled={currentPeriodIndex === 0}
              style={[
                styles.navButton,
                currentPeriodIndex === 0 && styles.navButtonDisabled,
              ]}
              accessible={true}
              accessibilityLabel="Previous Pay Period"
            >
              <Feather
                name="arrow-left"
                size={24}
                color={
                  currentPeriodIndex === 0
                    ? "#888"
                    : colorScheme === "dark"
                    ? "#FFFFFF"
                    : "#007AFF"
                }
              />
            </TouchableOpacity>

            <Text
              style={[styles.periodIndicator, dynamicStyles.periodIndicator]}
            >
              Pay Period {currentPeriodIndex + 1} of {attendanceHistory.length}
            </Text>

            <TouchableOpacity
              onPress={goToNextPeriod}
              disabled={currentPeriodIndex === attendanceHistory.length - 1}
              style={[
                styles.navButton,
                currentPeriodIndex === attendanceHistory.length - 1 &&
                  styles.navButtonDisabled,
              ]}
              accessible={true}
              accessibilityLabel="Next Pay Period"
            >
              <Feather
                name="arrow-right"
                size={24}
                color={
                  currentPeriodIndex === attendanceHistory.length - 1
                    ? "#888"
                    : colorScheme === "dark"
                    ? "#FFFFFF"
                    : "#007AFF"
                }
              />
            </TouchableOpacity>
          </View>
        )}
        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator
              size="large"
              color={colorScheme === "dark" ? "#FFFFFF" : "#0000ff"}
            />
          </View>
        ) : (
          <>
            <Text style={[styles.totalHoursText, dynamicStyles.totalHoursText]}>
              Total Hours Worked: {totalHoursWorked.toFixed(2)}
            </Text>
            <FlatList
              data={filteredAttendanceHistory}
              renderItem={renderAttendanceHistory}
              keyExtractor={(item) => item._id}
              ListEmptyComponent={
                <Text style={dynamicStyles.emptyText}>
                  No attendance history found
                </Text>
              }
              contentContainerStyle={isDesktop && styles.desktopList}
              accessible={true}
              accessibilityLabel="Attendance History List"
            />
          </>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f9f9f9",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    backgroundColor: "#fff",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#000",
  },
  themeToggle: {
    padding: 10,
  },
  content: {
    flex: 1,
    padding: 10,
  },
  desktopContent: {
    paddingHorizontal: 20,
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  backButton: {
    padding: 10,
  },
  searchBar: {
    padding: 10,
    backgroundColor: "#fff",
    borderRadius: 5,
    marginBottom: 10,
    borderColor: "#ccc",
    borderWidth: 1,
    color: "#000",
  },
  historyContainer: {
    padding: 10,
    backgroundColor: "#fff",
    borderRadius: 5,
    marginVertical: 5,
    borderColor: "#ccc",
    borderWidth: 1,
  },
  historyWeek: {
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 10,
    color: "#000",
  },
  tableContainer: {
    marginTop: 15,
    borderRadius: 8,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#333",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#1E1E1E",
    paddingVertical: 10,
    paddingHorizontal: 5,
  },
  headerCell: {
    color: "#FFFFFF",
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
    fontSize: 12,
    paddingHorizontal: 2,
  },
  tableRow: {
    flexDirection: "row",
    borderTopWidth: 1,
    borderTopColor: "#333",
    paddingVertical: 8,
    paddingHorizontal: 5,
  },
  tableCell: {
    color: "#CCCCCC",
    flex: 1,
    textAlign: "center",
    fontSize: 12,
    paddingHorizontal: 2,
  },
  serviceCell: {
    flex: 2,
    textAlign: "left",
  },
  expandableTimeDetails: {
    padding: 5,
  },
  expandableTimeTotal: {
    fontSize: 14,
    color: "#FFFFFF",
  },
  expandableTimeBreakdown: {
    fontSize: 12,
    color: "#CCCCCC",
    marginTop: 2,
  },
  totalHoursCell: {
    fontWeight: "bold",
  },
  weeklyTotalRow: {
    borderTopWidth: 2,
    borderTopColor: "#444",
  },
  weeklyTotalCell: {
    flex: 6,
    textAlign: "right",
    fontWeight: "bold",
  },
  weeklyTotalHours: {
    flex: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
  totalHoursText: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginVertical: 10,
    color: "#000",
  },
  navigationContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  navButton: {
    padding: 10,
  },
  navButtonDisabled: {
    opacity: 0.5,
  },
  periodIndicator: {
    marginHorizontal: 20,
    fontSize: 16,
    color: "#000",
  },
  desktopList: {
    paddingHorizontal: 20,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

const createDynamicStyles = (colorScheme, isDesktop) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#F8F9FA",
  },
  header: {
    backgroundColor: colorScheme === "dark" ? "#1E1E1E" : "white",
    borderBottomColor: colorScheme === "dark" ? "#333" : "#E0E0E0",
  },
  headerTitle: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  searchBar: {
    backgroundColor: colorScheme === "dark" ? "#333" : "#fff",
    borderColor: colorScheme === "dark" ? "#555" : "#ccc",
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  historyContainer: {
    backgroundColor: colorScheme === "dark" ? "#2C2C2C" : "#fff",
    borderColor: colorScheme === "dark" ? "#555" : "#ccc",
  },
  historyWeek: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000",
  },
  tableContainer: {
    borderColor: colorScheme === "dark" ? "#555" : "#ccc",
  },
  tableHeader: {
    backgroundColor: colorScheme === "dark" ? "#1E1E1E" : "#f2f2f2",
  },
  headerCell: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  tableRow: {
    borderTopColor: colorScheme === "dark" ? "#444" : "#e0e0e0",
  },
  tableCell: {
    color: colorScheme === "dark" ? "#CCCCCC" : "#333333",
  },
  expandableTimeDetails: {
    backgroundColor: colorScheme === "dark" ? "#333" : "#f0f0f0",
  },
  expandableTimeTotal: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  expandableTimeBreakdown: {
    color: colorScheme === "dark" ? "#CCCCCC" : "#666666",
  },
  totalHoursCell: {
    color: colorScheme === "dark" ? "#4CAF50" : "#28a745",
  },
  weeklyTotalRow: {
    backgroundColor: colorScheme === "dark" ? "#1E1E1E" : "#f8f9fa",
    borderTopColor: colorScheme === "dark" ? "#444" : "#dee2e6",
  },
  weeklyTotalCell: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  weeklyTotalHours: {
    color: colorScheme === "dark" ? "#4CAF50" : "#28a745",
  },
  totalHoursText: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  periodIndicator: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  emptyText: {
    color: colorScheme === "dark" ? "#AAAAAA" : "#666666",
    textAlign: "center",
    marginTop: 20,
    fontSize: 16,
  },
});

export default AttendanceHistory;
