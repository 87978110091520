import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Alert,
  Platform,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
} from "react-native";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import { Feather, AntDesign } from "@expo/vector-icons";
import CustomAlert from "./CustomAlert";

const REGEX = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  mobile: /^\d{10}$/,
  date: /^\d{4}-\d{2}-\d{2}$/,
};

const BackButton = ({ color }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

const Adddetails = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [employeeMobileNumber, setEmployeeMobileNumber] = useState("");
  const [supervisors, setSupervisors] = useState([{ id: "", email: "" }]);
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;
  const [alertConfig, setAlertConfig] = useState({
    visible: false,
    title: "",
    message: "",
    showCancel: false,
  });

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  const generateEmployeeId = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const handleAddSupervisor = () => {
    setSupervisors([...supervisors, { id: "", email: "" }]);
  };

  const handleRemoveSupervisor = (index) => {
    const newSupervisors = supervisors.filter((_, i) => i !== index);
    setSupervisors(newSupervisors);
  };

  const handleSupervisorChange = (field, value, index) => {
    const newSupervisors = [...supervisors];
    newSupervisors[index][field] = value;
    setSupervisors(newSupervisors);
  };

  const showCustomAlert = (title, message, showCancel = false, onConfirm) => {
    setAlertConfig({
      visible: true,
      title,
      message,
      showCancel,
      onConfirm,
    });
  };

  const handleCloseAlert = () => {
    setAlertConfig((prev) => ({ ...prev, visible: false }));
  };

  const validateForm = () => {
    const requiredFields = {
      employeeName: name,
      email: email,
      jobTitle: jobTitle,
      employeeMobileNumber: employeeMobileNumber,
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([_, value]) => !value.trim())
      .map(([field, _]) => {
        const fieldNames = {
          employeeName: "Full Name",
          email: "Email",
          jobTitle: "Job Title",
          employeeMobileNumber: "Mobile Number",
        };
        return fieldNames[field];
      });

    if (missingFields.length > 0) {
      const message =
        "The following fields are required:\n\n" +
        missingFields.map((field) => `• ${field}`).join("\n");
      showCustomAlert("Validation Error", message);
      return false;
    }

    // Email validation
    if (!REGEX.email.test(email.trim())) {
      showCustomAlert("Validation Error", "Please enter a valid email address");
      return false;
    }

    // Mobile number validation
    if (!REGEX.mobile.test(employeeMobileNumber.trim())) {
      showCustomAlert("Validation Error", "Mobile number must be 10 digits");
      return false;
    }

    // Hire date validation
    if (hireDate.trim() && !REGEX.date.test(hireDate.trim())) {
      showCustomAlert(
        "Validation Error",
        "Hire date must be in YYYY-MM-DD format"
      );
      return false;
    }

    // Supervisor validation
    const validSupervisors = supervisors.filter(
      (sup) =>
        sup &&
        sup.id &&
        sup.email &&
        sup.id.trim() !== "" &&
        sup.email.trim() !== ""
    );

    if (validSupervisors.length === 0) {
      showCustomAlert(
        "Validation Error",
        "At least one supervisor must be provided"
      );
      return false;
    }

    // Supervisor email validation
    for (const supervisor of validSupervisors) {
      if (!REGEX.email.test(supervisor.email.trim())) {
        showCustomAlert(
          "Validation Error",
          `Invalid email format for supervisor: ${supervisor.email}`
        );
        return false;
      }
    }

    return true;
  };

  const handleRegister = () => {
    if (!validateForm()) {
      return;
    }

    const employeeId = generateEmployeeId();

    const validSupervisors = supervisors.filter(
      (supervisor) =>
        supervisor &&
        supervisor.id &&
        supervisor.email &&
        supervisor.id.trim() !== "" &&
        supervisor.email.trim() !== ""
    );

    const supervisorsToSend = validSupervisors.map((supervisor) => ({
      id: supervisor.id.trim(),
      email: supervisor.email.trim().toLowerCase(),
    }));

    const employeeData = {
      employeeId: employeeId,
      employeeName: name.trim(),
      email: email.trim().toLowerCase(),
      employeeMobileNumber: employeeMobileNumber.trim(),
      jobTitle: jobTitle.trim(),
      department: department.trim() || null,
      hireDate: hireDate.trim() || null,
      supervisors: supervisorsToSend,
    };

    console.log("Sending employee data:", employeeData);

    axios
      .post("https://sbg-backend.onrender.com/addEmployee", employeeData)
      .then((response) => {
        showCustomAlert(
          "Success",
          "Employee and supervisor accounts have been created successfully! Welcome emails have been sent with login credentials to:\n\n" +
          `• ${email} (Employee)\n` +
          supervisorsToSend.map(sup => `• ${sup.email} (Supervisor)`).join('\n'),
          false,
          () => {
            setName("");
            setEmail("");
            setJobTitle("");
            setDepartment("");
            setHireDate("");
            setEmployeeMobileNumber("");
            setSupervisors([{ id: "", email: "" }]);
            navigation.navigate("Employees");
          }
        );
      })
      .catch((error) => {
        console.error("Registration error:", error);

        let errorMessage = "An error occurred during registration";

        if (error.response?.data) {
          if (typeof error.response.data === "string") {
            errorMessage = error.response.data;
          } else if (error.response.data.message) {
            errorMessage = error.response.data.message;
          } else if (error.response.data.errors) {
            const errors = error.response.data.errors;
            errorMessage =
              "Validation errors:\n" +
              Object.entries(errors)
                .map(([field, error]) => {
                  const errorMsg =
                    typeof error === "string"
                      ? error
                      : error.message || JSON.stringify(error);
                  return `• ${field}: ${errorMsg}`;
                })
                .join("\n");
          }
        }

        showCustomAlert("Registration Failed", errorMessage);
      });
  };

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#000" : "#F0F0F0"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"} />
        <Text
          style={[
            styles.headerText,
            colorScheme === "dark" && styles.headerTextDark,
          ]}
        >
          Add New Employee
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
          />
        </TouchableOpacity>
      </View>
      <ScrollView style={styles.scrollView}>
        <View
          style={[
            styles.formContainer,
            isDesktop && styles.desktopFormContainer,
          ]}
        >
          <View style={isDesktop && styles.desktopRow}>
            <InputField
              label="Full Name"
              value={name}
              onChangeText={setName}
              placeholder="Enter full name"
              colorScheme={colorScheme}
              style={isDesktop && styles.desktopHalfWidth}
              fieldName="name"
              required
            />
            <InputField
              label="Email"
              value={email}
              onChangeText={setEmail}
              placeholder="Enter email"
              keyboardType="email-address"
              colorScheme={colorScheme}
              style={isDesktop && styles.desktopHalfWidth}
              fieldName="email"
              required
            />
          </View>
          <View style={isDesktop && styles.desktopRow}>
            <InputField
              label="Job Title"
              value={jobTitle}
              onChangeText={setJobTitle}
              placeholder="Enter job title"
              colorScheme={colorScheme}
              style={isDesktop && styles.desktopHalfWidth}
              fieldName="jobTitle"
              required
            />
            <InputField
              label="Department"
              value={department}
              onChangeText={setDepartment}
              placeholder="Enter department (optional)"
              colorScheme={colorScheme}
              style={isDesktop && styles.desktopHalfWidth}
              fieldName="department"
            />
          </View>
          <View style={isDesktop && styles.desktopRow}>
            <InputField
              label="Mobile Number"
              value={employeeMobileNumber}
              onChangeText={setEmployeeMobileNumber}
              placeholder="Enter 10-digit mobile number"
              keyboardType="phone-pad"
              colorScheme={colorScheme}
              style={isDesktop && styles.desktopHalfWidth}
              fieldName="employeeMobileNumber"
              required
            />
            <InputField
              label="Hire Date"
              value={hireDate}
              onChangeText={setHireDate}
              placeholder="YYYY-MM-DD (optional)"
              colorScheme={colorScheme}
              style={isDesktop && styles.desktopHalfWidth}
              fieldName="hireDate"
            />
          </View>
          <View style={styles.supervisorContainer}>
            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Supervisors
            </Text>
            {supervisors.map((supervisor, index) => (
              <View key={index} style={styles.supervisorInputRow}>
                <InputField
                  value={supervisor.id}
                  onChangeText={(text) =>
                    handleSupervisorChange("id", text, index)
                  }
                  placeholder="Enter supervisor ID"
                  colorScheme={colorScheme}
                  style={styles.supervisorInput}
                />
                <InputField
                  value={supervisor.email}
                  onChangeText={(text) =>
                    handleSupervisorChange("email", text, index)
                  }
                  placeholder="Enter supervisor email"
                  colorScheme={colorScheme}
                  style={styles.supervisorInput}
                />
                {index > 0 && (
                  <TouchableOpacity
                    onPress={() => handleRemoveSupervisor(index)}
                    style={styles.removeSupervisorButton}
                  >
                    <AntDesign
                      name="minuscircleo"
                      size={24}
                      color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
                    />
                  </TouchableOpacity>
                )}
              </View>
            ))}
            <TouchableOpacity
              onPress={handleAddSupervisor}
              style={styles.addSupervisorButton}
            >
              <AntDesign
                name="pluscircleo"
                size={24}
                color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
              />
              <Text
                style={[
                  styles.addSupervisorText,
                  colorScheme === "dark" && styles.addSupervisorTextDark,
                ]}
              >
                Add Another Supervisor
              </Text>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            onPress={handleRegister}
            style={[
              styles.addButton,
              colorScheme === "dark" && styles.addButtonDark,
            ]}
          >
            <Text style={styles.addButtonText}>Add Employee</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
      <CustomAlert
        visible={alertConfig.visible}
        title={alertConfig.title}
        message={alertConfig.message}
        onClose={handleCloseAlert}
        onConfirm={alertConfig.onConfirm}
        showCancel={alertConfig.showCancel}
      />
    </SafeAreaView>
  );
};

const InputField = ({
  label,
  value,
  onChangeText,
  placeholder,
  keyboardType,
  colorScheme,
  style,
  fieldName,
  required,
}) => (
  <View style={[styles.inputContainer, style]}>
    {label && (
      <Text style={[styles.label, colorScheme === "dark" && styles.labelDark]}>
        {label}
      </Text>
    )}
    <TextInput
      value={value}
      onChangeText={onChangeText}
      style={[styles.input, colorScheme === "dark" && styles.inputDark]}
      placeholder={placeholder}
      placeholderTextColor={colorScheme === "dark" ? "#666" : "#999"}
      keyboardType={keyboardType || "default"}
    />
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F9FA",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  headerTextDark: {
    color: "#FFFFFF",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  scrollView: {
    flex: 1,
  },
  formContainer: {
    padding: 16,
  },
  desktopFormContainer: {
    maxWidth: 800,
    alignSelf: "center",
    width: "100%",
  },
  desktopRow: {
    flexDirection: "row",
    gap: 20,
    marginBottom: 15,
  },
  desktopHalfWidth: {
    flex: 1,
  },
  inputContainer: {
    marginBottom: 16,
  },
  label: {
    fontSize: 16,
    fontWeight: "600",
    color: "#333",
    marginBottom: 8,
  },
  labelDark: {
    color: "#CCCCCC",
  },
  input: {
    backgroundColor: "white",
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    fontSize: 16,
  },
  inputDark: {
    backgroundColor: "#333",
    borderColor: "#555",
    color: "#FFFFFF",
  },
  addButton: {
    backgroundColor: "#007AFF",
    padding: 16,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 24,
  },
  addButtonDark: {
    backgroundColor: "#0A84FF",
  },
  addButtonText: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  supervisorContainer: {
    marginBottom: 16,
  },
  supervisorInputRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  supervisorInput: {
    flex: 1,
    marginRight: 8,
  },
  removeSupervisorButton: {
    padding: 8,
  },
  addSupervisorButton: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  addSupervisorText: {
    marginLeft: 8,
    fontSize: 16,
    color: "#007AFF",
  },
  addSupervisorTextDark: {
    color: "#FFFFFF",
  },
  supervisorsContainer: {
    marginTop: 20,
  },
  supervisorRow: {
    flexDirection: "row",
    gap: 10,
    marginBottom: 10,
    alignItems: "center",
  },
  submitButton: {
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 30,
  },
  submitButtonDark: {
    backgroundColor: "#0A84FF",
  },
  submitButtonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "600",
  },
});

export default Adddetails;
