import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  SafeAreaView,
  FlatList,
  Text,
  View,
  TextInput,
  StyleSheet,
  Button,
  Platform,
  Alert,
  Pressable,
  TouchableOpacity,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
  ScrollView,
  Image,
  Modal,
  Picker,
  DateTimePicker,
} from "react-native";
import { Ionicons, Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import moment from "moment-timezone";
import jsPDF from "jspdf";
import "jspdf-autotable";
import debounce from "lodash/debounce";
import axios from "axios";
import CustomLoading from "./CustomLoading";

let ExcelJS, saveAs;
if (Platform.OS === "web") {
  ExcelJS = require("exceljs");
  saveAs = require("file-saver").saveAs;
}

// BackButton Component
const BackButton = ({ color }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
      accessible={true}
      accessibilityLabel="Go Back"
    >
      <Ionicons name="arrow-back" size={24} color={color} />
    </TouchableOpacity>
  );
};

// ExpandableTimeDetails Component for Special Timesheets
const ExpandableTimeDetails = ({ day, dynamicStyles }) => {
  const [expanded, setExpanded] = useState(false);

  const specialTimes = useMemo(() => {
    return [
      {
        type: "Vacation",
        amount: day.hours.find((h) => h.type === "vacation")?.amount || 0,
      },
      {
        type: "Sick",
        amount: day.hours.find((h) => h.type === "sick")?.amount || 0,
      },
      {
        type: "Holiday",
        amount: day.hours.find((h) => h.type === "holiday")?.amount || 0,
      },
    ].filter((time) => time.amount > 0);
  }, [day.hours]);

  const totalSpecialTime = useMemo(() => {
    return specialTimes.reduce((sum, time) => sum + time.amount, 0);
  }, [specialTimes]);

  return (
    <TouchableOpacity
      onPress={() => setExpanded(!expanded)}
      style={[
        styles.expandableTimeDetails,
        dynamicStyles.expandableTimeDetails,
      ]}
      accessible={true}
      accessibilityLabel={
        expanded
          ? "Collapse special time details"
          : "Expand special time details"
      }
    >
      <Text
        style={[styles.expandableTimeTotal, dynamicStyles.expandableTimeTotal]}
      >
        {totalSpecialTime > 0 ? totalSpecialTime.toFixed(1) : "-"}
      </Text>
      {expanded && totalSpecialTime > 0 && (
        <View style={{ marginTop: 5 }}>
          {specialTimes.map((time, index) => (
            <Text
              key={index}
              style={[
                styles.expandableTimeBreakdown,
                dynamicStyles.expandableTimeBreakdown,
              ]}
            >
              {time.type}: {time.amount.toFixed(1)}
            </Text>
          ))}
        </View>
      )}
    </TouchableOpacity>
  );
};

// Add this component before AllAttendanceSummary
const FilterSection = ({
  filterStatus,
  setFilterStatus,
  filterDateRange,
  setFilterDateRange,
  customStartDate,
  setCustomStartDate,
  customEndDate,
  setCustomEndDate,
  dynamicStyles = {},
}) => {
  const textColor = dynamicStyles?.headerText?.color || "#000000";
  const backgroundColor =
    dynamicStyles?.container?.backgroundColor === "#121212"
      ? "#333"
      : "#f5f5f5";

  const handleDateRangeChange = (value) => {
    setFilterDateRange(value);
    if (value !== "custom") {
      setCustomStartDate(null);
      setCustomEndDate(null);
    }
  };

  return (
    <View style={styles.filterContainer}>
      <View style={styles.filterDropdown}>
        <Text style={[styles.filterLabel, { color: textColor }]}>Status:</Text>
        <Picker
          selectedValue={filterStatus}
          onValueChange={setFilterStatus}
          style={[styles.picker, { backgroundColor }]}
        >
          <Picker.Item label="All Status" value="all" />
          <Picker.Item label="Approved" value="approved" />
          <Picker.Item label="Not Approved" value="notapproved" />
        </Picker>
      </View>

      <View style={styles.filterDropdown}>
        <Text style={[styles.filterLabel, { color: textColor }]}>
          Date Range:
        </Text>
        <Picker
          selectedValue={filterDateRange}
          onValueChange={handleDateRangeChange}
          style={[styles.picker, { backgroundColor }]}
        >
          <Picker.Item label="Current Period" value="current" />
          <Picker.Item label="Past Week" value="pastWeek" />
          <Picker.Item label="Past 2 Weeks" value="past2Weeks" />
          <Picker.Item label="Past Month" value="pastMonth" />
          <Picker.Item label="Past 3 Months" value="past3Months" />
          <Picker.Item label="Past 6 Months" value="past6Months" />
          <Picker.Item label="Year to Date" value="yearToDate" />
          <Picker.Item label="Custom Range" value="custom" />
        </Picker>
      </View>

      {filterDateRange === "custom" && (
        <View style={styles.customDateContainer}>
          <input
            type="date"
            value={customStartDate || ""}
            onChange={(e) => setCustomStartDate(e.target.value)}
            style={styles.dateInput}
          />
          <Text style={[styles.dateSeperator, { color: textColor }]}>to</Text>
          <input
            type="date"
            value={customEndDate || ""}
            onChange={(e) => setCustomEndDate(e.target.value)}
            style={styles.dateInput}
          />
        </View>
      )}
    </View>
  );
};

// Add this component definition before the AllAttendanceSummary component

const QuickActionsMenu = ({
  visible,
  onClose,
  onExportExcel,
  dynamicStyles,
}) => {
  if (!visible) return null;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={[styles.modalContainer, dynamicStyles?.modalContainer]}>
        <View style={[styles.modalContent, dynamicStyles?.modalContent]}>
          <Text style={[styles.modalTitle, dynamicStyles?.modalTitle]}>
            Quick Actions
          </Text>

          <TouchableOpacity
            style={[styles.modalButton, dynamicStyles?.modalButton]}
            onPress={() => {
              onExportExcel();
              onClose();
            }}
          >
            <Text
              style={[styles.modalButtonText, dynamicStyles?.modalButtonText]}
            >
              Export to Excel
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.modalCloseButton, dynamicStyles?.modalCloseButton]}
            onPress={onClose}
          >
            <Text
              style={[styles.modalButtonText, dynamicStyles?.modalButtonText]}
            >
              Close
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

// Add these styles to your StyleSheet
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 1,
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  content: {
    flex: 1,
    padding: 10,
  },
  desktopContent: {
    paddingHorizontal: 20,
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  searchBar: {
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
    borderWidth: 1,
  },
  attendanceContainer: {
    marginVertical: 10,
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 5,
  },
  employeeInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  employeeAvatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: "#007AFF",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  avatarText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "bold",
  },
  employeeName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#333",
  },
  employeeDetail: {
    fontSize: 14,
    color: "#555",
  },
  approvedDetail: {
    fontSize: 14,
    color: "green",
  },
  tableContainer: {
    marginVertical: 10,
  },
  desktopTableContainer: {
    // Optional: Add desktop-specific styles if needed
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f0f0f0",
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  desktopTableHeader: {
    // Optional: Add desktop-specific styles if needed
  },
  headerCell: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 14,
    color: "#333",
  },
  desktopHeaderCell: {
    // Optional: Add desktop-specific styles if needed
  },
  serviceCell: {
    flex: 2,
  },
  desktopServiceCell: {
    // Optional: Add desktop-specific styles if needed
  },
  tableRow: {
    flexDirection: "row",
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
  },
  desktopTableRow: {
    // Optional: Add desktop-specific styles if needed
  },
  tableCell: {
    flex: 1,
    textAlign: "center",
    fontSize: 14,
    color: "#555",
  },
  desktopTableCell: {
    // Optional: Add desktop-specific styles if needed
  },
  notesSection: {
    marginTop: 10,
  },
  notesHeader: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 5,
  },
  noteItem: {
    marginBottom: 5,
    padding: 5,
    backgroundColor: "#f9f9f9",
    borderRadius: 5,
  },
  noteContent: {
    fontSize: 14,
    color: "#333",
  },
  noteDetails: {
    fontSize: 12,
    color: "#777",
  },
  noNotesText: {
    fontSize: 14,
    color: "#777",
  },
  addNoteSection: {
    marginTop: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  noteInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    padding: 8,
    marginRight: 10,
    color: "#000",
  },
  addNoteButton: {
    backgroundColor: "#007AFF",
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
  },
  addNoteButtonText: {
    color: "#fff",
    fontWeight: "600",
  },
  approveButton: {
    backgroundColor: "green",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignSelf: "flex-end",
    marginTop: 10,
  },
  approveButtonText: {
    color: "#fff",
    fontWeight: "600",
  },
  exportButton: {
    backgroundColor: "#FFA500",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignSelf: "flex-end",
    marginTop: 10,
    marginLeft: 10,
  },
  exportButtonText: {
    color: "#fff",
    fontWeight: "600",
  },
  navigationContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  navButton: {
    padding: 10,
  },
  navButtonDisabled: {
    opacity: 0.5,
  },
  periodIndicator: {
    marginHorizontal: 20,
    fontSize: 16,
    fontWeight: "500",
    color: "#333",
  },
  paginationContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    marginTop: 10,
  },
  pageNumbers: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 10,
  },
  pageNumber: {
    padding: 8,
    marginHorizontal: 4,
    borderRadius: 4,
    minWidth: 36,
    alignItems: "center",
  },
  currentPage: {
    backgroundColor: "#007AFF",
  },
  currentPageText: {
    color: "#FFFFFF",
  },
  paginationButton: {
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#f0f0f0",
  },
  paginationButtonDisabled: {
    backgroundColor: "#cccccc",
  },
  paginationButtonText: {
    color: "#fff",
    fontWeight: "600",
  },
  paginationButtonTextDisabled: {
    color: "#666666",
  },
  paginationText: {
    fontSize: 16,
    fontWeight: "500",
    color: "#333",
  },
  textPrimary: {
    color: "#000000",
  },
  textSecondary: {
    color: "#555555",
  },
  buttonText: {
    color: "#ffffff",
  },
  toggleContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
  },
  toggleButton: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: "#007AFF",
  },
  toggleButtonActive: {
    backgroundColor: "#007AFF",
  },
  toggleButtonText: {
    color: "#007AFF",
  },
  toggleButtonTextActive: {
    color: "#FFFFFF",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 10,
    marginTop: 10,
  },
  resetButton: {
    backgroundColor: "#dc3545",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    marginHorizontal: 5,
    minWidth: 120,
    alignItems: "center",
  },
  resetButtonDisabled: {
    opacity: 0.5,
    backgroundColor: "#999",
  },
  resetButtonText: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
  },
  filterContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    flexWrap: "wrap",
    gap: 10,
    padding: 10,
  },
  filterDropdown: {
    flex: 1,
    minWidth: 150,
    marginHorizontal: 5,
  },
  filterLabel: {
    marginBottom: 5,
    fontWeight: "500",
  },
  picker: {
    borderRadius: 5,
    padding: 5,
    height: 40,
    borderWidth: 1,
    borderColor: "#ccc",
  },
  periodNavigation: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    marginBottom: 10,
  },
  navigationButton: {
    padding: 8,
    borderRadius: 5,
    backgroundColor: "#f0f0f0",
  },
  disabledButton: {
    opacity: 0.5,
  },
  periodText: {
    fontSize: 16,
    fontWeight: "500",
  },
  searchContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  searchInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    padding: 8,
    marginRight: 10,
    color: "#000",
  },
  toggleButton: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: "#007AFF",
  },
  toggleButtonText: {
    color: "#007AFF",
  },
  toggleButtonTextActive: {
    color: "#FFFFFF",
  },
  listContainer: {
    paddingHorizontal: 10,
  },
  // Modal Styles
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    width: "80%",
    maxWidth: 400,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 20,
  },
  modalButton: {
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 5,
    width: "100%",
    marginBottom: 10,
    alignItems: "center",
  },
  modalCloseButton: {
    backgroundColor: "#FF3B30",
    padding: 15,
    borderRadius: 5,
    width: "100%",
    alignItems: "center",
  },
  modalButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
  customDateContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    gap: 10,
  },
  dateInput: {
    flex: 1,
    padding: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ccc",
    minWidth: 130,
  },
  dateSeperator: {
    paddingHorizontal: 10,
  },
});

// Add these styles to your createDynamicStyles function
const createDynamicStyles = (colorScheme) => ({
  // Container Background
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#f9f9f9",
  },

  // Header Styles
  header: {
    backgroundColor: colorScheme === "dark" ? "#1E1E1E" : "#ffffff",
    borderBottomColor: colorScheme === "dark" ? "#333333" : "#E0E0E0",
  },
  headerText: {
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  headerIconColor: {
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },

  // Search Bar Styles
  searchBar: {
    backgroundColor: colorScheme === "dark" ? "#333333" : "#ffffff",
    borderColor: colorScheme === "dark" ? "#555555" : "#cccccc",
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  placeholderColor: colorScheme === "dark" ? "#888888" : "#999999",

  // Attendance Container Styles
  attendanceContainer: {
    backgroundColor: colorScheme === "dark" ? "#1E1E1E" : "#ffffff",
    shadowColor: colorScheme === "dark" ? "#000000" : "#000000",
    shadowOpacity: colorScheme === "dark" ? 0.5 : 0.1,
    shadowRadius: 4,
    elevation: 5,
    borderWidth: colorScheme === "dark" ? 1 : 1,
    borderColor: colorScheme === "dark" ? "#333333" : "#ccc",
  },

  // Employee Avatar Styles
  employeeAvatar: {
    backgroundColor: colorScheme === "dark" ? "#6A8ED4" : "#4b6cb7",
  },

  // Employee Name and Details
  employeeName: {
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  employeeDetail: {
    color: colorScheme === "dark" ? "#cccccc" : "#555555",
  },
  approvedDetail: {
    color: colorScheme === "dark" ? "#81C784" : "#4CAF50",
  },

  // Table Styles
  tableContainer: {
    backgroundColor: colorScheme === "dark" ? "#2C2C2C" : "#ffffff",
  },
  tableHeader: {
    backgroundColor: colorScheme === "dark" ? "#333333" : "#f2f2f2",
    borderBottomColor: colorScheme === "dark" ? "#555555" : "#cccccc",
  },
  headerCell: {
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  tableRow: {
    borderBottomColor: colorScheme === "dark" ? "#444444" : "#e0e0e0",
  },
  tableCell: {
    color: colorScheme === "dark" ? "#dddddd" : "#333333",
  },

  // Notes Section Styles
  notesSection: {
    backgroundColor: colorScheme === "dark" ? "#2C2C2C" : "#f0f0f0",
  },
  notesHeader: {
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  noteItem: {
    backgroundColor: colorScheme === "dark" ? "#3A3A3A" : "#ffffff",
  },
  noteContent: {
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  noteDetails: {
    color: colorScheme === "dark" ? "#aaaaaa" : "#777777",
  },
  noNotesText: {
    color: colorScheme === "dark" ? "#aaaaaa" : "#777777",
  },

  // Add Note Section Styles
  noteInput: {
    borderColor: colorScheme === "dark" ? "#555555" : "#dddddd",
    backgroundColor: colorScheme === "dark" ? "#333333" : "#ffffff",
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  addNoteButton: {
    backgroundColor: colorScheme === "dark" ? "#6A8ED4" : "#4b6cb7",
  },
  approveButton: {
    backgroundColor: colorScheme === "dark" ? "#FF5722" : "#FF9800",
  },
  exportButton: {
    backgroundColor: colorScheme === "dark" ? "#2196F3" : "#3F51B5",
  },

  // Pagination Styles
  paginationText: {
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  paginationButton: {
    backgroundColor: colorScheme === "dark" ? "#333333" : "#f0f0f0",
  },
  paginationButtonText: {
    color: colorScheme === "dark" ? "#ffffff" : "#000000",
  },
  paginationButtonTextDisabled: {
    color: colorScheme === "dark" ? "#666666" : "#888888",
  },

  // Loading Container Styles
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === "dark" ? "#1a2e22" : "#e6f0e8",
  },
  toggleButton: {
    borderColor: colorScheme === "dark" ? "#6A8ED4" : "#007AFF",
  },
  toggleButtonText: {
    color: colorScheme === "dark" ? "#6A8ED4" : "#007AFF",
  },

  // Add placeholderText styles
  placeholderText: {
    color: colorScheme === "dark" ? "#888888" : "#999999",
  },

  // Modal Styles
  modalContainer: {
    backgroundColor:
      colorScheme === "dark" ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: colorScheme === "dark" ? "#1E1E1E" : "white",
  },
  modalTitle: {
    color: colorScheme === "dark" ? "#FFFFFF" : "#000000",
  },
  modalButton: {
    backgroundColor: colorScheme === "dark" ? "#0A84FF" : "#007AFF",
  },
  modalCloseButton: {
    backgroundColor: colorScheme === "dark" ? "#FF453A" : "#FF3B30",
  },
  modalButtonText: {
    color: "#FFFFFF",
  },
});

// Update the FilterSection usage in AllAttendanceSummary
const AllAttendanceSummary = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(true);
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;
  const [showSubmitted, setShowSubmitted] = useState(true);

  // Add this line for QuickActionsMenu visibility state
  const [showQuickActions, setShowQuickActions] = useState(false);

  // Add these state variables at the top with other state declarations
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterDateRange, setFilterDateRange] = useState("current");

  // Add these state variables
  const [payPeriods, setPayPeriods] = useState([]);
  const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);

  // Add these pagination-related state variables
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // You can adjust this number as needed
  const [totalPages, setTotalPages] = useState(1);

  // Add these new state variables in AllAttendanceSummary
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [datePickerType, setDatePickerType] = useState("start"); // 'start' or 'end'

  // Add this near the top of the component, after the state declarations
  const defaultDynamicStyles = {
    placeholderText: { color: "#999999" },
    container: { backgroundColor: "#f9f9f9" },
    headerText: { color: "#000000" },
    headerIconColor: { color: "#000000" },
  };

  // Update the dynamicStyles useMemo to include a fallback
  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme) || defaultDynamicStyles,
    [colorScheme]
  );

  // Add this function to safely render the FilterSection
  const renderFilterSection = () => {
    if (!dynamicStyles) return null;

    return (
      <FilterSection
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterDateRange={filterDateRange}
        setFilterDateRange={setFilterDateRange}
        customStartDate={customStartDate}
        setCustomStartDate={setCustomStartDate}
        customEndDate={customEndDate}
        setCustomEndDate={setCustomEndDate}
        dynamicStyles={dynamicStyles}
      />
    );
  };

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  // Helper function to safely format numbers
  const formatNumber = (num) => {
    return typeof num === "number" ? num.toFixed(2) : "0.00";
  };

  const calculateTotalTime = useCallback((startTime, endTime) => {
    if (!startTime || !endTime) return 0;

    // Parse times using moment, accepting both formats
    const start = moment(startTime, ["h:mm A", "HH:mm"], true);
    const end = moment(endTime, ["h:mm A", "HH:mm"], true);

    // Validate that times were parsed correctly
    if (!start.isValid() || !end.isValid()) {
      console.error("Invalid time format:", { startTime, endTime });
      return 0;
    }

    // Set both times to the same day for proper calculation
    const baseDate = moment().startOf('day');
    start.year(baseDate.year()).month(baseDate.month()).date(baseDate.date());
    end.year(baseDate.year()).month(baseDate.month()).date(baseDate.date());

    // Handle overnight shifts
    if (end.isBefore(start)) {
      end.add(1, "day");
    }

    // Calculate duration in hours
    const duration = moment.duration(end.diff(start));
    return duration.asHours();
  }, []);

  const calculateDayTotal = useCallback(
    (day) => {
      console.log('Calculating day total for:', day); // Debug log
      let total = 0;

      // For unsubmitted entries, calculate from start/end times
      if (day.startTime && day.endTime) {
        // Calculate base work hours from start and end time
        total = calculateTotalTime(day.startTime, day.endTime);
        console.log('Base total from start/end time:', total); // Debug log

        // Subtract lunch duration if present
        if (day.lunchStartTime && day.lunchEndTime) {
          const lunchDuration = calculateTotalTime(
            day.lunchStartTime,
            day.lunchEndTime
          );
          total = roundToTwoDecimals(total - lunchDuration);
          console.log('Total after lunch subtraction:', total); // Debug log
        }
      }

      // Add stand-by hours if present
      const standByHours = parseFloat(day.standByHours) || 0;
      if (standByHours > 0) {
        total = roundToTwoDecimals(total + standByHours);
        console.log('Total after adding standby hours:', total); // Debug log
      }

      total = roundToTwoDecimals(total);
      console.log('Final total:', total); // Debug log
      return Math.max(0, total); // Ensure total is not negative
    },
    [calculateTotalTime]
  );

  // Utility function for consistent rounding
  const roundToTwoDecimals = (number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  };

  // Update calculateWeeklyTotalSpecial to use the new calculateDayTotal function
  const calculateWeeklyTotalSpecial = useCallback(
    (days) => {
      return days.reduce((total, day) => {
        const dayTotal = calculateDayTotal(day);
        return total + dayTotal;
      }, 0);
    },
    [calculateDayTotal]
  );

  // Update calculateWeeklyTotal for regular timesheets if needed
  const calculateWeeklyTotal = useCallback(
    (days) => {
      return days.reduce((total, day) => {
        const dayTotal = calculateDayTotal(day);
        return total + dayTotal;
      }, 0);
    },
    [calculateDayTotal]
  );

  // Fetch Attendance Data Function
  const fetchAttendanceData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://sbg-backend.onrender.com/attendances`
      );

      // Helper function to get the standardized week start
      const getStandardWeekStart = (date) => {
        return moment
          .tz(date, "America/Phoenix")
          .startOf("week")
          .format("YYYY-MM-DD");
      };

      // Sort and group the data by standardized week start
      let data = response.data
        .map((record) => ({
          ...record,
          standardWeekStart: getStandardWeekStart(record.weekStart),
          weeklyTotalHours: record.isSpecialFormat
            ? calculateWeeklyTotalSpecial(record.days)
            : calculateWeeklyTotal(record.days),
        }))
        .sort((a, b) => {
          // First sort by standardized week start
          const weekCompare = moment(b.standardWeekStart).diff(
            moment(a.standardWeekStart)
          );
          if (weekCompare !== 0) return weekCompare;

          // If same week, sort by submission date
          return moment(b.date).diff(moment(a.date));
        });

      console.log("Fetched and Sorted Attendance Data:", data);
      setAttendanceData(data);

      // Group by standardized week start for pay periods
      const uniquePayPeriods = [
        ...new Set(data.map((item) => item.standardWeekStart)),
      ].sort((a, b) => moment(b).diff(moment(a))); // Sort in descending order

      setPayPeriods(uniquePayPeriods);
      setCurrentPeriodIndex(0); // Initialize to the latest pay period
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setAttendanceData([]);
    } finally {
      setLoading(false);
    }
  }, [calculateWeeklyTotal, calculateWeeklyTotalSpecial]);

  useEffect(() => {
    fetchAttendanceData();
  }, [fetchAttendanceData]);

  const approveAttendance = async (attendance) => {
    try {
      const response = await axios.put(
        `https://sbg-backend.onrender.com/approveAttendance/${attendance._id}`,
        { approvedBy: "Current Supervisor" } // Replace with actual supervisor name if available
      );
      console.log(response.data);
      Alert.alert("Success", "Attendance approved successfully.");
      fetchAttendanceData();
    } catch (error) {
      console.error("Error approving attendance:", error);
      Alert.alert("Error", "Failed to approve attendance.");
    }
  };

  const addNote = async (attendanceId) => {
    if (!newNote.trim()) {
      Alert.alert("Error", "Please enter a note");
      return;
    }
    try {
      const response = await axios.post(
        `https://sbg-backend.onrender.com/attendances/${attendanceId}/notes`,
        {
          content: newNote,
          supervisorName: "Current Supervisor", // Replace with actual supervisor name if available
        }
      );
      console.log(response.data);
      setNewNote("");
      Alert.alert("Success", "Note added successfully.");
      fetchAttendanceData();
    } catch (error) {
      console.error("Error adding note:", error);
      Alert.alert("Error", "Failed to add note");
    }
  };

  const generateExcel = async () => {
    const dataToExport = attendanceData; // You can modify this based on search or other criteria

    if (Platform.OS === "web") {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Attendance");

      // Define columns
      worksheet.columns = [
        { header: "Employee Name", key: "employeeName", width: 20 },
        { header: "Employee ID", key: "employeeId", width: 15 },
        { header: "Job Title", key: "jobTitle", width: 20 },
        { header: "Client", key: "department", width: 20 },
        { header: "Week Start", key: "weekStart", width: 15 },
        { header: "Weekly Total Hours", key: "weeklyTotalHours", width: 20 },
        { header: "Submitted On", key: "submittedOn", width: 20 },
        { header: "Approved By", key: "approvedBy", width: 20 },
        { header: "Approved Date", key: "approvedDate", width: 20 },
        { header: "Day of Week", key: "dayOfWeek", width: 15 },
        { header: "Date", key: "date", width: 15 },
        { header: "Start Time", key: "startTime", width: 15 },
        { header: "End Time", key: "endTime", width: 15 },
        { header: "Lunch Start", key: "lunchStartTime", width: 15 },
        { header: "Lunch End", key: "lunchEndTime", width: 15 },
        { header: "Work Hours", key: "workHours", width: 15 },
        { header: "Vacation Hours", key: "vacationHours", width: 15 },
        { header: "Sick Hours", key: "sickHours", width: 15 },
        { header: "Holiday Hours", key: "holidayHours", width: 15 },
        { header: "Total Hours", key: "totalHours", width: 15 },
        { header: "Notes", key: "notes", width: 30 },
      ];

      dataToExport.forEach((item) => {
        if (item.isSpecialFormat) {
          // Handle Special Format Timesheets
          item.days.forEach((day) => {
            if (Array.isArray(day.services) && day.services.length > 0) {
              day.services.forEach((service) => {
                worksheet.addRow({
                  employeeName: item.employeeName,
                  employeeId: item.employeeId,
                  jobTitle: item.jobTitle,
                  department: item.department,
                  weekStart: moment
                    .tz(item.weekStart, "America/Phoenix")
                    .format("YYYY-MM-DD"),
                  weeklyTotalHours: item.weeklyTotalHours,
                  submittedOn: item.isSubmitted
                    ? formatSubmissionDate(getSubmissionDate(item))
                    : "",
                  approvedBy: item.approvedBy,
                  approvedDate: item.approvedDate
                    ? moment
                        .tz(item.approvedDate, "America/Phoenix")
                        .format("YYYY-MM-DD")
                    : "",
                  dayOfWeek: moment
                    .tz(day.date, "America/Phoenix")
                    .format("dddd"),
                  date: moment
                    .tz(day.date, "America/Phoenix")
                    .format("YYYY-MM-DD"),
                  startTime: service.startTime || "-",
                  endTime: service.endTime || "-",
                  lunchStartTime: "-",
                  lunchEndTime: "-",
                  workHours: "-",
                  vacationHours: "-",
                  sickHours: "-",
                  holidayHours: "-",
                  totalHours: formatNumber(service.totalTime),
                  notes: item.notes
                    ? item.notes
                        .map(
                          (note) => `${note.content} (${note.supervisorName})`
                        )
                        .join("; ")
                    : "",
                });
              });
            } else {
              // No services for this day
              worksheet.addRow({
                employeeName: item.employeeName,
                employeeId: item.employeeId,
                jobTitle: item.jobTitle,
                department: item.department,
                weekStart: moment
                  .tz(item.weekStart, "America/Phoenix")
                  .format("YYYY-MM-DD"),
                weeklyTotalHours: item.weeklyTotalHours,
                submittedOn: item.isSubmitted
                  ? formatSubmissionDate(getSubmissionDate(item))
                  : "",
                approvedBy: item.approvedBy,
                approvedDate: item.approvedDate
                  ? moment
                      .tz(item.approvedDate, "America/Phoenix")
                      .format("YYYY-MM-DD")
                  : "",
                dayOfWeek: moment
                  .tz(day.date, "America/Phoenix")
                  .format("dddd"),
                date: moment
                  .tz(day.date, "America/Phoenix")
                  .format("YYYY-MM-DD"),
                startTime: "-",
                endTime: "-",
                lunchStartTime: "-",
                lunchEndTime: "-",
                workHours: "-",
                vacationHours: "-",
                sickHours: "-",
                holidayHours: "-",
                totalHours: "0.00",
                notes: item.notes
                  ? item.notes
                      .map((note) => `${note.content} (${note.supervisorName})`)
                      .join("; ")
                  : "",
              });
            }

            // Add standByHours row
            if (Array.isArray(day.services) && day.services.length > 0) {
              worksheet.addRow({
                employeeName: item.employeeName,
                employeeId: item.employeeId,
                jobTitle: item.jobTitle,
                department: item.department,
                weekStart: moment
                  .tz(item.weekStart, "America/Phoenix")
                  .format("YYYY-MM-DD"),
                weeklyTotalHours: item.weeklyTotalHours,
                submittedOn: item.isSubmitted
                  ? formatSubmissionDate(getSubmissionDate(item))
                  : "",
                approvedBy: item.approvedBy,
                approvedDate: item.approvedDate
                  ? moment
                      .tz(item.approvedDate, "America/Phoenix")
                      .format("YYYY-MM-DD")
                  : "",
                dayOfWeek: "-",
                date: "-",
                startTime: "-",
                endTime: "-",
                lunchStartTime: "-",
                lunchEndTime: "-",
                workHours: "-",
                vacationHours: "-",
                sickHours: "-",
                holidayHours: "-",
                totalHours: formatNumber(day.standByHours),
                notes: item.notes
                  ? item.notes
                      .map((note) => `${note.content} (${note.supervisorName})`)
                      .join("; ")
                  : "",
              });
            }
          });
        } else {
          // Handle Regular Timesheets
          const notes = item.notes
            ? item.notes
                .map((note) => `${note.content} (${note.supervisorName})`)
                .join("; ")
            : "";
          if (item.days.length === 0) {
            worksheet.addRow({
              employeeName: item.employeeName,
              employeeId: item.employeeId,
              jobTitle: item.jobTitle,
              department: item.department,
              weekStart: moment
                .tz(item.weekStart, "America/Phoenix")
                .format("YYYY-MM-DD"),
              weeklyTotalHours: item.weeklyTotalHours,
              submittedOn: item.isSubmitted
                ? formatSubmissionDate(getSubmissionDate(item))
                : "",
              approvedBy: item.approvedBy,
              approvedDate: item.approvedDate
                ? moment
                    .tz(item.approvedDate, "America/Phoenix")
                    .format("YYYY-MM-DD")
                : "",
              dayOfWeek: "",
              date: "",
              startTime: "",
              endTime: "",
              lunchStartTime: "",
              lunchEndTime: "",
              workHours: "",
              vacationHours: "",
              sickHours: "",
              holidayHours: "",
              totalHours: "",
              notes: notes,
            });
          } else {
            item.days.forEach((day) => {
              const workHours =
                day.hours.find((h) => h.type === "work")?.amount || 0;
              const vacationHours =
                day.hours.find((h) => h.type === "vacation")?.amount || 0;
              const sickHours =
                day.hours.find((h) => h.type === "sick")?.amount || 0;
              const holidayHours =
                day.hours.find((h) => h.type === "holiday")?.amount || 0;
              worksheet.addRow({
                employeeName: item.employeeName,
                employeeId: item.employeeId,
                jobTitle: item.jobTitle,
                department: item.department,
                weekStart: moment
                  .tz(item.weekStart, "America/Phoenix")
                  .format("YYYY-MM-DD"),
                weeklyTotalHours: item.weeklyTotalHours,
                submittedOn: item.isSubmitted
                  ? formatSubmissionDate(getSubmissionDate(item))
                  : "",
                approvedBy: item.approvedBy,
                approvedDate: item.approvedDate
                  ? moment
                      .tz(item.approvedDate, "America/Phoenix")
                      .format("YYYY-MM-DD")
                  : "",
                dayOfWeek: day.dayOfWeek,
                date: moment
                  .tz(day.date, "America/Phoenix")
                  .format("YYYY-MM-DD"),
                startTime: day.startTime || "-",
                endTime: day.endTime || "-",
                lunchStartTime: day.lunchStartTime || "-",
                lunchEndTime: day.lunchEndTime || "-",
                workHours: formatNumber(workHours),
                vacationHours: formatNumber(vacationHours),
                sickHours: formatNumber(sickHours),
                holidayHours: formatNumber(holidayHours),
                totalHours: formatNumber(day.totalWorkHours),
                notes: notes,
              });
            });
          }
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "AttendanceSummary.xlsx");
    } else {
      Alert.alert("Export to Excel is not supported on mobile.");
    }
  };

  // Add this helper function at the top with other functions
  const formatSubmissionDate = (item) => {
    if (!item.isSubmitted) return null;

    // Use createdAt as submission timestamp
    const timestamp = item.createdAt;
    if (!timestamp) return null;

    return moment
      .tz(timestamp, "America/Phoenix")
      .format("MMM D, YYYY, hh:mm A z");
  };

  const generatePDF = (item) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 15; // Increased margin to 15
  
    // Helper function to add wrapped text
    const addWrappedText = (text, x, y, maxWidth, lineHeight) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      doc.text(lines, x, y);
      return y + lines.length * lineHeight;
    };
  
    // Helper function to calculate special times
    const calculateSpecialTimes = (hours) => {
      const specialTimes = [
        {
          type: "Vacation",
          amount: hours.find((h) => h.type === "vacation")?.amount || 0,
        },
        {
          type: "Sick",
          amount: hours.find((h) => h.type === "sick")?.amount || 0,
        },
        {
          type: "Holiday",
          amount: hours.find((h) => h.type === "holiday")?.amount || 0,
        },
      ].filter((time) => time.amount > 0);
  
      const totalSpecialTime = specialTimes.reduce(
        (sum, time) => sum + time.amount,
        0
      );
  
      return { specialTimes, totalSpecialTime };
    };
  
    // Title
    doc.setFontSize(24);
    doc.setTextColor(255, 102, 0); // Orange color
    doc.text("TIME SHEET", pageWidth / 2, margin, { align: "center" });
  
    // Reset text color to black
    doc.setTextColor(0, 0, 0);
  
    // Company details
    doc.setFontSize(10);
    let yPos = margin + 25;
    yPos = addWrappedText(
      "Scott Business Group",
      margin,
      yPos,
      pageWidth - 2 * margin,
      5
    );
    yPos = addWrappedText(
      "4600 East Washington Street, Suite 300, Phoenix, Arizona 85034",
      margin,
      yPos,
      pageWidth - 2 * margin,
      5
    );
    yPos = addWrappedText(
      "T: 602.604.9928 | F: 623.321.1327 | E: timesheets@scottbiz.net",
      margin,
      yPos,
      pageWidth - 2 * margin,
      5
    );
  
    // Employee details
    doc.setFontSize(12);
    yPos += 10;
    doc.text(`Employee Name: ${item.employeeName}`, margin, yPos);
    doc.text(`Employee ID: ${item.employeeId}`, pageWidth / 2, yPos);
    yPos += 7;
    doc.text(`Job Title: ${item.jobTitle || "N/A"}`, margin, yPos);
    doc.text(`Department: ${item.department || "N/A"}`, pageWidth / 2, yPos);
    yPos += 7;
  
    // Only show supervisor if there is one
    const supervisorName = item.supervisorName || item.approvedBy;
    if (supervisorName) {
      doc.text(`Supervisor: ${supervisorName}`, margin, yPos);
    } else {
      doc.text("Supervisor:", margin, yPos);
    }
    yPos += 10;
  
    // Check if there's enough space for the table
    if (yPos + 100 > pageHeight - margin) { // Adjust 100 based on table height
      doc.addPage();
      yPos = margin;
    }
  
    // Timesheet table
    if (item.isSpecialFormat) {
      doc.autoTable({
        startY: yPos,
        head: [
          [
            "DAY",
            "DATE",
            "SERVICE",
            "START TIME",
            "END TIME",
            "LUNCH",
            "TOTAL TIME",
            "STAND-BY HOURS",
            "DAY TOTAL",
          ],
        ],
        body: item.days.flatMap((day, dayIndex) => {
          if (day.services && day.services.length > 0) {
            return day.services.map((service, serviceIndex) => [
              serviceIndex === 0 ? moment(day.date).format("ddd") : "",
              serviceIndex === 0 ? moment(day.date).format("MM/DD/YYYY") : "",
              service.service || "-",
              service.startTime || "-",
              service.endTime || "-",
              day.lunchStartTime && day.lunchEndTime
                ? `${day.lunchStartTime} - ${day.lunchEndTime}`
                : "-",
              formatNumber(service.totalTime),
              serviceIndex === 0 ? formatNumber(day.standByHours) : "",
              serviceIndex === 0 ? formatNumber(calculateDayTotal(day)) : "",
            ]);
          } else {
            return [
              [
                moment(day.date).format("ddd"),
                moment(day.date).format("MM/DD/YYYY"),
                "-",
                "-",
                "-",
                "-",
                "0.00",
                formatNumber(day.standByHours),
                formatNumber(calculateDayTotal(day)),
              ],
            ];
          }
        }),
        showFoot: "lastPage",
        foot: [
          [
            {
              content: "Weekly Totals",
              colSpan: 6,
              styles: { halign: "right", fontStyle: "bold" },
            },
            formatNumber(
              item.days.reduce((sum, day) => {
                const serviceTotal =
                  day.services?.reduce(
                    (serviceSum, service) =>
                      serviceSum + (parseFloat(service.totalTime) || 0),
                    0
                  ) || 0;
                return sum + serviceTotal;
              }, 0)
            ),
            formatNumber(
              item.days.reduce(
                (sum, day) => sum + (parseFloat(day.standByHours) || 0),
                0
              )
            ),
            formatNumber(item.weeklyTotalHours),
          ],
        ],
        theme: "grid",
        headStyles: {
          fillColor: [255, 102, 0],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
        bodyStyles: { textColor: [0, 0, 0] },
        alternateRowStyles: { fillColor: [245, 245, 245] },
        footStyles: {
          fillColor: [220, 220, 220],
          textColor: [0, 0, 0],
          fontStyle: "bold",
        },
        styles: { fontSize: 8, cellPadding: 2 },
        columnStyles: {
          0: { cellWidth: 15 },
          1: { cellWidth: 20 },
          2: { cellWidth: 25 },
          3: { cellWidth: 15 },
          4: { cellWidth: 15 },
          5: { cellWidth: 20 },
          6: { cellWidth: 15 },
          7: { cellWidth: 15 },
          8: { cellWidth: 15 },
        },
      });
      yPos = doc.lastAutoTable.finalY;
    } else {
      // Regular Timesheet
      const hasSpecialTimes = item.days.some((day) => {
        const { totalSpecialTime } = calculateSpecialTimes(day.hours);
        return totalSpecialTime > 0;
      });
  
      const tableHeaders = [
        "DAY",
        "DATE",
        "START",
        "END",
        "LUNCH",
        "TOTAL",
      ];
  
      doc.autoTable({
        startY: yPos,
        head: [tableHeaders],
        body: item.days.map((day) => {
          const workHours = calculateDayTotal(day);
  
          const rowData = [
            moment(day.date).format("ddd"),
            moment(day.date).format("MM/DD/YYYY"),
            day.startTime || "-",
            day.endTime || "-",
            day.lunchStartTime && day.lunchEndTime
              ? `${day.lunchStartTime}-${day.lunchEndTime}`
              : "-",
            formatNumber(workHours),
          ];
  
          return rowData;
        }),
        showFoot: "lastPage",
        foot: [
          [
            "Weekly Totals",
            "",
            "",
            "",
            "",
            formatNumber(calculateWeeklyTotal(item.days)),
          ],
        ],
        theme: "grid",
        headStyles: {
          fillColor: [255, 102, 0],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
        bodyStyles: { textColor: [0, 0, 0] },
        alternateRowStyles: { fillColor: [245, 245, 245] },
        footStyles: {
          fillColor: [220, 220, 220],
          textColor: [0, 0, 0],
          fontStyle: "bold",
        },
        styles: { fontSize: 8, cellPadding: 2 },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 25 },
          2: { cellWidth: 25 },
          3: { cellWidth: 25 },
          4: { cellWidth: 30 },
          5: { cellWidth: 25 },
        },
      });
      yPos = doc.lastAutoTable.finalY;
    }
  
    // Check if there's enough space for certification text
    if (yPos + 80 > pageHeight - margin) { // Adjust 80 based on content height
      doc.addPage();
      yPos = margin;
    }
  
    // Certification Text
    const certificationY = yPos + 10;
    const certificationText =
      "By signing this timesheet, I certify that I have carefully reviewed this timesheet and that the hours reported on this timesheet, including all start and stop times, are accurate.";
    yPos = addWrappedText(
      certificationText,
      margin,
      certificationY,
      pageWidth - 2 * margin,
      5
    );
  
    // Signature lines
    const signatureY = yPos + 15;
    const signatureWidth = (pageWidth - 3 * margin) / 2;
    const signatureHeight = 30;
  
    // Helper function to add signature safely
    const addSignatureSafely = (
      signatureData,
      x,
      y,
      width,
      height,
      label,
      dateString
    ) => {
      doc.setFontSize(10);
      doc.text(`${label} Signature:`, x, y);
  
      if (signatureData) {
        try {
          if (!signatureData.startsWith("data:image")) {
            throw new Error("Invalid signature data format");
          }
  
          const signatureWidth = 80;
          const signatureHeight = 20;
          const yOffset = y + 5;
  
          doc.addImage(
            signatureData,
            "PNG",
            x,
            yOffset,
            signatureWidth,
            signatureHeight
          );
  
          doc.setDrawColor(200, 200, 200);
          doc.rect(x, yOffset, signatureWidth, signatureHeight);
        } catch (error) {
          console.error(`Error adding ${label} signature to PDF:`, error);
          doc.text(`${label} signature not available`, x, y + 10);
          doc.line(x, y + 15, x + width, y + 15);
        }
      } else {
        doc.line(x, y + 15, x + width, y + 15);
      }
  
      if (dateString && dateString !== "N/A") {
        doc.text(`Date: ${dateString}`, x, y + height + 5);
      } else {
        doc.text("Date:", x, y + height + 5);
      }
    };
  
    // Add Employee Signature
    addSignatureSafely(
      item.signature,
      margin,
      signatureY,
      signatureWidth,
      signatureHeight,
      "Employee",
      item.signatureDate
        ? moment(item.signatureDate).format("MM/DD/YYYY")
        : ""
    );
  
    // Add Supervisor Signature
    addSignatureSafely(
      item.supervisorSignature,
      pageWidth / 2,
      signatureY,
      signatureWidth,
      signatureHeight,
      "Supervisor",
      item.supervisorSignatureDate
        ? moment(item.supervisorSignatureDate).format("MM/DD/YYYY")
        : ""
    );
  
    // Update yPos after signatures
    yPos = signatureY + signatureHeight + 20;
  
    // Add submission and approval details
    const addSubmissionAndApprovalDetails = () => {
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
  
      // Use createdAt for submission date
      const submittedDate = item.createdAt
        ? moment
            .tz(item.createdAt, "America/Phoenix")
            .format("YYYY-MM-DD hh:mm A z")
        : "N/A";
  
      doc.text(`Submitted On: ${submittedDate}`, margin, yPos);
  
      // Right side - Approval details (only if present)
      if (item.approvedBy && item.approvedDate) {
        const approvedDate = moment
          .tz(item.approvedDate, "America/Phoenix")
          .format("YYYY-MM-DD hh:mm A z");
  
        doc.text(`Approved by: ${item.approvedBy}`, pageWidth / 2, yPos);
        doc.text(`Approved on: ${approvedDate}`, pageWidth / 2, yPos + 5);
      }
    };
  
    addSubmissionAndApprovalDetails();
    yPos += 20;
  
    // Add signature verification details
    const addSignatureVerificationDetails = () => {
      // Set smaller font size and gray color for verification details
      doc.setFontSize(8);
      doc.setTextColor(100, 100, 100); // Darker gray for better readability
  
      // Helper function to format hash - shows first 6 and last 6 characters
      const formatHash = (hash) => {
        if (!hash) return "N/A";
        return `${hash.substring(0, 6)}...${hash.substring(hash.length - 6)}`;
      };
  
      // For special format, use signatureVerificationHash
      if (item.isSpecialFormat) {
        // Employee verification hash
        if (item.signatureVerificationHash) {
          doc.text(
            `Employee Verification: ${formatHash(
              item.signatureVerificationHash
            )}`,
            margin,
            yPos
          );
        }
  
        // Supervisor verification hash
        if (item.supervisorSignatureVerificationHash) {
          doc.text(
            `Supervisor Verification: ${formatHash(
              item.supervisorSignatureVerificationHash
            )}`,
            pageWidth / 2,
            yPos
          );
        }
      } else {
        // Regular format handling
        if (item.signatureVerificationHash) {
          doc.text(
            `Employee Verification: ${formatHash(
              item.signatureVerificationHash
            )}`,
            margin,
            yPos
          );
        }
  
        if (item.supervisorSignatureVerificationHash) {
          doc.text(
            `Supervisor Verification: ${formatHash(
              item.supervisorSignatureVerificationHash
            )}`,
            pageWidth / 2,
            yPos
          );
        }
      }
  
      // Add small verification note
      doc.setFontSize(7);
      doc.setTextColor(150, 150, 150); // Lighter gray for the note
      doc.text(
        "* Partial hashes shown for verification purposes",
        margin,
        yPos + 4
      );
    };
  
    addSignatureVerificationDetails();
    yPos += 20;
  
    // Save the PDF
    const fileName = `Timesheet_${item.employeeName}_${moment(
      item.weekStart
    ).format("YYYYMMDD")}.pdf`;
    doc.save(fileName);
  };

  // Helper function to format numbers to two decimal places

  // Get current pay period date
  const currentPayPeriod = payPeriods[currentPeriodIndex];

  // Get attendance data for current pay period
  const currentPeriodData = useMemo(() => {
    if (!currentPayPeriod) return [];

    return attendanceData
      .filter((item) => item.standardWeekStart === currentPayPeriod)
      .sort((a, b) => {
        // Sort by submission date within the same week
        return moment(b.date).diff(moment(a.date));
      });
  }, [attendanceData, currentPayPeriod]);

  // Modify processedData to filter based on submission status
  const processedData = useMemo(() => {
    let filteredData = currentPeriodData;

    // Apply date range filter
    if (filterDateRange !== "current") {
      const now = moment();
      let startDate;

      switch (filterDateRange) {
        case "pastWeek":
          startDate = now.clone().subtract(1, "week");
          break;
        case "past2Weeks":
          startDate = now.clone().subtract(2, "weeks");
          break;
        case "pastMonth":
          startDate = now.clone().subtract(1, "month");
          break;
        case "past3Months":
          startDate = now.clone().subtract(3, "months");
          break;
        case "past6Months":
          startDate = now.clone().subtract(6, "months");
          break;
        case "yearToDate":
          startDate = now.clone().startOf("year");
          break;
        case "custom":
          if (customStartDate && customEndDate) {
            filteredData = filteredData.filter((item) => {
              const itemDate = moment(item.weekStart);
              return itemDate.isBetween(
                customStartDate,
                customEndDate,
                "day",
                "[]"
              );
            });
          }
          break;
      }

      if (startDate) {
        filteredData = filteredData.filter((item) => {
          const itemDate = moment(item.weekStart);
          return itemDate.isAfter(startDate);
        });
      }
    }

    // Apply status filter
    if (filterStatus !== "all") {
      filteredData = filteredData.filter((item) => {
        if (filterStatus === "approved") {
          return item.approved === true;
        } else if (filterStatus === "notapproved") {
          return item.approved !== true;
        }
        return true;
      });
    }

    // Then apply submitted/unsubmitted filter if showSubmitted is being used
    filteredData = filteredData.filter((item) =>
      showSubmitted ? item.signature : !item.signature
    );

    // Then apply search filter
    if (searchQuery.trim() !== "") {
      const searchLower = searchQuery.toLowerCase();
      filteredData = filteredData.filter((item) => {
        const matches =
          (item.employeeName &&
            item.employeeName.toLowerCase().includes(searchLower)) ||
          (item.employeeId &&
            item.employeeId.toLowerCase().includes(searchLower)) ||
          (item.jobTitle &&
            item.jobTitle.toLowerCase().includes(searchLower)) ||
          (item.department &&
            item.department.toLowerCase().includes(searchLower)) ||
          (item.approvedBy &&
            item.approvedBy.toLowerCase().includes(searchLower)) ||
          (item.weekStart &&
            moment
              .tz(item.weekStart, "America/Phoenix")
              .format("YYYY-MM-DD")
              .includes(searchLower)) ||
          (item.date &&
            moment
              .tz(item.date, "America/Phoenix")
              .format("YYYY-MM-DD")
              .includes(searchLower)) ||
          (item.approvedDate &&
            moment
              .tz(item.approvedDate, "America/Phoenix")
              .format("YYYY-MM-DD")
              .includes(searchLower)) ||
          (item.weeklyTotalHours !== null &&
            item.weeklyTotalHours !== undefined &&
            item.weeklyTotalHours.toString().includes(searchLower)) ||
          (item.notes &&
            item.notes.some(
              (note) =>
                note.content.toLowerCase().includes(searchLower) ||
                note.supervisorName.toLowerCase().includes(searchLower)
            ));

        return matches;
      });
    }

    return filteredData;
  }, [
    currentPeriodData,
    searchQuery,
    showSubmitted,
    filterStatus,
    filterDateRange,
    customStartDate,
    customEndDate,
  ]);

  // Add pagination functions
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Add this useEffect to calculate total pages whenever data changes
  useEffect(() => {
    if (processedData) {
      setTotalPages(Math.ceil(processedData.length / itemsPerPage));
    }
  }, [processedData]);

  // Add this to calculate current items for the page
  const currentItems = useMemo(() => {
    if (!processedData) return [];
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return processedData.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, processedData]);

  // Reset to first page when processedData changes
  useEffect(() => {
    setCurrentPage(1);
  }, [processedData]);

  // Navigation Functions
  const goToNextPeriod = useCallback(() => {
    if (currentPeriodIndex < payPeriods.length - 1) {
      setCurrentPeriodIndex(currentPeriodIndex + 1);
    }
  }, [currentPeriodIndex, payPeriods.length]);

  const goToPreviousPeriod = useCallback(() => {
    if (currentPeriodIndex > 0) {
      setCurrentPeriodIndex(currentPeriodIndex - 1);
    }
  }, [currentPeriodIndex]);

  const renderRegularTable = (item, isDesktop) => (
    <View
      style={[
        styles.tableContainer,
        isDesktop && styles.desktopTableContainer,
        dynamicStyles.tableContainer,
      ]}
    >
      {/* Table Header */}
      <View
        style={[
          styles.tableHeader,
          isDesktop && styles.desktopTableHeader,
          dynamicStyles.tableHeader,
        ]}
      >
        {[
          "Day",
          "Date",
          "Start Time",
          "End Time",
          "Lunch",
          "Work Hours",
          "Vacation Hours",
          "Sick Hours",
          "Holiday Hours",
          "Total Work Hours",
        ].map((header, index) => (
          <Text
            key={index}
            style={[
              styles.headerCell,
              isDesktop && styles.desktopHeaderCell,
              dynamicStyles.headerCell,
            ]}
          >
            {header}
          </Text>
        ))}
      </View>

      {/* Table Rows */}
      {item.days.map((day, index) => {
        const workHours = calculateDayTotal(day);
        console.log(`Day ${index} total hours:`, workHours); // Debug log
        return (
          <View
            key={index}
            style={[
              styles.tableRow,
              isDesktop && styles.desktopTableRow,
              dynamicStyles.tableRow,
            ]}
          >
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {moment(day.date).format("ddd")}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {moment(day.date).format("MM/DD")}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {day.startTime || "-"}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {day.endTime || "-"}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {day.lunchStartTime && day.lunchEndTime
                ? `${day.lunchStartTime}-${day.lunchEndTime}`
                : "-"}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {formatNumber(workHours)}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {formatNumber(day.hours?.find((h) => h.type === "vacation")?.amount)}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {formatNumber(day.hours?.find((h) => h.type === "sick")?.amount)}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {formatNumber(day.hours?.find((h) => h.type === "holiday")?.amount)}
            </Text>
            <Text
              style={[
                styles.tableCell,
                isDesktop && styles.desktopTableCell,
                dynamicStyles.tableCell,
              ]}
            >
              {formatNumber(workHours)}
            </Text>
          </View>
        );
      })}
    </View>
  );

  const renderSpecialFormatTable = (item, isDesktop) => {
    const sortedDays = [...item.days].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    return (
      <View
        style={[
          styles.tableContainer,
          isDesktop && styles.desktopTableContainer,
          dynamicStyles.tableContainer,
        ]}
      >
        {/* Table Header */}
        <View
          style={[
            styles.tableHeader,
            isDesktop && styles.desktopTableHeader,
            dynamicStyles.tableHeader,
          ]}
        >
          {[
            "Day",
            "Date",
            "Service",
            "Start",
            "End",
            "Lunch",
            "Total",
            "Stand-by",
            "Day Total",
          ].map((header, index) => (
            <Text
              key={index}
              style={[
                styles.tableHeaderCell,
                dynamicStyles.tableHeaderCell,
                isDesktop && styles.desktopTableHeaderCell,
                { flex: header === "Service" ? 2 : 1 },
              ]}
            >
              {header}
            </Text>
          ))}
        </View>

        {/* Table Rows */}
        {sortedDays.map((day, dayIndex) => (
          <React.Fragment key={dayIndex}>
            {day.services && day.services.length > 0 ? (
              day.services.map((service, serviceIndex) => (
                <View
                  key={`${dayIndex}-${serviceIndex}`}
                  style={[
                    styles.tableRow,
                    isDesktop && styles.desktopTableRow,
                    dynamicStyles.tableRow,
                  ]}
                >
                  <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                    {serviceIndex === 0 ? moment(day.date).format("ddd") : ""}
                  </Text>
                  <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                    {serviceIndex === 0 ? moment(day.date).format("MM/DD") : ""}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.serviceCell,
                      dynamicStyles.tableCell,
                    ]}
                  >
                    {service.service}
                  </Text>
                  <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                    {service.startTime}
                  </Text>
                  <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                    {service.endTime}
                  </Text>
                  <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                    {serviceIndex === 0 &&
                    day.lunchStartTime &&
                    day.lunchEndTime
                      ? `${day.lunchStartTime}-${day.lunchEndTime}`
                      : "-"}
                  </Text>
                  <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                    {formatNumber(
                      calculateTotalTime(service.startTime, service.endTime)
                    )}
                  </Text>
                  <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                    {serviceIndex === 0
                      ? formatNumber(day.standByHours || 0)
                      : ""}
                  </Text>
                  <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                    {serviceIndex === 0
                      ? formatNumber(calculateDayTotal(day))
                      : ""}
                  </Text>
                </View>
              ))
            ) : (
              <View
                style={[
                  styles.tableRow,
                  isDesktop && styles.desktopTableRow,
                  dynamicStyles.tableRow,
                ]}
              >
                <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                  {moment(day.date).format("ddd")}
                </Text>
                <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                  {moment(day.date).format("MM/DD")}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.serviceCell,
                    dynamicStyles.tableCell,
                  ]}
                >
                  -
                </Text>
                <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                  -
                </Text>
                <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                  -
                </Text>
                <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                  -
                </Text>
                <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                  0.00
                </Text>
                <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                  {formatNumber(day.standByHours || 0)}
                </Text>
                <Text style={[styles.tableCell, dynamicStyles.tableCell]}>
                  {formatNumber(calculateDayTotal(day))}
                </Text>
              </View>
            )}
          </React.Fragment>
        ))}
      </View>
    );
  };

  const [isResetting, setIsResetting] = useState(false);

  const resetApproval = async (attendance) => {
    try {
      console.log("Starting reset process for attendance:", attendance._id);
      setIsResetting(true);

      // Log the URL being called
      const url = `https://sbg-backend.onrender.com/attendances/${attendance._id}/resetApproval`;
      console.log("Calling API endpoint:", url);

      const response = await axios.put(
        url,
        {}, // Empty body
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Reset API Response:", response.data);

      if (response.data) {
        console.log("Reset successful, refreshing data...");
        await fetchAttendanceData(); // Refresh the data
        Alert.alert("Success", "Approval status has been reset successfully");
      }
    } catch (error) {
      console.error("Reset approval error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });

      Alert.alert(
        "Error",
        `Failed to reset approval: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      console.log("Reset process completed");
      setIsResetting(false);
    }
  };

  // Update the reset button press handler to be more explicit
  const handleResetPress = (item) => {
    console.log("Reset button pressed for attendance:", item._id);

    if (!item._id) {
      console.error("No attendance ID found");
      Alert.alert("Error", "Cannot reset: Invalid attendance record");
      return;
    }

    Alert.alert(
      "Reset Approval",
      `Are you sure you want to reset the approval status for ${item.employeeName}'s timesheet?`,
      [
        {
          text: "Cancel",
          style: "cancel",
          onPress: () => console.log("Reset cancelled"),
        },
        {
          text: "Reset",
          style: "destructive",
          onPress: () => {
            console.log("Reset confirmed for attendance:", item._id);
            resetApproval(item);
          },
        },
      ]
    );
  };

  const renderAttendance = ({ item }) => {
    console.log(
      `Rendering Attendance ID: ${item._id}, isSpecialFormat: ${item.isSpecialFormat}`
    );

    const firstDay =
      item.days.length > 0
        ? moment.tz(item.days[0].date, "America/Phoenix")
        : moment.tz(item.weekStart, "America/Phoenix");
    const weekStart = firstDay.clone().startOf("week");
    const weekEnd = weekStart.clone().add(6, "days");

    return (
      <View
        style={[styles.attendanceContainer, dynamicStyles.attendanceContainer]}
      >
        <View style={styles.employeeInfo}>
          <View style={[styles.employeeAvatar, dynamicStyles.employeeAvatar]}>
            <Text style={styles.avatarText}>
              {item?.employeeName?.charAt(0)}
            </Text>
          </View>
          <View>
            <Text style={[styles.employeeName, dynamicStyles.employeeName]}>
              {item?.employeeName}
            </Text>
            <Text style={[styles.employeeDetail, dynamicStyles.employeeDetail]}>
              ID: {item?.employeeId}
            </Text>
            <Text style={[styles.employeeDetail, dynamicStyles.employeeDetail]}>
              Job Title: {item.jobTitle || "N/A"}
            </Text>
            <Text style={[styles.employeeDetail, dynamicStyles.employeeDetail]}>
              Department: {item.department || "N/A"}
            </Text>
            <Text style={[styles.employeeDetail, dynamicStyles.employeeDetail]}>
              Submitted On:{" "}
              {item.createdAt
                ? moment
                    .tz(item.createdAt, "America/Phoenix")
                    .format("YYYY-MM-DD hh:mm A z")
                : "N/A"}
            </Text>
            <Text style={[styles.employeeDetail, dynamicStyles.employeeDetail]}>
              Week: {weekStart.format("YYYY-MM-DD")} to{" "}
              {weekEnd.format("YYYY-MM-DD")}
            </Text>
            <Text style={[styles.employeeDetail, dynamicStyles.employeeDetail]}>
              Weekly Total Hours: {formatNumber(item?.weeklyTotalHours)}
            </Text>
            <Text style={[styles.employeeDetail, dynamicStyles.employeeDetail]}>
              Status: {item.signature ? "Submitted" : "In Progress"}
            </Text>
            {item.approved && (
              <>
                <Text
                  style={[styles.approvedDetail, dynamicStyles.approvedDetail]}
                >
                  Approved by: {item.approvedBy}
                </Text>
                <Text
                  style={[styles.approvedDetail, dynamicStyles.approvedDetail]}
                >
                  Approved on:{" "}
                  {item.approvedDate
                    ? moment
                        .tz(item.approvedDate, "America/Phoenix")
                        .format("YYYY-MM-DD hh:mm A z")
                    : "N/A"}
                </Text>
              </>
            )}
          </View>
        </View>
        <ScrollView horizontal={!isDesktop}>
          {item.isSpecialFormat
            ? renderSpecialFormatTable(item, isDesktop)
            : renderRegularTable(item, isDesktop)}
        </ScrollView>

        <View style={[styles.notesSection, dynamicStyles.notesSection]}>
          <Text style={[styles.notesHeader, dynamicStyles.notesHeader]}>
            Notes:
          </Text>
          {item.notes && item.notes.length > 0 ? (
            item.notes.map((note, index) => (
              <View
                key={index}
                style={[styles.noteItem, dynamicStyles.noteItem]}
              >
                <Text style={[styles.noteContent, dynamicStyles.noteContent]}>
                  {note.content}
                </Text>
                <Text style={[styles.noteDetails, dynamicStyles.noteDetails]}>
                  By {note.supervisorName} on{" "}
                  {note.date
                    ? moment
                        .tz(note.date, "America/Phoenix")
                        .format("YYYY-MM-DD hh:mm A z")
                    : "N/A"}
                </Text>
              </View>
            ))
          ) : (
            <Text style={[styles.noNotesText, dynamicStyles.noNotesText]}>
              No notes available
            </Text>
          )}
        </View>

        <View style={styles.addNoteSection}>
          <TextInput
            style={[styles.noteInput, dynamicStyles.noteInput]}
            value={newNote}
            onChangeText={setNewNote}
            placeholder="Add a new note..."
            placeholderTextColor={dynamicStyles.placeholderColor}
            multiline
            accessible={true}
            accessibilityLabel="Add a new note"
          />
          <Pressable
            style={[styles.addNoteButton, dynamicStyles.addNoteButton]}
            onPress={() => addNote(item._id)}
            accessible={true}
            accessibilityLabel="Add Note Button"
          >
            <Text style={styles.addNoteButtonText}>Add Note</Text>
          </Pressable>
        </View>

        <View style={styles.buttonContainer}>
          {!item.approved && (
            <Pressable
              style={[styles.approveButton, dynamicStyles.approveButton]}
              onPress={() => approveAttendance(item)}
              accessible={true}
              accessibilityLabel="Approve Attendance Button"
            >
              <Text style={styles.approveButtonText}>Approve</Text>
            </Pressable>
          )}

          {item.approved && (
            <Pressable
              style={[
                styles.resetButton,
                isResetting && styles.resetButtonDisabled,
              ]}
              onPress={() => handleResetPress(item)}
              disabled={isResetting}
            >
              <Text style={styles.resetButtonText}>
                {isResetting ? "Resetting..." : "Reset Approval"}
              </Text>
            </Pressable>
          )}

          <Pressable
            style={[styles.exportButton, dynamicStyles.exportButton]}
            onPress={() => generatePDF(item)}
            accessible={true}
            accessibilityLabel="Export to PDF Button"
          >
            <Text style={styles.exportButtonText}>Export to PDF</Text>
          </Pressable>
        </View>
      </View>
    );
  };

  if (loading) {
    return (
      <View style={dynamicStyles.loadingContainer}>
        <CustomLoading
          isDarkMode={colorScheme === "dark"}
          width={300}
          height={75}
        />
      </View>
    );
  }

  return (
    <SafeAreaView style={[styles.container, dynamicStyles?.container]}>
      <View style={[styles.header, dynamicStyles?.header]}>
        <BackButton
          color={dynamicStyles?.headerIconColor?.color || "#000000"}
        />
        <Text style={[styles.headerText, dynamicStyles?.headerText]}>
          Attendance Summary
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
          accessible={true}
          accessibilityLabel="Toggle Theme"
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={dynamicStyles?.headerIconColor?.color || "#000000"}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => setShowQuickActions(true)}
          style={styles.quickActionsButton}
        >
          <Feather
            name="more-vertical"
            size={24}
            color={dynamicStyles?.headerIconColor?.color || "#000000"}
          />
        </TouchableOpacity>
      </View>

      <View style={[styles.content, isDesktop && styles.desktopContent]}>
        {/* Period Navigation */}
        <View style={styles.periodNavigation}>
          <TouchableOpacity
            onPress={goToPreviousPeriod}
            disabled={currentPeriodIndex === 0}
            style={[
              styles.navigationButton,
              currentPeriodIndex === 0 && styles.disabledButton,
            ]}
          >
            <Text style={dynamicStyles.navigationButtonText}>
              Previous Week
            </Text>
          </TouchableOpacity>

          <Text style={[styles.periodText, dynamicStyles?.periodText]}>
            Week of{" "}
            {payPeriods[currentPeriodIndex]
              ? moment(payPeriods[currentPeriodIndex]).format("MMM D, YYYY")
              : "N/A"}
          </Text>

          <TouchableOpacity
            onPress={goToNextPeriod}
            disabled={currentPeriodIndex === payPeriods.length - 1}
            style={[
              styles.navigationButton,
              currentPeriodIndex === payPeriods.length - 1 &&
                styles.disabledButton,
            ]}
          >
            <Text style={dynamicStyles.navigationButtonText}>Next Week</Text>
          </TouchableOpacity>
        </View>

        {/* Render Filter Section */}
        {renderFilterSection()}

        {/* Existing Search Bar */}
        <View style={[styles.searchContainer, dynamicStyles?.searchContainer]}>
          <TextInput
            style={[styles.searchInput, dynamicStyles.searchInput]}
            placeholder="Search..."
            placeholderTextColor={
              dynamicStyles?.placeholderText?.color || "#999999"
            }
            value={searchQuery}
            onChangeText={setSearchQuery}
          />
          <TouchableOpacity
            style={styles.toggleButton}
            onPress={() => setShowSubmitted(!showSubmitted)}
          >
            <Text
              style={[styles.toggleButtonText, dynamicStyles.toggleButtonText]}
            >
              {showSubmitted ? "Show Unsubmitted" : "Show Submitted"}
            </Text>
          </TouchableOpacity>
        </View>

        {/* Attendance List */}
        <FlatList
          data={currentItems}
          renderItem={renderAttendance}
          keyExtractor={(item) => item._id}
          contentContainerStyle={styles.listContainer}
        />

        {/* Pagination Controls */}
        <View style={styles.paginationContainer}>
          <TouchableOpacity
            onPress={goToPreviousPage}
            disabled={currentPage === 1}
            style={[
              styles.paginationButton,
              currentPage === 1 && styles.disabledButton,
            ]}
          >
            <Text style={dynamicStyles?.paginationButtonText}>Previous</Text>
          </TouchableOpacity>

          <View style={styles.pageNumbers}>
            {[...Array(totalPages)].map((_, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => paginate(index + 1)}
                style={[
                  styles.pageNumber,
                  currentPage === index + 1 && styles.currentPage,
                ]}
              >
                <Text
                  style={[
                    dynamicStyles?.pageNumberText,
                    currentPage === index + 1 && styles.currentPageText,
                  ]}
                >
                  {index + 1}
                </Text>
              </TouchableOpacity>
            ))}
          </View>

          <TouchableOpacity
            onPress={goToNextPage}
            disabled={currentPage === totalPages}
            style={[
              styles.paginationButton,
              currentPage === totalPages && styles.disabledButton,
            ]}
          >
            <Text style={dynamicStyles?.paginationButtonText}>Next</Text>
          </TouchableOpacity>
        </View>
      </View>

      <QuickActionsMenu
        visible={showQuickActions}
        onClose={() => setShowQuickActions(false)}
        onExportExcel={generateExcel}
        dynamicStyles={dynamicStyles}
      />
    </SafeAreaView>
  );
};

export default AllAttendanceSummary;
