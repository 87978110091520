import React from 'react';
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';

const BackButton = ({ color }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

const UserGuide = () => {
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={[styles.content, isDesktop && styles.desktopContent]}>
          {/* Introduction Section */}
          <Section title="Introduction">
            <Text style={styles.paragraph}>
              Welcome to the Scott Business Group Attendance Management System. This guide will help you understand how to use the system effectively for submitting your timesheets.
            </Text>
          </Section>

          {/* Important Deadlines Section */}
          <Section title="Important Deadlines">
            <Text style={styles.paragraph}>
              • Timesheets are due every Monday by 12:00 PM (noon) MST
            </Text>
            <Text style={styles.paragraph}>
              • Late submissions require supervisor approval
            </Text>
          </Section>

          {/* Timesheet Submission Process */}
          <Section title="How to Submit Your Timesheet">
            <Text style={styles.paragraph}>
              1. Log in to your account
            </Text>
            <Text style={styles.paragraph}>
              2. Navigate to the timesheet submission page
            </Text>
            <Text style={styles.paragraph}>
              3. Enter your work hours for each day:
              {'\n'}  • Regular work hours
              {'\n'}  • Lunch break times
              {'\n'}  • Standby hours (if applicable)
            </Text>
            <Text style={styles.paragraph}>
              4. Review your entries for accuracy
            </Text>
            <Text style={styles.paragraph}>
              5. Add your digital signature
            </Text>
            <Text style={styles.paragraph}>
              6. Submit your timesheet
            </Text>
          </Section>

          {/* Tips and Best Practices */}
          <Section title="Tips and Best Practices">
            <Text style={styles.paragraph}>
              • Submit your timesheet as soon as your work week ends
            </Text>
            <Text style={styles.paragraph}>
              • Double-check all entries before submitting
            </Text>
            <Text style={styles.paragraph}>
              • Keep track of your hours throughout the week
            </Text>
            <Text style={styles.paragraph}>
              • Contact your supervisor immediately if you notice any errors after submission
            </Text>
          </Section>

          {/* Need Help Section */}
          <Section title="Need Help?">
            <Text style={styles.paragraph}>
              If you need assistance or have questions about using the system, please contact:
            </Text>
            <Text style={styles.paragraph}>
              • Your immediate supervisor
            </Text>
            <Text style={styles.paragraph}>
              • IT Support: sbg.software.dev@gmail.com
            </Text>
          </Section>
        </View>
      </ScrollView>
    </View>
  );
};

// Section component for better organization
const Section = ({ title, children }) => (
  <View style={styles.section}>
    <Text style={styles.sectionTitle}>{title}</Text>
    <View style={styles.sectionContent}>{children}</View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f8f9fa',
  },
  scrollView: {
    flex: 1,
  },
  content: {
    padding: 20,
    maxWidth: 800,
    alignSelf: 'center',
    width: '100%',
  },
  desktopContent: {
    padding: 40,
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#212529',
    marginBottom: 16,
  },
  sectionContent: {
    marginLeft: 8,
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 24,
    color: '#495057',
    marginBottom: 12,
  },
  bold: {
    fontWeight: '600',
  },
});

export default UserGuide;
