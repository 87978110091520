import React, { useState, useEffect, useRef } from "react";
import {
  View,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  StatusBar,
  useWindowDimensions,
  useColorScheme as useDeviceColorScheme,
  Modal,
  Platform,
} from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "moment-timezone";
import axios from "axios";
import { Feather, MaterialIcons } from "@expo/vector-icons";
import { useLocalSearchParams } from "expo-router";
import { useNavigation } from "@react-navigation/native";
import CustomAlert from "./CustomAlert";
import TimeInput from "./TimeInput";
import AmPmRadioButtons from "./AmPmRadioButtons";
import SignatureCanvas from "react-signature-canvas";
import CryptoJS from "crypto-js";

// Helper functions
const getCurrentWeekDates = (selectedDate) => {
  const weekStart = selectedDate.clone().startOf("week");
  const weekEnd = weekStart.clone().add(6, "days").endOf("day");

  // Since users can now submit timesheets outside of the deadline,
  // we set isSubmissionOpen to true
  const isSubmissionOpen = true;

  return { weekStart, weekEnd, isSubmissionOpen };
};

const getWeekDays = (weekStart) => {
  return Array.from({ length: 7 }, (_, i) => weekStart.clone().add(i, "days"));
};

const getTimesheetMessage = (weekStart) => {
  const now = moment().tz("America/Phoenix");
  const currentWeekStart = now.clone().startOf("week");
  if (weekStart.isSame(currentWeekStart, "day")) {
    return "You are viewing the current week's timesheet.";
  } else {
    return `You are viewing the timesheet for the week starting ${weekStart.format(
      "MMMM D, YYYY"
    )}.`;
  }
};

// Back Button Component
const BackButton = ({ color }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

// User Component
const User = () => {
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;
  const navigation = useNavigation();
  const params = useLocalSearchParams();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);

  const [recentHistory, setRecentHistory] = useState([]);
  const [currentWeek, setCurrentWeek] = useState([]);
  const [startTimes, setStartTimes] = useState(Array(7).fill(""));
  const [endTimes, setEndTimes] = useState(Array(7).fill(""));
  const [lunchStartTimes, setLunchStartTimes] = useState(Array(7).fill(""));
  const [lunchEndTimes, setLunchEndTimes] = useState(Array(7).fill(""));
  const [totalWorkHours, setTotalWorkHours] = useState(Array(7).fill(0));
  const [weeklyTotalHours, setWeeklyTotalHours] = useState("");
  const [vacationTimes, setVacationTimes] = useState(Array(7).fill(""));
  const [sickTimes, setSickTimes] = useState(Array(7).fill(""));
  const [holidayTimes, setHolidayTimes] = useState(Array(7).fill(""));
  const [canSubmit, setCanSubmit] = useState(true);
  const [amPmTimes, setAmPmTimes] = useState(
    Array(7).fill({ start: "AM", end: "PM", lunchStart: "PM", lunchEnd: "PM" })
  );
  const [savedDays, setSavedDays] = useState(Array(7).fill(false));
  const [isSignatureChecked, setIsSignatureChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertConfirm, setAlertConfirm] = useState(null);
  const [showCancel, setShowCancel] = useState(true);

  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [signature, setSignature] = useState(null);
  const signaturePadRef = useRef(null);
  const [signatureData, setSignatureData] = useState(null);
  const [isSignaturePadEmpty, setIsSignaturePadEmpty] = useState(true);

  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const scrollViewRef = useRef(null);

  // New states for date picker
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().tz("America/Phoenix")
  );

  const showAlert = (message, title, onConfirm = null, showCancel = true) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertConfirm(() => onConfirm);
    setAlertVisible(true);
    setShowCancel(showCancel);
  };

  const formattedTotalHours = weeklyTotalHours
    ? weeklyTotalHours.toFixed(2)
    : "";

  const fetchRecentHistory = async () => {
    try {
      const response = await axios.get(
        `https://sbg-backend.onrender.com/attendances/${params?.id}/history?limit=5`
      );
      setRecentHistory(response.data);
    } catch (error) {
      console.error("Error fetching recent history:", error);
    }
  };

  const isSubmissionAllowed = () => {
    const { isSubmissionOpen } = getCurrentWeekDates(selectedDate);
    return isSubmissionOpen;
  };

  const fetchAttendanceData = async () => {
    try {
      const { weekStart } = getCurrentWeekDates(selectedDate);

      const response = await axios.get(
        `https://sbg-backend.onrender.com/attendances/${
          params?.id
        }?weekStart=${weekStart.format("YYYY-MM-DD")}`
      );

      const attendanceData = response.data;

      if (attendanceData && attendanceData.length > 0) {
        const currentWeekData = attendanceData.find((data) =>
          moment(data.weekStart).isSame(weekStart, "day")
        );

        if (currentWeekData) {
          populateTimesheetData(currentWeekData);
          setIsSubmitted(currentWeekData.isSubmitted || false);
          setCanSubmit(!currentWeekData.isSubmitted && isSubmissionAllowed());
        } else {
          resetStates();
          setIsSubmitted(false);
          setCanSubmit(isSubmissionAllowed());
        }
      } else {
        resetStates();
        setIsSubmitted(false);
        setCanSubmit(isSubmissionAllowed());
      }

      setCurrentWeek(getWeekDays(weekStart));
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      resetStates();
      setIsSubmitted(false);
      setCanSubmit(isSubmissionAllowed());
    }
  };

  const populateTimesheetData = (weekData) => {
    const newStartTimes = Array(7).fill("");
    const newEndTimes = Array(7).fill("");
    const newLunchStartTimes = Array(7).fill("");
    const newLunchEndTimes = Array(7).fill("");
    const newTotalWorkHours = Array(7).fill(0);
    const newVacationTimes = Array(7).fill("");
    const newSickTimes = Array(7).fill("");
    const newHolidayTimes = Array(7).fill("");
    const newAmPmTimes = Array(7).fill({
      start: "AM",
      end: "PM",
      lunchStart: "PM",
      lunchEnd: "PM",
    });
    const newSavedDays = Array(7).fill(false);

    const weekStart = moment(weekData.weekStart).tz("America/Phoenix");

    weekData.days.forEach((day) => {
      const dayMoment = moment(day.date).tz("America/Phoenix");
      const dayIndex = dayMoment.diff(weekStart, "days");

      if (dayIndex < 0 || dayIndex > 6) return;

      const transformTime = (timeString) => {
        if (!timeString) return { time: "", amPm: "AM" };
        const [time, amPm] = timeString.split(" ");
        return { time, amPm };
      };

      const startTimeData = transformTime(day.startTime);
      newStartTimes[dayIndex] = startTimeData.time;
      newAmPmTimes[dayIndex] = {
        ...newAmPmTimes[dayIndex],
        start: startTimeData.amPm,
      };

      const endTimeData = transformTime(day.endTime);
      newEndTimes[dayIndex] = endTimeData.time;
      newAmPmTimes[dayIndex] = {
        ...newAmPmTimes[dayIndex],
        end: endTimeData.amPm,
      };

      const lunchStartData = transformTime(day.lunchStartTime);
      newLunchStartTimes[dayIndex] = lunchStartData.time;
      newAmPmTimes[dayIndex] = {
        ...newAmPmTimes[dayIndex],
        lunchStart: lunchStartData.amPm,
      };

      const lunchEndData = transformTime(day.lunchEndTime);
      newLunchEndTimes[dayIndex] = lunchEndData.time;
      newAmPmTimes[dayIndex] = {
        ...newAmPmTimes[dayIndex],
        lunchEnd: lunchEndData.amPm,
      };

      newTotalWorkHours[dayIndex] =
        day.hours.find((h) => h.type === "work")?.amount || 0;
      newVacationTimes[dayIndex] = (
        day.hours.find((h) => h.type === "vacation")?.amount || 0
      ).toString();
      newSickTimes[dayIndex] = (
        day.hours.find((h) => h.type === "sick")?.amount || 0
      ).toString();
      newHolidayTimes[dayIndex] = (
        day.hours.find((h) => h.type === "holiday")?.amount || 0
      ).toString();
      newSavedDays[dayIndex] = true;
    });

    setStartTimes(newStartTimes);
    setEndTimes(newEndTimes);
    setLunchStartTimes(newLunchStartTimes);
    setLunchEndTimes(newLunchEndTimes);
    setTotalWorkHours(newTotalWorkHours);
    setVacationTimes(newVacationTimes);
    setSickTimes(newSickTimes);
    setHolidayTimes(newHolidayTimes);
    setAmPmTimes(newAmPmTimes);
    setSavedDays(newSavedDays);
    setWeeklyTotalHours(weekData.weeklyTotalHours || 0);
  };

  const resetStates = () => {
    setStartTimes(Array(7).fill(""));
    setEndTimes(Array(7).fill(""));
    setLunchStartTimes(Array(7).fill(""));
    setLunchEndTimes(Array(7).fill(""));
    setTotalWorkHours(Array(7).fill(0));
    setVacationTimes(Array(7).fill(""));
    setSickTimes(Array(7).fill(""));
    setHolidayTimes(Array(7).fill(""));
    setAmPmTimes(
      Array(7).fill({
        start: "AM",
        end: "PM",
        lunchStart: "PM",
        lunchEnd: "PM",
      })
    );
    setWeeklyTotalHours(0);
    setSavedDays(Array(7).fill(false));
  };

  useEffect(() => {
    const updateTimesheetData = async () => {
      setIsLoading(true);
      const { weekStart, isSubmissionOpen } = getCurrentWeekDates(selectedDate);
      setCurrentWeek(getWeekDays(weekStart));
      await fetchAttendanceData();
      setCanSubmit(isSubmissionOpen);
      setIsLoading(false);
    };

    updateTimesheetData();
    fetchRecentHistory();

    const interval = setInterval(updateTimesheetData, 60000);

    return () => clearInterval(interval);
  }, [params?.id, selectedDate]);

  useEffect(() => {
    const calculateTotalWorkHours = () => {
      const minuteToDecimal = (minutes) => {
        const chart = {
          1: 0.02,
          2: 0.03,
          3: 0.05,
          4: 0.07,
          5: 0.08,
          6: 0.1,
          7: 0.12,
          8: 0.13,
          9: 0.15,
          10: 0.17,
          11: 0.18,
          12: 0.2,
          13: 0.22,
          14: 0.23,
          15: 0.25,
          16: 0.27,
          17: 0.28,
          18: 0.3,
          19: 0.32,
          20: 0.33,
          21: 0.35,
          22: 0.37,
          23: 0.38,
          24: 0.4,
          25: 0.42,
          26: 0.43,
          27: 0.45,
          28: 0.47,
          29: 0.48,
          30: 0.5,
          31: 0.52,
          32: 0.53,
          33: 0.55,
          34: 0.57,
          35: 0.58,
          36: 0.6,
          37: 0.62,
          38: 0.63,
          39: 0.65,
          40: 0.67,
          41: 0.68,
          42: 0.7,
          43: 0.72,
          44: 0.73,
          45: 0.75,
          46: 0.77,
          47: 0.78,
          48: 0.8,
          49: 0.82,
          50: 0.83,
          51: 0.85,
          52: 0.87,
          53: 0.88,
          54: 0.9,
          55: 0.92,
          56: 0.93,
          57: 0.95,
          58: 0.97,
          59: 0.98,
          60: 1.0,
        };
        return chart[minutes] || 0;
      };

      const newTotalWorkHours = startTimes.map((startTime, i) => {
        if (startTime && endTimes[i]) {
          const start = moment.tz(
            `${startTime} ${amPmTimes[i].start}`,
            "hh:mm A",
            "America/Phoenix"
          );
          const end = moment.tz(
            `${endTimes[i]} ${amPmTimes[i].end}`,
            "hh:mm A",
            "America/Phoenix"
          );

          if (end.isBefore(start)) {
            end.add(1, "day");
          }

          const workDuration = moment.duration(end.diff(start));
          let workMinutes = workDuration.asMinutes();

          if (lunchStartTimes[i] && lunchEndTimes[i]) {
            const lunchStart = moment.tz(
              `${lunchStartTimes[i]} ${amPmTimes[i].lunchStart}`,
              "hh:mm A",
              "America/Phoenix"
            );
            const lunchEnd = moment.tz(
              `${lunchEndTimes[i]} ${amPmTimes[i].lunchEnd}`,
              "hh:mm A",
              "America/Phoenix"
            );

            if (lunchEnd.isBefore(lunchStart)) {
              lunchEnd.add(1, "day");
            }

            const lunchDuration = moment.duration(lunchEnd.diff(lunchStart));
            workMinutes -= lunchDuration.asMinutes();
          }

          const workHours = Math.floor(workMinutes / 60);
          const remainingMinutes = workMinutes % 60;
          return workHours + minuteToDecimal(remainingMinutes);
        } else {
          return 0;
        }
      });

      // Set total work hours without adding special times
      setTotalWorkHours(newTotalWorkHours);

      // Calculate weekly total using only regular work hours
      const newWeeklyTotalHours = newTotalWorkHours.reduce(
        (acc, hours) => acc + (hours || 0),
        0
      );
      setWeeklyTotalHours(newWeeklyTotalHours);
    };

    calculateTotalWorkHours();
  }, [startTimes, endTimes, lunchStartTimes, lunchEndTimes, amPmTimes]);

  const validateTime = (time) => {
    const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/;
    return timeRegex.test(time);
  };

  const handleSaveDay = async (index) => {
    if (!canSubmit || isSubmitted) {
      showAlert(
        "This timesheet has already been submitted and cannot be modified.",
        "Submission Closed",
        null,
        false
      );
      return;
    }

    const timesToValidate = [
      startTimes[index],
      endTimes[index],
      lunchStartTimes[index],
      lunchEndTimes[index],
    ];

    const invalidTimes = timesToValidate.filter(
      (time) => time && !validateTime(time)
    );

    if (invalidTimes.length > 0) {
      showAlert(
        "Please enter valid times in the format HH:MM (e.g., 09:00).",
        "Invalid Time Format",
        null,
        false
      );
      return;
    }

    const vacationHours = parseFloat(vacationTimes[index]) || 0;
    const sickHours = parseFloat(sickTimes[index]) || 0;
    const holidayHours = parseFloat(holidayTimes[index]) || 0;
    const totalSpecialHours = vacationHours + sickHours + holidayHours;

    if (totalSpecialHours > 8) {
      showAlert(
        "The combined vacation, sick, and holiday hours cannot exceed 8 hours for a single day.",
        "Invalid Hours",
        null,
        false
      );
      return;
    }

    const combineTimeAndAmPm = (time, amPm) => {
      return time ? `${time} ${amPm}` : null;
    };

    try {
      const { weekStart } = getCurrentWeekDates(selectedDate);
      const dayDate = weekStart.clone().add(index, "days");

      const dayData = {
        employeeId: params?.id,
        employeeName: params?.name,
        dayOfWeek: dayDate.format("dddd"),
        date: dayDate.format("YYYY-MM-DD"),
        startTime: combineTimeAndAmPm(
          startTimes[index],
          amPmTimes[index].start
        ),
        endTime: combineTimeAndAmPm(endTimes[index], amPmTimes[index].end),
        lunchStartTime: combineTimeAndAmPm(
          lunchStartTimes[index],
          amPmTimes[index].lunchStart
        ),
        lunchEndTime: combineTimeAndAmPm(
          lunchEndTimes[index],
          amPmTimes[index].lunchEnd
        ),
        hours: [
          { type: "work", amount: totalWorkHours[index] },
          { type: "vacation", amount: vacationHours },
          { type: "sick", amount: sickHours },
          { type: "holiday", amount: holidayHours },
        ],
        weekStart: weekStart.format("YYYY-MM-DD"),
      };

      const response = await axios.post(
        "https://sbg-backend.onrender.com/saveDay",
        dayData
      );

      if (response.status === 200) {
        if (response.data.isSubmitted) {
          setIsSubmitted(true);
          setCanSubmit(false);
          showAlert(
            "This timesheet has been submitted and can no longer be modified.",
            "Timesheet Locked",
            null,
            false
          );
        } else {
          const newSavedDays = [...savedDays];
          newSavedDays[index] = true;
          setSavedDays(newSavedDays);
          showAlert("Day saved successfully", "Success", null, false);
        }
      } else {
        showAlert(
          "Failed to save day. Please try again.",
          "Error",
          null,
          false
        );
      }
    } catch (error) {
      console.error("Error saving day:", error);
      showAlert("An error occurred. Please try again.", "Error", null, false);
    }
  };

  const handleTermsScroll = (event) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const paddingToBottom = 20;
    if (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    ) {
      setHasScrolledToBottom(true);
    }
  };

  const handleTermsCheckboxPress = () => {
    if (!hasAgreedToTerms) {
      setIsTermsModalVisible(true);
    } else {
      setHasAgreedToTerms(false);
    }
  };

  const handleAgreeToTerms = () => {
    setHasAgreedToTerms(true);
    setIsTermsModalVisible(false);
  };

  const handleAttendanceSubmit = () => {
    if (!canSubmit || isSubmitted) {
      showAlert(
        "This timesheet has already been submitted and cannot be modified.",
        "Submission Closed",
        null,
        false
      );
      return;
    }

    if (!isSignatureChecked || !hasAgreedToTerms) {
      showAlert(
        "Please check both the acknowledgment and agree to the terms and conditions before submitting.",
        "Acknowledgment Required",
        null,
        false
      );
      return;
    }

    const confirmationMessage = createConfirmationMessage();

    showAlert(
      confirmationMessage,
      "Confirm Submission",
      () => {
        setIsSignatureModalVisible(true);
      },
      true
    );
  };

  const createConfirmationMessage = () => {
    let message = `
I, ${
      params?.name
    }, hereby certify that I have reviewed and verified the accuracy of the following hours for the week ${currentWeek[0].format(
      "MM/DD/YYYY"
    )} to ${currentWeek[6].format("MM/DD/YYYY")}:

`;

    currentWeek.forEach((day, index) => {
      if (totalWorkHours[index] > 0) {
        message += `${day.format("MM/DD/YYYY")} (${day.format(
          "ddd"
        )}): ${totalWorkHours[index].toFixed(2)} hours\n`;
      }
    });

    message += `
Weekly Total Hours: ${weeklyTotalHours.toFixed(2)}

By proceeding, you will be asked to provide your electronic signature to submit this timesheet. Do you wish to continue?`;

    return message;
  };

  const handleSignatureConfirm = () => {
    if (signatureData && !isSignaturePadEmpty) {
      setSignature(signatureData);
      setIsSignatureModalVisible(false);
      submitAttendance();
    } else {
      showAlert("Please provide a valid signature before confirming.", "Error");
    }
  };

  const handleSignatureCancel = () => {
    setIsSignatureModalVisible(false);
    setSignatureData(null);
    setIsSignaturePadEmpty(true);
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const submitAttendance = async () => {
    try {
      const { weekStart } = getCurrentWeekDates(selectedDate);
      const signatureHash = CryptoJS.SHA256(signatureData).toString();

      // Calculate weekly total hours excluding all special times
      const calculatedWeeklyTotalHours = startTimes.reduce((total, startTime, i) => {
        const workHours = totalWorkHours[i] || 0;
        return total + workHours;
      }, 0);

      const attendanceData = {
        employeeId: params?.id,
        employeeName: params?.name,
        date: moment().tz("America/Phoenix").format("YYYY-MM-DD"),
        weeklyTotalHours: calculatedWeeklyTotalHours,
        weekStart: weekStart.format("YYYY-MM-DD"),
        days: startTimes
          .map((startTime, i) => {
            const dayDate = weekStart.clone().add(i, "days");
            const vacationHours = parseFloat(vacationTimes[i]) || 0;
            const sickHours = parseFloat(sickTimes[i]) || 0;
            const holidayHours = parseFloat(holidayTimes[i]) || 0;

            if (
              !startTime &&
              !endTimes[i] &&
              vacationHours === 0 &&
              sickHours === 0 &&
              holidayHours === 0
            ) {
              return null;
            }

            return {
              dayOfWeek: dayDate.format("dddd"),
              date: dayDate.format("YYYY-MM-DD"),
              startTime: startTime
                ? `${startTime} ${amPmTimes[i].start}`
                : null,
              endTime: endTimes[i]
                ? `${endTimes[i]} ${amPmTimes[i].end}`
                : null,
              lunchStartTime: lunchStartTimes[i]
                ? `${lunchStartTimes[i]} ${amPmTimes[i].lunchStart}`
                : null,
              lunchEndTime: lunchEndTimes[i]
                ? `${lunchEndTimes[i]} ${amPmTimes[i].lunchEnd}`
                : null,
              hours: [
                { type: "work", amount: totalWorkHours[i] },
                { type: "vacation", amount: vacationHours },
                { type: "sick", amount: sickHours },
                { type: "holiday", amount: holidayHours },
              ],
            };
          })
          .filter(Boolean),
        signature: signatureData,
        signatureHash,
        isSubmitted: true,
        isSpecialFormat: false,
      };

      const response = await axios.post(
        "https://sbg-backend.onrender.com/attendance",
        attendanceData
      );

      if (response.status === 200) {
        setIsSubmitted(true);
        setCanSubmit(false);
        showAlert(
          "Attendance submitted successfully. You can no longer modify this timesheet.",
          "Success",
          () => {
            fetchAttendanceData();
          },
          false
        );
      }
    } catch (error) {
      console.error("Error submitting attendance:", error);
      let errorMessage = "An error occurred while submitting attendance.";
      if (error.response) {
        errorMessage += ` Server responded with: ${
          error.response.data.message || error.response.statusText
        }`;
      } else if (error.request) {
        errorMessage += " No response received from server.";
      } else {
        errorMessage += ` ${error.message}`;
      }
      showAlert(errorMessage, "Error", null, false);
    }
  };

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  const handleTimeChange = (index, timeType, newValue) => {
    const updater = {
      start: setStartTimes,
      end: setEndTimes,
      lunchStart: setLunchStartTimes,
      lunchEnd: setLunchEndTimes,
    }[timeType];

    updater((prevTimes) => {
      const newTimes = [...prevTimes];
      newTimes[index] = newValue;
      return newTimes;
    });
  };

  const handleAmPmChange = (index, timeType, newValue) => {
    setAmPmTimes((prevAmPmTimes) => {
      const newAmPmTimes = [...prevAmPmTimes];
      newAmPmTimes[index] = { ...newAmPmTimes[index], [timeType]: newValue };
      return newAmPmTimes;
    });
  };

  const renderRecentHistory = () => (
    <>
      <Text
        style={[
          styles.sectionHeader,
          colorScheme === "dark" && styles.sectionHeaderDark,
        ]}
      >
        Recent Attendance History
      </Text>
      {recentHistory.map((item) => {
        const itemWeekStart = moment(item.weekStart).tz("America/Phoenix");
        const { weekStart: currentWeekStart } =
          getCurrentWeekDates(selectedDate);
        const isCurrentWeek = itemWeekStart.isSame(currentWeekStart, "day");

        return (
          <View
            key={item._id}
            style={[
              styles.historyCard,
              colorScheme === "dark" && styles.historyCardDark,
            ]}
          >
            <Text
              style={[
                styles.historyWeekOf,
                colorScheme === "dark" && styles.historyWeekOfDark,
              ]}
            >
              Week of: {itemWeekStart.format("MMMM D")} -{" "}
              {itemWeekStart.clone().endOf("week").format("MMMM D, YYYY")}
              {isCurrentWeek && " (Current Week)"}
            </Text>
            {item.days.map((day, index) => {
              const totalWorkHours =
                day.hours.find((h) => h.type === "work")?.amount || 0;
              return (
                <View
                  key={index}
                  style={[
                    styles.historyDay,
                    colorScheme === "dark" && styles.historyDayDark,
                  ]}
                >
                  <Text
                    style={[
                      styles.historyDate,
                      colorScheme === "dark" && styles.historyDateDark,
                    ]}
                  >
                    {moment(day.date)
                      .tz("America/Phoenix")
                      .format("ddd, MMM D")}
                  </Text>
                  <Text
                    style={[
                      styles.historyHours,
                      colorScheme === "dark" && styles.historyHoursDark,
                    ]}
                  >
                    Total: {totalWorkHours.toFixed(2)} hours
                  </Text>
                </View>
              );
            })}
            <Text
              style={[
                styles.historyTotalHours,
                colorScheme === "dark" && styles.historyTotalHoursDark,
              ]}
            >
              Weekly Total: {item.weeklyTotalHours.toFixed(2)} hours
            </Text>
          </View>
        );
      })}
    </>
  );

  const onDateChange = (event, selected) => {
    if (event.type === "set") {
      setSelectedDate(moment(selected).tz("America/Phoenix"));
      setShowDatePicker(false);
    } else {
      setShowDatePicker(false);
    }
  };

  const openDatePicker = () => {
    setShowDatePicker(true);
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    );
  }

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#121212" : "#F8F9FA"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"} />
        <Text
          style={[
            styles.headerText,
            colorScheme === "dark" && styles.headerTextDark,
          ]}
        >
          Timesheet
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
          />
        </TouchableOpacity>
      </View>
      <ScrollView style={styles.scrollView}>
        <View style={[styles.content, isDesktop && styles.desktopContent]}>
          <View style={[styles.column, isDesktop && styles.leftColumn]}>
            <View
              style={[
                styles.welcomeContainer,
                colorScheme === "dark" && styles.welcomeContainerDark,
              ]}
            >
              <Text
                style={[
                  styles.welcomeText,
                  colorScheme === "dark" && styles.welcomeTextDark,
                ]}
              >
                Welcome, {params?.name}
              </Text>

              {/* Date Picker */}
              <TouchableOpacity
                style={[
                  styles.datePickerButton,
                  colorScheme === "dark" && styles.datePickerButtonDark,
                ]}
                onPress={openDatePicker}
              >
                <Text
                  style={[
                    styles.datePickerButtonText,
                    colorScheme === "dark" && styles.datePickerButtonTextDark,
                  ]}
                >
                  Select Week Starting Date
                </Text>
              </TouchableOpacity>

              {showDatePicker && (
                <DateTimePicker
                  value={selectedDate.toDate()}
                  mode="date"
                  display={Platform.OS === "ios" ? "spinner" : "default"}
                  onChange={onDateChange}
                />
              )}

              <Text
                style={[
                  styles.dateRangeText,
                  colorScheme === "dark" && styles.dateRangeTextDark,
                ]}
              >
                Current Timesheet:{" "}
                {currentWeek[0] ? currentWeek[0].format("MMMM DD") : ""} -{" "}
                {currentWeek[6] ? currentWeek[6].format("MMMM DD, YYYY") : ""}
              </Text>
              {currentWeek[0] && (
                <Text style={styles.timesheetMessage}>
                  {getTimesheetMessage(currentWeek[0])}
                </Text>
              )}
            </View>

            <View
              style={[
                styles.infoCard,
                colorScheme === "dark" && styles.infoCardDark,
              ]}
            >
              <MaterialIcons
                name="info-outline"
                size={24}
                color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
                style={styles.infoIcon}
              />
              <View style={styles.infoTextContainer}>
                <Text
                  style={[
                    styles.infoTextHeader,
                    colorScheme === "dark" && styles.infoTextHeaderDark,
                  ]}
                >
                  Timesheets are due on{" "}
                  <Text style={styles.boldText}>Mondays before 12:00 noon</Text>
                  .
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Timesheets not submitted on time may be processed in the
                  subsequent pay period.
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Payroll staff will attempt to contact you if your timesheet
                  is missing.
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Hours over 40 per week will be paid as overtime (1.5x hourly
                  rate).
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Please check your entries for accuracy before submitting.
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Recent attendance history is displayed below.
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Personal leave requests can be submitted below.
                </Text>
              </View>
            </View>

            {currentWeek.map((day, index) => (
              <View
                key={index}
                style={[
                  styles.dayCard,
                  colorScheme === "dark" && styles.dayCardDark,
                  day.isSame(moment().tz("America/Phoenix"), "day") &&
                    styles.currentDayCard,
                ]}
              >
                <Text
                  style={[
                    styles.dayText,
                    colorScheme === "dark" && styles.dayTextDark,
                  ]}
                >
                  {day.format("dddd, MMMM DD")}
                </Text>
                {savedDays[index] && (
                  <View style={styles.savedBadge}>
                    <Text style={styles.savedBadgeText}>Saved</Text>
                  </View>
                )}
                <View style={styles.timeInputsRow}>
                  <TimeInput
                    label="Start Time"
                    value={startTimes[index]}
                    onChange={(newValue) =>
                      handleTimeChange(index, "start", newValue)
                    }
                    amPm={amPmTimes[index].start}
                    onChangeAmPm={(newValue) =>
                      handleAmPmChange(index, "start", newValue)
                    }
                    colorScheme={colorScheme}
                    editable={!isSubmitted && canSubmit}
                  />
                  <TimeInput
                    label="End Time"
                    value={endTimes[index]}
                    onChange={(newValue) =>
                      handleTimeChange(index, "end", newValue)
                    }
                    amPm={amPmTimes[index].end}
                    onChangeAmPm={(newValue) =>
                      handleAmPmChange(index, "end", newValue)
                    }
                    colorScheme={colorScheme}
                    editable={!isSubmitted && canSubmit}
                  />
                </View>
                <View style={styles.timeInputsRow}>
                  <TimeInput
                    label="Lunch Start"
                    value={lunchStartTimes[index]}
                    onChange={(newValue) =>
                      handleTimeChange(index, "lunchStart", newValue)
                    }
                    amPm={amPmTimes[index].lunchStart}
                    onChangeAmPm={(newValue) =>
                      handleAmPmChange(index, "lunchStart", newValue)
                    }
                    colorScheme={colorScheme}
                    editable={!isSubmitted && canSubmit}
                  />
                  <TimeInput
                    label="Lunch End"
                    value={lunchEndTimes[index]}
                    onChange={(newValue) =>
                      handleTimeChange(index, "lunchEnd", newValue)
                    }
                    amPm={amPmTimes[index].lunchEnd}
                    onChangeAmPm={(newValue) =>
                      handleAmPmChange(index, "lunchEnd", newValue)
                    }
                    colorScheme={colorScheme}
                    editable={!isSubmitted && canSubmit}
                  />
                </View>
                <View style={styles.specialHoursContainer}>
                  <View style={styles.specialHoursWrapper}>
                    <Text
                      style={[
                        styles.inputLabel,
                        colorScheme === "dark" && styles.inputLabelDark,
                      ]}
                    >
                      Vacation
                    </Text>
                    <TextInput
                      style={[
                        styles.specialHoursInput,
                        colorScheme === "dark" && styles.specialHoursInputDark,
                      ]}
                      placeholder="0"
                      placeholderTextColor={
                        colorScheme === "dark"
                          ? "rgba(255, 255, 255, 0.5)"
                          : "rgba(0, 0, 0, 0.5)"
                      }
                      value={vacationTimes[index] || ""}
                      onChangeText={(text) => {
                        const newVacationTimes = [...vacationTimes];
                        newVacationTimes[index] = text.replace(/[^0-9.]/g, "");
                        setVacationTimes(newVacationTimes);
                      }}
                      editable={canSubmit}
                      keyboardType="numeric"
                    />
                  </View>
                  <View style={styles.specialHoursWrapper}>
                    <Text
                      style={[
                        styles.inputLabel,
                        colorScheme === "dark" && styles.inputLabelDark,
                      ]}
                    >
                      Sick
                    </Text>
                    <TextInput
                      style={[
                        styles.specialHoursInput,
                        colorScheme === "dark" && styles.specialHoursInputDark,
                      ]}
                      placeholder="0"
                      placeholderTextColor={
                        colorScheme === "dark"
                          ? "rgba(255, 255, 255, 0.5)"
                          : "rgba(0, 0, 0, 0.5)"
                      }
                      value={sickTimes[index] || ""}
                      onChangeText={(text) => {
                        const newSickTimes = [...sickTimes];
                        newSickTimes[index] = text.replace(/[^0-9.]/g, "");
                        setSickTimes(newSickTimes);
                      }}
                      editable={canSubmit}
                      keyboardType="numeric"
                    />
                  </View>
                  <View style={styles.specialHoursWrapper}>
                    <Text
                      style={[
                        styles.inputLabel,
                        colorScheme === "dark" && styles.inputLabelDark,
                      ]}
                    >
                      Holiday
                    </Text>
                    <TextInput
                      style={[
                        styles.specialHoursInput,
                        colorScheme === "dark" && styles.specialHoursInputDark,
                      ]}
                      placeholder="0"
                      placeholderTextColor={
                        colorScheme === "dark"
                          ? "rgba(255, 255, 255, 0.5)"
                          : "rgba(0, 0, 0, 0.5)"
                      }
                      value={holidayTimes[index] || ""}
                      onChangeText={(text) => {
                        const newHolidayTimes = [...holidayTimes];
                        newHolidayTimes[index] = text.replace(/[^0-9.]/g, "");
                        setHolidayTimes(newHolidayTimes);
                      }}
                      editable={canSubmit}
                      keyboardType="numeric"
                    />
                  </View>
                </View>
                <Text
                  style={[
                    styles.totalHoursText,
                    colorScheme === "dark" && styles.totalHoursTextDark,
                  ]}
                >
                  Total Hours: {totalWorkHours[index]?.toFixed(2)}
                </Text>
                <TouchableOpacity
                  style={[
                    styles.saveButton,
                    (!canSubmit || isSubmitted) && styles.disabledButton,
                    colorScheme === "dark" && styles.saveButtonDark,
                    (!canSubmit || isSubmitted) &&
                      colorScheme === "dark" &&
                      styles.disabledButtonDark,
                  ]}
                  onPress={() => handleSaveDay(index)}
                  disabled={!canSubmit || isSubmitted}
                >
                  <Text
                    style={[
                      styles.buttonText,
                      (!canSubmit || isSubmitted) && styles.disabledButtonText,
                      colorScheme === "dark" &&
                        (!canSubmit || isSubmitted) &&
                        styles.disabledButtonTextDark,
                    ]}
                  >
                    {savedDays[index] ? "Update Day" : "Save Day"}
                  </Text>
                </TouchableOpacity>
              </View>
            ))}

            <View
              style={[
                styles.summaryCard,
                colorScheme === "dark" && styles.summaryCardDark,
              ]}
            >
              <Text
                style={[
                  styles.summaryText,
                  colorScheme === "dark" && styles.summaryTextDark,
                ]}
              >
                Weekly Total Hours: {formattedTotalHours}
              </Text>
              {isSubmitted ? (
                <View style={styles.submissionStatusContainer}>
                  <Text style={styles.submissionStatusText}>
                    Timesheet submitted for this week
                  </Text>
                </View>
              ) : (
                <View style={styles.submissionStatusContainer}>
                  <Text style={styles.submissionStatusText}>
                    {savedDays.filter(Boolean).length} / 7 days saved
                  </Text>
                </View>
              )}
              <View style={styles.signatureContainer}>
                <TouchableOpacity
                  style={styles.checkbox}
                  onPress={() => setIsSignatureChecked(!isSignatureChecked)}
                >
                  {isSignatureChecked && (
                    <Feather name="check" size={20} color="#4CAF50" />
                  )}
                </TouchableOpacity>
                <Text
                  style={[
                    styles.signatureText,
                    colorScheme === "dark" && styles.signatureTextDark,
                  ]}
                >
                  By signing this timesheet, I certify that I have carefully
                  reviewed this timesheet and that the hours reported on this
                  timesheet, including all start and stop times, are accurate. I
                  acknowledge that my electronic signature is legally binding.
                </Text>
              </View>
              <View style={styles.signatureContainer}>
                <TouchableOpacity
                  style={styles.checkbox}
                  onPress={handleTermsCheckboxPress}
                >
                  {hasAgreedToTerms && (
                    <Feather name="check" size={20} color="#4CAF50" />
                  )}
                </TouchableOpacity>
                <Text
                  style={[
                    styles.signatureText,
                    colorScheme === "dark" && styles.signatureTextDark,
                  ]}
                >
                  I agree to the{" "}
                  <Text
                    style={styles.termsLink}
                    onPress={() => setIsTermsModalVisible(true)}
                  >
                    Terms and Conditions
                  </Text>
                  .
                </Text>
              </View>

              <TouchableOpacity
                style={[
                  styles.submitButton,
                  (!isSignatureChecked ||
                    !hasAgreedToTerms ||
                    !canSubmit ||
                    isSubmitted) &&
                    styles.disabledButton,
                  colorScheme === "dark" && styles.submitButtonDark,
                  (!isSignatureChecked ||
                    !hasAgreedToTerms ||
                    !canSubmit ||
                    isSubmitted) &&
                    colorScheme === "dark" &&
                    styles.disabledButtonDark,
                ]}
                onPress={handleAttendanceSubmit}
                disabled={
                  !isSignatureChecked ||
                  !hasAgreedToTerms ||
                  !canSubmit ||
                  isSubmitted
                }
              >
                <Text
                  style={[
                    styles.buttonText,
                    (!isSignatureChecked ||
                      !hasAgreedToTerms ||
                      !canSubmit ||
                      isSubmitted) &&
                      styles.disabledButtonText,
                    colorScheme === "dark" &&
                      (!isSignatureChecked ||
                        !hasAgreedToTerms ||
                        !canSubmit ||
                        isSubmitted) &&
                      styles.disabledButtonTextDark,
                  ]}
                >
                  {isSubmitted ? "Timesheet Submitted" : "Submit Timesheet"}
                </Text>
              </TouchableOpacity>
            </View>

            <View style={styles.actionButtonsContainer}>
              <TouchableOpacity
                style={styles.actionButton}
                onPress={() =>
                  navigation.navigate("Attendance History", { id: params?.id })
                }
              >
                <Text style={styles.actionButtonText}>
                  View Attendance History
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.actionButton}
                onPress={() =>
                  navigation.navigate("Personal Leave", {
                    employeeName: params?.name,
                    employeeId: params?.id,
                    department: params?.department,
                  })
                }
              >
                <Text style={styles.actionButtonText}>
                  Request Personal Leave
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          {isDesktop ? (
            <View style={styles.rightColumn}>{renderRecentHistory()}</View>
          ) : (
            renderRecentHistory()
          )}
        </View>
      </ScrollView>
      <CustomAlert
        visible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setAlertVisible(false)}
        onConfirm={alertConfirm}
        showCancel={showCancel}
      />

      {/* Signature Modal */}
      <Modal
        visible={isSignatureModalVisible}
        animationType="slide"
        transparent={true}
      >
        <View style={styles.modalContainer}>
          <View style={styles.signaturePadContainer}>
            <Text style={styles.modalTitle}>Please sign below</Text>
            <SignatureCanvas
              ref={signaturePadRef}
              onBegin={() => setIsSignaturePadEmpty(false)}
              onEnd={() => {
                if (signaturePadRef.current) {
                  const data = signaturePadRef.current.toDataURL();
                  setSignatureData(data);
                }
              }}
              clearText="Clear"
              webStyle={`
                .m-signature-pad {
                  width: 100%;
                  height: 300px;
                  margin-bottom: 20px;
                }
                .m-signature-pad--body {
                  border: 1px solid #e8e8e8;
                }
                .m-signature-pad--body canvas {
                  width: 100% !important;
                  height: 100% !important;
                }
              `}
            />
            <View style={styles.modalButtonContainer}>
              <TouchableOpacity
                style={[
                  styles.modalButton,
                  isSignaturePadEmpty && styles.modalButtonDisabled,
                ]}
                onPress={handleSignatureConfirm}
                disabled={isSignaturePadEmpty}
              >
                <Text style={styles.modalButtonText}>Confirm Signature</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.cancelButton]}
                onPress={handleSignatureCancel}
              >
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      {/* Terms and Conditions Modal */}
      <Modal
        visible={isTermsModalVisible}
        animationType="slide"
        transparent={true}
      >
        <View style={styles.modalContainer}>
          <View style={styles.termsModalContent}>
            <Text style={styles.modalTitle}>Terms and Conditions</Text>
            <ScrollView
              style={styles.termsScrollView}
              ref={scrollViewRef}
              onScroll={handleTermsScroll}
              scrollEventThrottle={16}
            >
              <Text style={styles.termsText}>
                {"Terms and Conditions Placeholder\n"}
                {"=============================\n\n"}{" "}
                {"Last updated: August 26, 2024\n\n"}
                {
                  "Please read these terms and conditions carefully before using Our Service.\n\n"
                }
                {"Interpretation and Definitions\n"}
                {"------------------------------\n\n"}
                {"Interpretation\n"}
                {"~~~~~~~~~~~~~~\n"}
                {
                  "The words of which the initial letter is capitalized have meanings defined\n"
                }
                {
                  "under the following conditions. The following definitions shall have the same\n"
                }
                {
                  "meaning regardless of whether they appear in singular or in plural.\n\n"
                }
                {"Definitions\n"}
                {"~~~~~~~~~~~\n\n"}
                {"For the purposes of these Terms and Conditions:\n\n"}
                {
                  "\u2022 Affiliate means an entity that controls, is controlled by or is under\n"
                }
                {
                  "common control with a party, where control means ownership of 50% or\n"
                }
                {
                  "more of the shares, equity interest or other securities entitled to vote\n"
                }
                {"for election of directors or other managing authority.\n\n"}
                {
                  "\u2022 Account means a unique account created for You to access our Service or\n"
                }
                {"parts of our Service.\n\n"}
                {"\u2022 Country refers to: Arizona, United States\n\n"}
                {
                  "\u2022 Company (referred to as either the Company, We, Us or Our in this\n"
                }
                {"Agreement) refers to https://sbg-attendance.com/.\n\n"}
                {
                  "\u2022 Device means any device that can access the Service such as a computer, a\n"
                }
                {"cellphone or a digital tablet.\n\n"}
                {"\u2022 Service refers to the Website.\n\n"}
                {
                  "\u2022 Terms and Conditions (also referred as Terms) mean these Terms and\n"
                }
                {
                  "Conditions that form the entire agreement between You and the Company\n"
                }
                {"regarding the use of the Service.\n\n"}
                {
                  "\u2022 Third-party Social Media Service means any services or content (including\n"
                }
                {
                  "data, information, products or services) provided by a third-party that\n"
                }
                {
                  "may be displayed, included or made available by the Service.\n\n"
                }
                {
                  "\u2022 Website refers to https://sbg-attendance.com/, accessible from\n"
                }
                {"<https://sbg-attendance.com/>\n\n"}
                {
                  "\u2022 You means the individual accessing or using the Service, or the company,\n"
                }
                {
                  "or other legal entity on behalf of which such individual is accessing or\n"
                }
                {"using the Service, as applicable.\n\n"}
                {"Acknowledgment\n"}
                {"--------------\n"}
                {
                  "These are the Terms and Conditions governing the use of this Service and the\n"
                }
                {
                  "agreement that operates between You and the Company. These Terms and\n"
                }
                {
                  "Conditions set out the rights and obligations of all users regarding the use\n"
                }
                {"of the Service.\n\n"}
                {
                  "Your access to and use of the Service is conditioned on Your acceptance of and\n"
                }
                {
                  "compliance with these Terms and Conditions. These Terms and Conditions apply\n"
                }
                {
                  "to all visitors, users and others who access or use the Service.\n\n"
                }
                {
                  "By accessing or using the Service You agree to be bound by these Terms and\n"
                }
                {
                  "Conditions. If You disagree with any part of these Terms and Conditions then\n"
                }
                {"You may not access the Service.\n\n"}
                {
                  "You represent that you are over the age of 18. The Company does not permit\n"
                }
                {"those under 18 to use the Service.\n\n"}
                {
                  "Your access to and use of the Service is also conditioned on Your acceptance\n"
                }
                {
                  "of and compliance with the Privacy Policy of the Company. Our Privacy Policy\n"
                }
                {
                  "describes Our policies and procedures on the collection, use and disclosure of\n"
                }
                {
                  "Your personal information when You use the Application or the Website and\n"
                }
                {
                  "tells You about Your privacy rights and how the law protects You. Please read\n"
                }
                {"Our Privacy Policy carefully before using Our Service.\n\n"}
                {"User Accounts\n"}
                {"-------------\n"}
                {
                  "When You create an account with Us, You must provide Us information that is\n"
                }
                {
                  "accurate, complete, and current at all times. Failure to do so constitutes a\n"
                }
                {
                  "breach of the Terms, which may result in immediate termination of Your account\n"
                }
                {"on Our Service.\n\n"}
                {
                  "You are responsible for safeguarding the password that You use to access the\n"
                }
                {
                  "Service and for any activities or actions under Your password, whether Your\n"
                }
                {
                  "password is with Our Service or a Third-Party Social Media Service.\n\n"
                }
                {
                  "You agree not to disclose Your password to any third party. You must notify Us\n"
                }
                {
                  "immediately upon becoming aware of any breach of security or unauthorized use\n"
                }
                {"of Your account.\n\n"}
                {
                  "You may not use as a username the name of another person or entity or that is\n"
                }
                {
                  "not lawfully available for use, a name or trademark that is subject to any\n"
                }
                {
                  "rights of another person or entity other than You without appropriate\n"
                }
                {
                  "authorization, or a name that is otherwise offensive, vulgar or obscene.\n\n"
                }
                {"Intellectual Property\n"}
                {"---------------------\n"}
                {
                  "The Service and its original content (excluding Content provided by You or\n"
                }
                {
                  "other users), features and functionality are and will remain the exclusive\n"
                }
                {"property of the Company and its licensors.\n\n"}
                {
                  "The Service is protected by copyright, trademark, and other laws of both the\n"
                }
                {"Country and foreign countries.\n\n"}
                {
                  "Our trademarks and trade dress may not be used in connection with any product\n"
                }
                {
                  "or service without the prior written consent of the Company.\n\n"
                }
                {"Links to Other Websites\n"}
                {"-----------------------\n"}
                {
                  "Our Service may contain links to third-party web sites or services that are\n"
                }
                {"not owned or controlled by the Company.\n\n"}
                {
                  "The Company has no control over, and assumes no responsibility for, the\n"
                }
                {
                  "content, privacy policies, or practices of any third party web sites or\n"
                }
                {
                  "services. You further acknowledge and agree that the Company shall not be\n"
                }
                {
                  "responsible or liable, directly or indirectly, for any damage or loss caused\n"
                }
                {
                  "or alleged to be caused by or in connection with the use of or reliance on any\n"
                }
                {
                  "such content, goods or services available on or through any such web sites or\n"
                }
                {"services.\n\n"}
                {
                  "We strongly advise You to read the terms and conditions and privacy policies\n"
                }
                {"of any third-party web sites or services that You visit.\n\n"}
                {"Termination\n"}
                {"-----------\n"}
                {
                  "We may terminate or suspend Your Account immediately, without prior notice or\n"
                }
                {
                  "liability, for any reason whatsoever, including without limitation if You\n"
                }
                {"breach these Terms and Conditions.\n\n"}
                {
                  "Upon termination, Your right to use the Service will cease immediately. If You\n"
                }
                {
                  "wish to terminate Your Account, You may simply discontinue using the Service.\n\n"
                }
                {"Limitation of Liability\n"}
                {"-----------------------\n"}
                {
                  "Notwithstanding any damages that You might incur, the entire liability of the\n"
                }
                {
                  "Company and any of its suppliers under any provision of this Terms and Your\n"
                }
                {
                  "exclusive remedy for all of the foregoing shall be limited to the amount\n"
                }
                {
                  "actually paid by You through the Service or 100 USD if You haven't purchased\n"
                }
                {"anything through the Service.\n\n"}
                {
                  "To the maximum extent permitted by applicable law, in no event shall the\n"
                }
                {
                  "Company or its suppliers be liable for any special, incidental, indirect, or\n"
                }
                {
                  "consequential damages whatsoever (including, but not limited to, damages for\n"
                }
                {
                  "loss of profits, loss of data or other information, for business interruption,\n"
                }
                {
                  "for personal injury, loss of privacy arising out of or in any way related to\n"
                }
                {
                  "the use of or inability to use the Service, third-party software and/or third-\n"
                }
                {
                  "party hardware used with the Service, or otherwise in connection with any\n"
                }
                {
                  "provision of this Terms), even if the Company or any supplier has been advised\n"
                }
                {
                  "of the possibility of such damages and even if the remedy fails of its\n"
                }
                {"essential purpose.\n\n"}
                {
                  "Some states do not allow the exclusion of implied warranties or limitation of\n"
                }
                {
                  "liability for incidental or consequential damages, which means that some of\n"
                }
                {
                  "the above limitations may not apply. In these states, each party's liability\n"
                }
                {"will be limited to the greatest extent permitted by law.\n\n"}
                {'"AS IS" and "AS AVAILABLE" Disclaimer\n'}
                {"-------------------------------------\n"}
                {
                  'The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults\n'
                }
                {
                  "and defects without warranty of any kind. To the maximum extent permitted\n"
                }
                {
                  "under applicable law, the Company, on its own behalf and on behalf of its\n"
                }
                {
                  "Affiliates and its and their respective licensors and service providers,\n"
                }
                {
                  "expressly disclaims all warranties, whether express, implied, statutory or\n"
                }
                {
                  "otherwise, with respect to the Service, including all implied warranties of\n"
                }
                {
                  "merchantability, fitness for a particular purpose, title and non-infringement,\n"
                }
                {
                  "and warranties that may arise out of course of dealing, course of performance,\n"
                }
                {
                  "usage or trade practice. Without limitation to the foregoing, the Company\n"
                }
                {
                  "provides no warranty or undertaking, and makes no representation of any kind\n"
                }
                {
                  "that the Service will meet Your requirements, achieve any intended results, be\n"
                }
                {
                  "compatible or work with any other software, applications, systems or services,\n"
                }
                {
                  "operate without interruption, meet any performance or reliability standards or\n"
                }
                {
                  "be error free or that any errors or defects can or will be corrected.\n\n"
                }
                {
                  "Without limiting the foregoing, neither the Company nor any of the company's\n"
                }
                {
                  "provider makes any representation or warranty of any kind, express or implied:\n"
                }
                {
                  "(i) as to the operation or availability of the Service, or the information,\n"
                }
                {
                  "content, and materials or products included thereon; (ii) that the Service\n"
                }
                {
                  "will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or\n"
                }
                {
                  "currency of any information or content provided through the Service; or (iv)\n"
                }
                {
                  "that the Service, its servers, the content, or e-mails sent from or on behalf\n"
                }
                {
                  "of the Company are free of viruses, scripts, trojan horses, worms, malware,\n"
                }
                {"timebombs or other harmful components.\n\n"}
                {
                  "Some jurisdictions do not allow the exclusion of certain types of warranties\n"
                }
                {
                  "or limitations on applicable statutory rights of a consumer, so some or all of\n"
                }
                {
                  "the above exclusions and limitations may not apply to You. But in such a case\n"
                }
                {
                  "the exclusions and limitations set forth in this section shall be applied to\n"
                }
                {"the greatest extent enforceable under applicable law.\n\n"}
                {"Governing Law\n"}
                {"-------------\n"}
                {
                  "The laws of the Country, excluding its conflicts of law rules, shall govern\n"
                }
                {
                  "this Terms and Your use of the Service. Your use of the Application may also\n"
                }
                {
                  "be subject to other local, state, national, or international laws.\n\n"
                }
                {"Disputes Resolution\n"}
                {"-------------------\n"}
                {
                  "If You have any concern or dispute about the Service, You agree to first try\n"
                }
                {
                  "to resolve the dispute informally by contacting the Company.\n\n"
                }
                {"For European Union (EU) Users\n"}
                {"-----------------------------\n"}
                {
                  "If You are a European Union consumer, you will benefit from any mandatory\n"
                }
                {
                  "provisions of the law of the country in which You are resident.\n\n"
                }
                {"United States Legal Compliance\n"}
                {"------------------------------\n"}
                {
                  "You represent and warrant that (i) You are not located in a country that is\n"
                }
                {
                  "subject to the United States government embargo, or that has been designated\n"
                }
                {
                  'by the United States government as a "terrorist supporting" country, and (ii)\n'
                }
                {
                  "You are not listed on any United States government list of prohibited or\n"
                }
                {"restricted parties.\n\n"}
                {"Severability and Waiver\n"}
                {"-----------------------\n"}
                {"Severability\n"}
                {"~~~~~~~~~~~~\n"}
                {
                  "If any provision of these Terms is held to be unenforceable or invalid, such\n"
                }
                {
                  "provision will be changed and interpreted to accomplish the objectives of such\n"
                }
                {
                  "provision to the greatest extent possible under applicable law and the\n"
                }
                {
                  "remaining provisions will continue in full force and effect.\n\n"
                }
                {"Waiver\n"}
                {"~~~~~~\n"}
                {
                  "Except as provided herein, the failure to exercise a right or to require\n"
                }
                {
                  "performance of an obligation under these Terms shall not affect a party's\n"
                }
                {
                  "ability to exercise such right or require such performance at any time\n"
                }
                {
                  "thereafter nor shall the waiver of a breach constitute a waiver of any\n"
                }
                {"subsequent breach.\n\n"}
                {"Translation Interpretation\n"}
                {"--------------------------\n"}
                {
                  "These Terms and Conditions may have been translated if We have made them\n"
                }
                {
                  "available to You on our Service. You agree that the original English text\n"
                }
                {"shall prevail in the case of a dispute.\n\n"}
                {"Changes to These Terms and Conditions\n"}
                {"-------------------------------------\n"}
                {
                  "We reserve the right, at Our sole discretion, to modify or replace these Terms\n"
                }
                {
                  "at any time. If a revision is material We will make reasonable efforts to\n"
                }
                {
                  "provide at least 30 days' notice prior to any new terms taking effect. What\n"
                }
                {
                  "constitutes a material change will be determined at Our sole discretion.\n\n"
                }
                {
                  "By continuing to access or use Our Service after those revisions become\n"
                }
                {
                  "effective, You agree to be bound by the revised terms. If You do not agree to\n"
                }
                {
                  "the new terms, in whole or in part, please stop using the website and the\n"
                }
                {"Service.\n\n"}
                {"Contact Us\n"}
                {"----------\n"}
                {
                  "If you have any questions about these Terms and Conditions, You can contact\n"
                }
                {"us:\n\n"}
                {"   By email: sbg.software.dev@gmail.com\n"}
              </Text>
            </ScrollView>
            <View style={styles.modalButtonContainer}>
              <TouchableOpacity
                style={[
                  styles.modalButton,
                  !hasScrolledToBottom && styles.disabledButton,
                ]}
                onPress={handleAgreeToTerms}
                disabled={!hasScrolledToBottom}
              >
                <Text style={styles.modalButtonText}>
                  {hasScrolledToBottom
                    ? "I Agree"
                    : "Please scroll to the bottom"}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.cancelButton]}
                onPress={() => setIsTermsModalVisible(false)}
              >
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F9FA",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  headerTextDark: {
    color: "#FFFFFF",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  scrollView: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 16,
  },
  desktopContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginHorizontal: "auto",
  },
  column: {
    flex: 1,
  },
  leftColumn: {
    marginRight: 20,
    flex: 2,
  },
  rightColumn: {
    flex: 1,
    marginTop: 20,
  },
  welcomeContainer: {
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  welcomeContainerDark: {
    backgroundColor: "#1E1E1E",
  },
  welcomeText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  welcomeTextDark: {
    color: "#FFFFFF",
  },
  dateRangeText: {
    fontSize: 14,
    color: "#666",
  },
  dateRangeTextDark: {
    color: "#CCCCCC",
  },
  timesheetMessage: {
    fontSize: 14,
    color: "#007AFF",
    marginTop: 10,
  },
  infoCard: {
    backgroundColor: "#E3F2FD",
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    flexDirection: "row",
  },
  infoCardDark: {
    backgroundColor: "#1E1E1E",
  },
  infoIcon: {
    marginRight: 10,
    marginTop: 2,
  },
  infoTextContainer: {
    flex: 1,
  },
  infoTextHeader: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  infoTextHeaderDark: {
    color: "#FFFFFF",
  },
  infoText: {
    fontSize: 14,
    color: "#333",
    marginBottom: 5,
  },
  infoTextDark: {
    color: "#CCCCCC",
  },
  boldText: {
    fontWeight: "bold",
  },
  dayCard: {
    backgroundColor: "#fff",
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  dayCardDark: {
    backgroundColor: "#1E1E1E",
  },
  currentDayCard: {
    borderColor: "#007AFF",
    borderWidth: 2,
  },
  dayText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  dayTextDark: {
    color: "#FFFFFF",
  },
  timeInputsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  specialHoursContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 10,
  },
  specialHoursWrapper: {
    width: "30%",
    marginBottom: 10,
  },
  inputLabel: {
    fontSize: 12,
    color: "#666",
    marginBottom: 5,
  },
  inputLabelDark: {
    color: "#CCCCCC",
  },
  specialHoursInput: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 8,
    fontSize: 14,
    height: 40,
  },
  specialHoursInputDark: {
    borderColor: "#333",
    color: "#FFFFFF",
  },
  totalHoursText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginTop: 10,
  },
  totalHoursTextDark: {
    color: "#FFFFFF",
  },
  saveButton: {
    backgroundColor: "#28a745",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  saveButtonDark: {
    backgroundColor: "#1E6E34",
  },
  disabledButton: {
    backgroundColor: "#cccccc",
  },
  disabledButtonDark: {
    backgroundColor: "#444444",
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  disabledButtonText: {
    color: "#666666",
  },
  disabledButtonTextDark: {
    color: "#999999",
  },
  summaryCard: {
    backgroundColor: "#fff",
    padding: 20,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  summaryCardDark: {
    backgroundColor: "#1E1E1E",
  },
  summaryText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 15,
  },
  summaryTextDark: {
    color: "#FFFFFF",
  },
  submissionStatusContainer: {
    backgroundColor: "#f0f0f0",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  submissionStatusText: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    color: "#333",
  },
  signatureContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  checkbox: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: "#4CAF50",
    borderRadius: 4,
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  signatureText: {
    flex: 1,
    fontSize: 14,
    color: "#333",
  },
  signatureTextDark: {
    color: "#FFFFFF",
  },
  submitButton: {
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  submitButtonDark: {
    backgroundColor: "#0056b3",
  },
  actionButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  actionButton: {
    backgroundColor: "#6c757d",
    padding: 15,
    borderRadius: 5,
    flex: 1,
    marginHorizontal: 5,
    alignItems: "center",
  },
  actionButtonDark: {
    backgroundColor: "#333",
  },
  actionButtonText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
  },
  sectionHeader: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 15,
  },
  sectionHeaderDark: {
    color: "#FFFFFF",
  },
  historyCard: {
    backgroundColor: "#fff",
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  historyCardDark: {
    backgroundColor: "#1E1E1E",
  },
  historyWeekOf: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  historyWeekOfDark: {
    color: "#FFFFFF",
  },
  historyDay: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
  historyDayDark: {
    borderBottomColor: "#444",
  },
  historyDate: {
    fontSize: 14,
    color: "#666",
  },
  historyDateDark: {
    color: "#CCCCCC",
  },
  historyHours: {
    fontSize: 14,
    color: "#333",
  },
  historyHoursDark: {
    color: "#FFFFFF",
  },
  historyTotalHours: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginTop: 10,
    textAlign: "right",
  },
  historyTotalHoursDark: {
    color: "#FFFFFF",
  },
  savedBadge: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "#28a745",
    borderRadius: 5,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  savedBadgeText: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  signaturePadContainer: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    width: "95%",
    maxWidth: 600,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    textAlign: "center",
  },
  modalButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  modalButton: {
    flex: 1,
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginHorizontal: 5,
    backgroundColor: "#007AFF",
  },
  modalButtonDisabled: {
    backgroundColor: "#cccccc",
  },
  cancelButton: {
    backgroundColor: "#f0f0f0",
  },
  modalButtonText: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: 16,
  },
  cancelButtonText: {
    color: "#333",
    fontSize: 16,
  },
  termsLink: {
    color: "#007AFF",
    textDecorationLine: "underline",
  },
  termsModalContent: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    width: "95%",
    maxWidth: 600,
    maxHeight: "80%",
  },
  termsScrollView: {
    maxHeight: 400,
  },
  termsText: {
    fontSize: 14,
    color: "#333333",
    marginBottom: 20,
  },
  closeButton: {
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 20,
  },
  closeButtonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F8F9FA",
  },
  loadingText: {
    fontSize: 18,
    color: "#333",
  },
});

export default User;
