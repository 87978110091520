// TimeInput.js

import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import PropTypes from "prop-types";
import moment from "moment-timezone";

const TimeInput = ({
  label,
  time,
  onChange,
  disabled = false,
  colorScheme = "light",
  totalHours,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("AM");
  const isTyping = useRef(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isTyping.current) {
      isTyping.current = false;
      return;
    }

    if (time) {
      const parsedTime = moment(time, "h:mm A");
      if (parsedTime.isValid()) {
        setInputValue(parsedTime.format("hh:mm"));
        setSelectedPeriod(parsedTime.format("A"));
      }
    } else {
      setInputValue("");
      setSelectedPeriod("AM");
    }
  }, [time]);

  const handleChange = (text) => {
    isTyping.current = true;
    const cleanedText = text.replace(/[^0-9:]/g, "");
    let formattedText = cleanedText;

    if (/^\d{2}$/.test(cleanedText)) {
      formattedText = cleanedText + ":";
    }
    setInputValue(formattedText);

    if (formattedText.length === 5) {
      const [hoursStr, minutesStr] = formattedText.split(":");
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);

      if (
        hours >= 1 &&
        hours <= 12 &&
        minutes >= 0 &&
        minutes <= 59 &&
        (selectedPeriod === "AM" || selectedPeriod === "PM")
      ) {
        const parsedTime = moment(
          `${formattedText} ${selectedPeriod}`,
          "hh:mm A"
        );
        if (parsedTime.isValid()) {
          const formattedTime = parsedTime.format("h:mm A");
          onChange(formattedTime);
          setError("");
        } else {
          setError("Invalid time format.");
        }
      } else {
        setError("Invalid hours or minutes.");
      }
    } else if (formattedText === "") {
      onChange("");
      setError("");
    } else {
      setError(""); // Clear error while typing
    }
  };

  const togglePeriod = (period) => {
    if (disabled) return;
    setSelectedPeriod(period);

    if (inputValue) {
      const parsedTime = moment(`${inputValue} ${period}`, "hh:mm A");
      if (parsedTime.isValid()) {
        const formattedTime = parsedTime.format("h:mm A");
        onChange(formattedTime);
        setInputValue(parsedTime.format("hh:mm"));
      }
    }
  };

  return (
    <View style={styles.container}>
      {label && (
        <Text
          style={[
            styles.label,
            colorScheme === "dark" && styles.labelDark,
            disabled && styles.disabledText,
          ]}
        >
          {label}
        </Text>
      )}
      <View style={styles.inputRow}>
        <TextInput
          style={[
            styles.input,
            colorScheme === "dark" ? styles.inputDark : styles.inputLight,
            disabled && styles.disabledInput,
          ]}
          value={inputValue}
          onChangeText={handleChange}
          placeholder="HH:MM"
          keyboardType="numeric"
          maxLength={5}
          editable={!disabled}
          placeholderTextColor={colorScheme === "dark" ? "#888" : "#999"}
        />
        <View style={styles.periodToggleContainer}>
          <TouchableOpacity
            style={[
              styles.periodButton,
              selectedPeriod === "AM" && styles.selectedPeriodButton,
              colorScheme === "dark" && styles.periodButtonDark,
              selectedPeriod === "AM" &&
                colorScheme === "dark" &&
                styles.selectedPeriodButtonDark,
              disabled && styles.disabledPeriodButton,
            ]}
            onPress={() => togglePeriod("AM")}
            disabled={disabled}
          >
            <Text
              style={[
                styles.periodButtonText,
                selectedPeriod === "AM" && styles.selectedPeriodButtonText,
                colorScheme === "dark" && styles.periodButtonTextDark,
                selectedPeriod === "AM" &&
                  colorScheme === "dark" &&
                  styles.selectedPeriodButtonTextDark,
                disabled && styles.disabledPeriodButtonText,
              ]}
            >
              AM
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.periodButton,
              selectedPeriod === "PM" && styles.selectedPeriodButton,
              colorScheme === "dark" && styles.periodButtonDark,
              selectedPeriod === "PM" &&
                colorScheme === "dark" &&
                styles.selectedPeriodButtonDark,
              disabled && styles.disabledPeriodButton,
            ]}
            onPress={() => togglePeriod("PM")}
            disabled={disabled}
          >
            <Text
              style={[
                styles.periodButtonText,
                selectedPeriod === "PM" && styles.selectedPeriodButtonText,
                colorScheme === "dark" && styles.periodButtonTextDark,
                selectedPeriod === "PM" &&
                  colorScheme === "dark" &&
                  styles.selectedPeriodButtonTextDark,
                disabled && styles.disabledPeriodButtonText,
              ]}
            >
              PM
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {error !== "" && <Text style={styles.errorText}>{error}</Text>}
    </View>
  );
};

TimeInput.propTypes = {
  label: PropTypes.string,
  time: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  colorScheme: PropTypes.oneOf(["light", "dark"]),
  totalHours: PropTypes.number,
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  label: {
    fontSize: 14,
    color: "#333",
    marginBottom: 4,
  },
  labelDark: {
    color: "#FFF",
  },
  disabledText: {
    color: "#A9A9A9",
  },
  inputRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    height: 40,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 4,
    paddingHorizontal: 8,
    fontSize: 16,
    width: "60%",
  },
  inputLight: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
  },
  inputDark: {
    backgroundColor: "#3C3C3C",
    color: "#FFFFFF",
  },
  disabledInput: {
    backgroundColor: "#F0F0F0",
    color: "#A9A9A9",
  },
  periodToggleContainer: {
    flexDirection: "row",
    width: "35%",
  },
  periodButton: {
    flex: 1,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderWidth: 1,
    borderColor: "#ddd",
  },
  periodButtonDark: {
    borderColor: "#555555",
    backgroundColor: "#3C3C3C",
  },
  selectedPeriodButton: {
    backgroundColor: "#007AFF",
    borderColor: "#007AFF",
  },
  selectedPeriodButtonDark: {
    backgroundColor: "#0A84FF",
    borderColor: "#0A84FF",
  },
  periodButtonText: {
    fontSize: 14,
    color: "#000000",
  },
  periodButtonTextDark: {
    color: "#FFFFFF",
  },
  selectedPeriodButtonText: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  selectedPeriodButtonTextDark: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  disabledPeriodButton: {
    backgroundColor: "#F0F0F0",
    borderColor: "#A9A9A9",
  },
  disabledPeriodButtonText: {
    color: "#A9A9A9",
  },
  errorText: {
    color: "#FF3B30",
    fontSize: 12,
    marginTop: 4,
  },
});

export default TimeInput;
